import React from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { AliveScope } from 'react-activation';

// 页面
import App from './App';
import HomePage from './views/HomePage';
import Capital from './views/Capital';
import Sponsor from './views/Sponsor';
import News from './views/News';

export default function Router() {
    return (
        <BrowserRouter>
            <AliveScope>
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/event" element={<Capital />} />
                        <Route path="/sponsor" element={<Sponsor />} />
                        <Route path="/news" element={<News />} />
                        <Route path="*" element={<Navigate to="404" replace />} />
                    </Route>
                </Routes>
            </AliveScope>
        </BrowserRouter>
    );
}
