import BlockchainWrap from './components/BlockchainWrap';
import TextContent from './components/TextContent';
// import WebSpaceWrap from "./components/WebSpaceWrap";
import MeetsCapitalWrap from './components/MeetsCapitalWrap';
import PartnersWrap from '@/components/PartnersWrap/index';
import OurMembersWrap from './components/OurMembersWrap';
import SubscribeWrap from './components/SubscribeWrap';
// import GalleryWrap from "./components/GalleryWrap";
import WebEventsWrap from './components/WebEventsWrap';
import ExploreOurOfferWrap from './components/ExploreOurOfferWrap';
import GoToTopWrap from './components/GoToTopWrap';
import './index.scss';

import ourImg1 from '@/assets/our/img1.png';
import ourImg2 from '@/assets/our/img2.png';
import ourImg3 from '@/assets/our/img3.png';
import ourImg4 from '@/assets/our/img4.png';
import ourImg5 from '@/assets/our/img5.png';
import ourImg6 from '@/assets/our/img6.png';
import ourImg7 from '@/assets/our/img7.png';
import ourImg8 from '@/assets/our/img8.png';
import ourImg9 from '@/assets/our/img9.png';
import ourImg10 from '@/assets/our/img10.png';
import ourImg11 from '@/assets/our/img11.png';
import ourImg12 from '@/assets/our/img12.png';
import ourImg13 from '@/assets/our/img13.png';
import ourImg14 from '@/assets/our/img14.png';
import ourImg15 from '@/assets/our/img15.png';
import ourImg16 from '@/assets/our/img16.png';
import ourImg17 from '@/assets/our/img17.png';
import ourImg18 from '@/assets/our/img18.png';
import ourImg19 from '@/assets/our/img19.png';
import ourImg20 from '@/assets/our/img20.png';
import ourImg21 from '@/assets/our/img21.png';
import ourImg22 from '@/assets/our/img22.png';
import ourImg23 from '@/assets/our/img23.png';
import ourImg24 from '@/assets/our/img24.png';
import ourImg25 from '@/assets/our/img25.png';
import ourImg26 from '@/assets/our/img26.png';
import ourImg27 from '@/assets/our/img27.png';
import ourImg28 from '@/assets/our/img28.png';
import ourImg29 from '@/assets/our/img29.png';

const HomePage = () => {
    const arr = [
        { img: ourImg2, linkTo: '' },
        { img: ourImg29, linkTo: '' },
        { img: ourImg28, linkTo: '' },
        { img: '/img/home/ii.png', linkTo: '' },
        { img: ourImg5, linkTo: '' },
        { img: ourImg9, linkTo: '' },

        { img: ourImg6, linkTo: '' },
        { img: ourImg8, linkTo: '' },
        { img: ourImg15, linkTo: '' },
        { img: ourImg16, linkTo: '' },

        { img: ourImg17, linkTo: '' },
        { img: ourImg18, linkTo: '' },
        { img: ourImg19, linkTo: '' },
        { img: ourImg20, linkTo: '' },
        { img: ourImg21, linkTo: '' },
        { img: ourImg23, linkTo: '' },
        { img: ourImg25, linkTo: '' },

        { img: ourImg27, linkTo: '' },
        { img: ourImg10, linkTo: '' },
        { img: '/img/home/ilfp.png', linkTo: '' },
        { img: ourImg11, linkTo: '' },
        { img: ourImg12, linkTo: '' },
        { img: ourImg26, linkTo: '' },
        { img: '/img/home/waldos-desktop.png', linkTo: '' },
    ];
    return (
        <div className="homePageWrap">
            <BlockchainWrap />
            <TextContent />
            {/* <WebSpaceWrap /> */}
            <MeetsCapitalWrap />
            <PartnersWrap list={arr} title={'Our partners'} />
            <OurMembersWrap />
            <SubscribeWrap />
            {/* <GalleryWrap /> */}
            <WebEventsWrap />
            <ExploreOurOfferWrap />
            <GoToTopWrap />
        </div>
    );
};

export default HomePage;
