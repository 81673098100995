import React, { useCallback, useEffect, useState, useLayoutEffect, useRef } from 'react';
import Logo from '@/assets/svg/capital/logo.svg';
import ArrowRight from '@/assets/svg/capital/arrow-right.svg';
import AllowClickIcon from '@/svg/capital/presentation.svg';
import { Link } from 'react-router-dom';
import { Carousel } from 'antd';
import GoToTopWrap from '@/views/HomePage/components/GoToTopWrap/index';
import { useStateStore } from '@/store';
import PartnersWrap from '@/components/PartnersWrap/index';
import PlatformLoadingDom from '@/views/HomePage/components/MeetsCapitalWrap/components/PlatformLoadingDom/index';
import program1 from '@/assets/img/program-1.jpg';
import program2 from '@/assets/img/program-2.jpg';
import program3 from '@/assets/img/program-3.jpg';
import program4 from '@/assets/img/program-4.jpg';
import pcImg from '@/assets/img/pc-bg.png';
import phonePng from '@/assets/img/phone-bg.png';
import monksImg from '@/assets/img/capital/Platinum_6monks.png';
import bfImg from '@/assets/img/capital/Platinum_BF.png';
import bityImg from '@/assets/img/capital/Platinum_bity.png';
import dfinityImg from '@/assets/img/capital/Platinum_Dfinity.png';
import { useCountUp } from 'use-count-up';

import Partner_cvsummit from '@/assets/img/capital/desktop/Partner_cvsummit.png';
import Partner_phone_cvsummit from '@/assets/img/capital/mobile/Partner_cvsummit.png';
import Partner_Lange from '@/assets/img/capital/desktop/Partner_Lange.png';
import Partner_phone_Lange from '@/assets/img/capital/mobile/Partner_Lange.png';
import Partner_Lugano_Plan from '@/assets/img/capital/desktop/Partner_Lugano_Plan.png';
import Partner_phone_Lugano_Plan from '@/assets/img/capital/mobile/Partner_Lugano_Plan.png';

import Attendees_1kx from '@/assets/img/capital/Attendees_1kx.png';
import Attendees_6monks from '@/assets/img/capital/Attendees_6monks.png';
import Attendees_allswiss from '@/assets/img/capital/Attendees_allswiss.png';
import Attendees_alt_alpha from '@/assets/img/capital/Attendees_alt_alpha.png';
import Attendees_animoca_brands from '@/assets/img/capital/Attendees_animoca_brands.png';
import Attendees_BAS from '@/assets/img/capital/Attendees_BAS.png';
import Attendees_BF from '@/assets/img/capital/Attendees_BF.png';
import Attendees_binance from '@/assets/img/capital/Attendees_binance.png';
import Attendees_bitget from '@/assets/img/capital/Attendees_bitget.png';
import Attendees_bitmex from '@/assets/img/capital/Attendees_bitmex.png';
import Attendees_bity from '@/assets/img/capital/Attendees_bity.png';
import Attendees_cardano_foundation from '@/assets/img/capital/Attendees_cardano_foundation.png';
import Attendees_cointelegraph from '@/assets/img/capital/Attendees_cointelegraph.png';
import Attendees_cth from '@/assets/img/capital/Attendees_cth.png';
import Attendees_dcap from '@/assets/img/capital/Attendees_dcap.png';
import Attendees_IC from '@/assets/img/capital/Attendees_IC.png';
import Attendees_dsocial from '@/assets/img/capital/Attendees_dsocial.png';
import Attendees_eterna from '@/assets/img/capital/Attendees_eterna.png';
import Attendees_GLDT from '@/assets/img/capital/Attendees_GLDT.png';
import Attendees_green_field from '@/assets/img/capital/Attendees_green_field.png';
import Attendees_hackvc from '@/assets/img/capital/Attendees_hackvc.png';
import Attendees_homsy_legal from '@/assets/img/capital/Attendees_homsy_legal.png';
import Attendees_huobi from '@/assets/img/capital/Attendees_huobi.png';
import Attendees_imd from '@/assets/img/capital/Attendees_imd.png';
import Attendees_imo_ventures from '@/assets/img/capital/Attendees_imo_ventures.png';
import Attendees_inacta from '@/assets/img/capital/Attendees_inacta.png';
import Attendees_ivc from '@/assets/img/capital/Attendees_ivc.png';
import Attendees_kernel from '@/assets/img/capital/Attendees_kernel.png';
import Attendees_L1D from '@/assets/img/capital/Attendees_L1D.png';
import Attendees_leax from '@/assets/img/capital/Attendees_leax.png';
import Attendees_near from '@/assets/img/capital/Attendees_near.png';
import Attendees_nva from '@/assets/img/capital/Attendees_nva.png';
import Attendees_origyn from '@/assets/img/capital/Attendees_origyn.png';
import Attendees_red_alpine from '@/assets/img/capital/Attendees_red_alpine.png';
import Attendees_RG from '@/assets/img/capital/Attendees_RG.png';
import Attendees_shiku from '@/assets/img/capital/Attendees_shiku.png';
import Attendees_snz from '@/assets/img/capital/Attendees_snz.png';
import Attendees_softbank from '@/assets/img/capital/Attendees_softbank.png';
import Attendees_spartan from '@/assets/img/capital/Attendees_spartan.png';
import Attendees_swiss_borg from '@/assets/img/capital/Attendees_swiss_borg.png';
import Attendees_veris_ventures from '@/assets/img/capital/Attendees_veris_ventures.png';
import Attendees_versant_venture from '@/assets/img/capital/Attendees_versant_venture.png';
import Attendees_wef from '@/assets/img/capital/Attendees_wef.png';
import Attendees_yahoo from '@/assets/img/capital/Attendees_yahoo.png';
import Attendees_yumi from '@/assets/img/capital/Attendees_yumi.png';
import Attendees_cathy_capital from '@/assets/img/capital/Attendees_cathy_capital.png';
import Attendees_ledger from '@/assets/img/capital/Attendees_ledger.png';
import Attendees_apex_venture from '@/assets/img/capital/Attendees_apex_venture.png';
import Attendees_syz from '@/assets/img/capital/Attendees_syz.png';
import Attendees_chorus_one from '@/assets/img/capital/Attendees_chorus_one.png';
import Attendees_ikigai from '@/assets/img/capital/Attendees_ikigai.png';
import Attendees_quadrillium from '@/assets/img/capital/Attendees_quadrillium.png';
import Attendees_seba from '@/assets/img/capital/Attendees_seba.png';
import Attendees_spice from '@/assets/img/capital/Attendees_spice.png';
import Attendees_onramp from '@/assets/img/capital/Attendees_onramp.png';
import Attendees_tenity from '@/assets/img/capital/Attendees_tenity.png';
import Attendees_zeltner from '@/assets/img/capital/Attendees_zeltner.png';
import Attendees_business_vellow from '@/assets/img/capital/Attendees_business_vellow.png';
import Attendees_laser_digital from '@/assets/img/capital/Attendees_laser_digital.png';
import Attendees_algo from '@/assets/img/capital/Attendees_algo.png';
import Attendees_BS from '@/assets/img/capital/Attendees_BS.png';
import Attendees_sygnum from '@/assets/img/capital/Attendees_sygnum.png';
import Attendees_deloitte from '@/assets/img/capital/Attendees_deloitte.png';
import Attendees_conflux from '@/assets/img/capital/Attendees_conflux.png';
import Attendees_kraken from '@/assets/img/capital/Attendees_kraken.png';
import Attendees_page_executive from '@/assets/img/capital/Attendees_page_executive.png';
import Attendees_sts from '@/assets/img/capital/Attendees_sts.png';
import Attendees_mindfullness_escape from '@/assets/img/capital/Attendees_mindfullness_escape.png';
import Attendees_huawei from '@/assets/img/capital/Attendees_huawei.png';
import Attendees_liquity from '@/assets/img/capital/Attendees_liquity.png';
import Attendees_fabric from '@/assets/img/capital/Attendees_fabric.png';
import Attendees_uzh from '@/assets/img/capital/Attendees_uzh.png';
import Attendees_amber from '@/assets/img/capital/Attendees_amber.png';
import Attendees_maas_ventures from '@/assets/img/capital/Attendees_maas_ventures.png';
import Attendees_sge from '@/assets/img/capital/Attendees_sge.png';
import Attendees_sinoswiss from '@/assets/img/capital/Attendees_sinoswiss.png';

import closeSvg from '@/svg/capital/close.svg';
import partnerCloseSvg from '@/svg/capital/partnerCloseSvg.svg';
import upSvg from '@/svg/capital/up.svg';
import downSvg from '@/svg/capital/down.svg';
import leftArrowSvg from '@/svg/left-arrow.svg';
import rightArrowSvg from '@/svg/right-arrow.svg';

import media_partner_cointelegraph from '@/assets/img/capital/media_partner_cointelegraph.png';
import media_partner_coinhustle from '@/assets/img/capital/media_partner_coinhustle.png';
import media_partner_foresightnew from '@/assets/img/capital/media_partner_foresightnew.png';
import media_partner_odaily from '@/assets/img/capital/media_partner_odaily.png';
import media_partner_cryptopolitan from '@/assets/img/capital/media_partner_cryptopolitan.png';
import media_partner_haitun from '@/assets/img/capital/media_partner_haitun.png';
import media_partner_wjb from '@/assets/img/capital/media_partner_wjb.png';
import media_partner_BeInCrypto from '@/assets/img/capital/media_partner_BeInCrypto.png';

import desktop_wednesday_wed_1 from '@/assets/img/capital/desktop/wednesday/wed_1.jpg';
import desktop_wednesday_wed_2 from '@/assets/img/capital/desktop/wednesday/wed_2.jpg';
import desktop_wednesday_wed_3 from '@/assets/img/capital/desktop/wednesday/wed_3.jpg';
import mobile_wednesday_wed_1 from '@/assets/img/capital/mobile/wednesday/wed_1.jpg';
import mobile_wednesday_wed_2 from '@/assets/img/capital/mobile/wednesday/wed_2.jpg';

import desktop_thursday_thur_1 from '@/assets/img/capital/desktop/thursday/thur_1.jpg';
import desktop_thursday_thur_2 from '@/assets/img/capital/desktop/thursday/thur_2.jpg';
import desktop_thursday_thur_3 from '@/assets/img/capital/desktop/thursday/thur_3.jpg';
import desktop_thursday_thur_4 from '@/assets/img/capital/desktop/thursday/thur_4.jpg';
import desktop_thursday_thur_5 from '@/assets/img/capital/desktop/thursday/thur_5.jpg';
import desktop_thursday_thur_6 from '@/assets/img/capital/desktop/thursday/thur_6.jpg';
import desktop_thursday_thur_7 from '@/assets/img/capital/desktop/thursday/thur_7.jpg';
import desktop_thursday_thur_8 from '@/assets/img/capital/desktop/thursday/thur_8.png';
import desktop_thursday_thur_9 from '@/assets/img/capital/desktop/thursday/thur_9.svg';
import desktop_thursday_thur_10 from '@/assets/img/capital/desktop/thursday/thur_10.jpg';
import desktop_thursday_thur_11 from '@/assets/img/capital/desktop/thursday/thur_11.jpg';
import desktop_thursday_thur_12 from '@/assets/img/capital/desktop/thursday/thur_12.jpg';
import desktop_thursday_thur_13 from '@/assets/img/capital/desktop/thursday/thur_13.jpg';
import desktop_thursday_thur_14 from '@/assets/img/capital/desktop/thursday/thur_14.jpg';
import desktop_thursday_thur_15 from '@/assets/img/capital/desktop/thursday/thur_15.png';

import desktop_thursday_thur_17 from '@/assets/img/capital/desktop/thursday/thur_17.jpg';

import desktop_program_gldt from '@/assets/img/capital/desktop/thursday/gldt.png';
import mobile_program_gldt from '@/assets/img/capital/mobile/thursday/gldt.png';
import desktop_program_shiku from '@/assets/img/capital/desktop/thursday/shiku.png';
import mobile_program_shiku from '@/assets/img/capital/mobile/thursday/shiku.png';

import mobile_thursday_thur_1 from '@/assets/img/capital/mobile/thursday/thur_1.jpg';
import mobile_thursday_thur_2 from '@/assets/img/capital/mobile/thursday/thur_2.jpg';
import mobile_thursday_thur_3 from '@/assets/img/capital/mobile/thursday/thur_3.jpg';
import mobile_thursday_thur_4 from '@/assets/img/capital/mobile/thursday/thur_4.jpg';
import mobile_thursday_thur_5 from '@/assets/img/capital/mobile/thursday/thur_5.jpg';
import mobile_thursday_thur_6 from '@/assets/img/capital/mobile/thursday/thur_6.jpg';
import mobile_thursday_thur_7 from '@/assets/img/capital/mobile/thursday/thur_7.jpg';
import mobile_thursday_thur_8 from '@/assets/img/capital/mobile/thursday/thur_8.svg';
import mobile_thursday_thur_9 from '@/assets/img/capital/mobile/thursday/thur_9.jpg';
import mobile_thursday_thur_10 from '@/assets/img/capital/mobile/thursday/thur_10.jpg';
import mobile_thursday_thur_11 from '@/assets/img/capital/mobile/thursday/thur_11.jpg';
import mobile_thursday_thur_12 from '@/assets/img/capital/mobile/thursday/thur_12.jpg';
import mobile_thursday_thur_13 from '@/assets/img/capital/mobile/thursday/thur_13.jpg';
import mobile_thursday_thur_14 from '@/assets/img/capital/mobile/thursday/thur_14.png';
import mobile_thursday_thur_15 from '@/assets/img/capital/mobile/thursday/thur_15.jpg';
import mobile_thursday_thur_16 from '@/assets/img/capital/mobile/thursday/thur_16.jpg';

import desktop_thursday_Julien_Wolff from '@/assets/img/capital/desktop/thursday/Julien_Wolff.png';
import mobile_thursday_Julien_Wolff from '@/assets/img/capital/mobile/thursday/Julien_Wolff.png';
import desktop_thursday_Julie_Bourgeois from '@/assets/img/capital/desktop/thursday/Julie_Bourgeois.png';
import mobile_thursday_Julie_Bourgeois from '@/assets/img/capital/mobile/thursday/Julie_Bourgeois.png';

import desktop_friday_fri_1 from '@/assets/img/capital/desktop/friday/fri_1.jpg';
import desktop_friday_fri_2 from '@/assets/img/capital/desktop/friday/fri_2.jpg';
import desktop_friday_fri_3 from '@/assets/img/capital/desktop/friday/fri_3.jpg';
import desktop_friday_fri_4 from '@/assets/img/capital/desktop/friday/fri_4.jpg';
import desktop_friday_fri_5 from '@/assets/img/capital/desktop/friday/fri_5.jpg';
import desktop_friday_fri_6 from '@/assets/img/capital/desktop/friday/fri_6.jpg';
import mobile_friday_fri_1 from '@/assets/img/capital/mobile/friday/fri_1.jpg';
import mobile_friday_fri_2 from '@/assets/img/capital/mobile/friday/fri_2.jpg';
import mobile_friday_fri_3 from '@/assets/img/capital/mobile/friday/fri_3.jpg';
import mobile_friday_fri_4 from '@/assets/img/capital/mobile/friday/fri_4.jpg';
import mobile_friday_fri_5 from '@/assets/img/capital/mobile/friday/fri_5.jpg';

import './index.scss';

const Capital = () => {
    const [num, setNum] = useState(0);
    const state = useStateStore();
    const { appType } = state.default;

    const { value, reset } = useCountUp({
        isCounting: true,
        start: 0,
        end: 100,
        duration: 1,
        easing: 'linear',
    });

    const [currentInfo, setCurrentInfo] = useState({});
    const [currentId, setCurrentId] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showPartnerModal, setShowPartnerModal] = useState(false);
    const [currentPartnerInfo, setCurrentPartnerInfo] = useState({});
    const pointer = {
        cursor: 'pointer',
    };

    const [currentPartnerId, setCurrentPartnerId] = useState(1);

    const imgList = [
        { img: Attendees_BF, linkTo: 'https://www.bochslerfinance.com/' },
        { img: Attendees_softbank, linkTo: 'https://www.softbank.jp/en/' },
        { img: Attendees_L1D, linkTo: 'https://www.l1.digital/' },
        { img: Attendees_animoca_brands, linkTo: 'https://www.animocabrands.com/' },
        { img: Attendees_spartan, linkTo: 'https://www.spartangroup.io/' },
        { img: Attendees_binance, linkTo: 'https://www.binance.com/fr' },
        { img: Attendees_bitget, linkTo: 'https://www.bitget.com/fr/' },
        { img: Attendees_huobi, linkTo: 'https://www.huobi.com/' },
        { img: Attendees_bitmex, linkTo: 'https://www.bitmex.com/' },
        { img: Attendees_kraken, linkTo: 'https://kraken.com/' },
        { img: Attendees_IC, linkTo: 'https://internetcomputer.org/' },
        { img: Attendees_cardano_foundation, linkTo: 'https://cardanofoundation.org/' },
        { img: Attendees_near, linkTo: 'https://near.org/' },
        { img: Attendees_conflux, linkTo: 'https://confluxnetwork.org/' },
        { img: Attendees_leax, linkTo: 'https://www.leax.ch/' },
        { img: Attendees_6monks, linkTo: 'https://www.6m.lu/' },
        { img: Attendees_nva, linkTo: 'https://nva.capital/' },
        { img: Attendees_hackvc, linkTo: 'https://hack.vc/' },
        { img: Attendees_dcap, linkTo: 'https://www.dcap.ch/' },
        { img: Attendees_imo_ventures, linkTo: 'https://imo.vc/' },
        { img: Attendees_wef, linkTo: 'https://www.weforum.org/' },
        { img: Attendees_huawei, linkTo: 'https://huawei.com/' },
        { img: Attendees_yahoo, linkTo: 'https://finance.yahoo.com/' },
        { img: Attendees_page_executive, linkTo: 'https://www.pageexecutive.com/' },
        { img: Attendees_deloitte, linkTo: 'https://www2.deloitte.com/ch/fr.html' },
        { img: Attendees_red_alpine, linkTo: 'https://redalpine.com/' },
        { img: Attendees_RG, linkTo: 'http://rategroup.co.uk/' },
        { img: Attendees_kernel, linkTo: 'https://www.kernel-ventures.com/' },
        { img: Attendees_eterna, linkTo: 'https://www.eternacapital.com/' },
        { img: Attendees_ivc, linkTo: 'https://www.ivcrypto.io/' },
        { img: Attendees_ikigai, linkTo: 'https://ikigaiventuresltd.com/' },
        { img: Attendees_quadrillium, linkTo: 'https://quadrilium.com/' },
        { img: Attendees_veris_ventures, linkTo: 'https://verisventures.xyz/' },
        { img: Attendees_spice, linkTo: 'https://spicevc.com/' },
        { img: Attendees_GLDT, linkTo: '' },
        { img: Attendees_1kx, linkTo: 'https://1kx.capital/' },
        { img: Attendees_inacta, linkTo: 'https://inacta.ch/' },
        { img: Attendees_cth, linkTo: 'https://www.cth.group/what_we_do/fundamental_labs/' },
        { img: Attendees_alt_alpha, linkTo: 'https://www.altalphastrategies.com/' },
        { img: Attendees_versant_venture, linkTo: 'https://www.versantventures.com/' },
        { img: Attendees_snz, linkTo: 'https://snzholding.com/' },
        { img: Attendees_imd, linkTo: 'https://www.imd.org/' },
        { img: Attendees_BAS, linkTo: 'https://rbzy6-caaaa-aaaan-qdn5a-cai.icp0.io/' },
        { img: Attendees_allswiss, linkTo: 'http://allswiss.com/' },
        { img: Attendees_green_field, linkTo: 'https://greenfield.xyz/' },
        { img: Attendees_algo, linkTo: 'https://algo-capital.com/' },
        { img: Attendees_sts, linkTo: 'https://stsdigital.io/' },
        { img: Attendees_laser_digital, linkTo: 'https://www.laserdigital.com/' },
        { img: Attendees_business_vellow, linkTo: 'https://www.swiss-crowd.com/' },
        { img: Attendees_cathy_capital, linkTo: 'https://www.cathaycapital.com/' },
        { img: Attendees_chorus_one, linkTo: 'https://chorus.one/' },
        { img: Attendees_apex_venture, linkTo: 'https://www.apex.ventures/' },
        { img: Attendees_ledger, linkTo: 'https://www.ledger.com/fr' },
        { img: Attendees_syz, linkTo: 'https://www.syzcapital.com/en' },
        { img: Attendees_zeltner, linkTo: 'https://www.zeltnerco.com/' },
        { img: Attendees_tenity, linkTo: 'https://tenity.com' },
        { img: Attendees_mindfullness_escape, linkTo: 'https://www.mindfulnesscap.com/' },
        { img: Attendees_onramp, linkTo: 'https://onramp-capital.com/' },
        { img: Attendees_shiku, linkTo: 'https://shiku.com/' },
        { img: Attendees_cointelegraph, linkTo: 'https://cointelegraph.com/' },
        { img: Attendees_liquity, linkTo: 'https://www.liquity.org/' },
        { img: Attendees_fabric, linkTo: 'https://fabric.vc/' },
        { img: Attendees_yumi, linkTo: 'https://yumi.io/' },
        { img: Attendees_dsocial, linkTo: 'https://dsocial.app/' },
        { img: Attendees_uzh, linkTo: 'https://www.blockchain.uzh.ch/' },
        { img: Attendees_amber, linkTo: 'https://ambergroup.io/' },
        { img: Attendees_sge, linkTo: 'https://www.s-ge.com/en?ct=' },
        { img: Attendees_maas_ventures, linkTo: 'https://maasventures.io/' },
        { img: Attendees_sinoswiss, linkTo: 'https://www.sinoswiss-holding.com/' },
        { img: Attendees_bity, linkTo: 'https://bity.com/' },
        { img: Attendees_sygnum, linkTo: 'https://www.sygnum.com/' },
        { img: Attendees_BS, linkTo: 'https://bitcoinsuisse.com/' },
        { img: Attendees_seba, linkTo: 'https://www.seba.swiss/' },
        { img: Attendees_swiss_borg, linkTo: 'https://swissborg.com/' },
    ];

    useLayoutEffect(() => {
        window.addEventListener('click', (e) => colse_modal(e));
    }, []);
    useLayoutEffect(() => {
        return () => {
            window.removeEventListener('click', (e) => colse_modal(e));
        };
    });
    const colse_modal = (e) => {
        if (e.target.className === 'capital-modal') {
            setShowModal(false);
        }
        if (
            e.target.className === 'capital-modal-partner' ||
            e.target.className === 'capital-modal-partner-detail'
        ) {
            setShowPartnerModal(false);
        }
    };
    const add = useCallback(() => {
        setNum((prev) => prev + 1);
    }, []);
    const platinumList = [
        { img: bfImg, linkTo: 'https://www.bochslerfinance.com/' },
        { img: bityImg, linkTo: 'https://bity.com/' },
        { img: monksImg, linkTo: 'https://www.6m.lu/' },
        { img: dfinityImg, linkTo: 'https://internetcomputer.org/' },
    ];
    const goldList = [
        { img: Attendees_shiku, linkTo: 'https://shiku.com/' },
        { img: Attendees_yumi, linkTo: 'https://yumi.io/' },
        { img: Attendees_dsocial, linkTo: 'https://dsocial.app/' },
        { img: Attendees_origyn, linkTo: 'https://www.origyn.com/' },
    ];
    const mediaPartnerList = [
        { img: media_partner_cointelegraph, linkTo: '' },
        { img: media_partner_coinhustle, linkTo: '' },
        { img: media_partner_foresightnew, linkTo: '' },
        { img: media_partner_odaily, linkTo: '' },
        { img: media_partner_cryptopolitan, linkTo: '' },
    ];
    const partnetArr = [
        {
            date: 'October 3-4',
            url: Partner_cvsummit,
            modal_img: Partner_phone_cvsummit,
            desc: 'CV Summit 2023 |  Zug, Switzerland',
            id: 1,

            pc_thumb:
                'https://buckets.bochslerfinance.com/shiku-public/assets/img/capital/cvSummit2023_pc.png',
            pad_thumb:
                'https://buckets.bochslerfinance.com/shiku-public/assets/img/capital/cvSummit2023_pad.png',
            phone_thumb:
                'https://buckets.bochslerfinance.com/shiku-public/assets/img/capital/cvSummit2023_mobile.png',
            title: 'CV Summit 2023',
            subtitle: '3-4 October |  Zug, Switzerland',
            content: [
                'Crypto Valley was recently crowned the #1 global blockchain hub. Find out why at #CVSummit 2023 as we celebrate the 10-year anniversary of Crypto Valley and look forward to what’s next.',
                'CV Summit, now in its 9th edition, is the longest-established blockchain and Web3 business conference in Switzerland. Connect with industry experts, engage in practical workshops at our Startup & Web3 space, and meet investors at our VC power hour. The conference even offers a customized app to maximize your business and networking opportunities.',
                'Will we see you there? Get your tickets now via <a target="_blank" href="https://www.cvsummit.ch">www.cvsummit.ch</a>',
            ],
            linkTo: 'https://www.eventbrite.com/e/cv-summit-2023-tickets-589756436377?discount=CVS_Swiss_Blockchain_Capital_Days',
        },
        {
            date: 'October 4',
            url: Partner_Lange,
            desc: 'Apéro at Lange & Söhne | Zürich',
            id: 1,
            modal_img: Partner_phone_Lange,
            pc_thumb:
                'https://buckets.bochslerfinance.com/shiku-public/assets/img/capital/Lange_pc.png',
            pad_thumb:
                'https://buckets.bochslerfinance.com/shiku-public/assets/img/capital/Lange_pad.png',
            phone_thumb:
                'https://buckets.bochslerfinance.com/shiku-public/assets/img/capital/Lange_phone.png',
            title: 'Apéro at Lange & Söhne',
            subtitle: '3-4 October |  Zug, Switzerland',
            content: [
                'Long considered one of the finest purveyors of mechanical horological instruments, Lange & Söhne offers broad collections ranging from classic to sporty, all exuding an unmistakably timeless aesthetic. Experience a level of detail seldom found in watchmaking these days. No collection is complete without a Lange. We invite you to join us for an afternoon of understanding sophistication.Todo Berchtold & Marc Unterburger, representatives of <a target="_blank" href="https://alange-soehne.com/">Lange & Söhne</a> (Zürich), will be present to introduce you to this world of horological excellence. They will be happy to offer expert advice on building your collection - or just finding that perfect piece.',
            ],
            linkTo: 'https://lu.ma/9rz1bo2e',
        },
        {
            date: 'October 20-21',
            url: Partner_Lugano_Plan,
            desc: 'Lugano Plan ₿ Forum 2023 | Lugano',
            id: 1,
            modal_img: Partner_phone_Lugano_Plan,
            pc_thumb:
                'https://buckets.bochslerfinance.com/shiku-public/assets/img/capital/Lugano_Plan_pc.png',
            pad_thumb:
                'https://buckets.bochslerfinance.com/shiku-public/assets/img/capital/Lugano_Plan_pad.png',
            phone_thumb:
                'https://buckets.bochslerfinance.com/shiku-public/assets/img/capital/Lugano_Plan_phone.png',
            title: 'Lugano Plan ₿ Forum 2023',
            subtitle: '3-4 October |  Zug, Switzerland',
            content: [
                'Lugano’s Plan ₿ Forum is the premier Bitcoin conference bringing together world leaders, technologists, and entrepreneurs to discuss nation-state Bitcoin adoption, economics, financial freedom, and freedom of speech. Lugano is home to over 350+ merchants that accept Bitcoin and in October we’ll host world-class speakers such as Adam Back, Phil Zimmerman, Elizabeth Stark, Julien Assange’s family, and many more.',
            ],
            linkTo: 'https://planb.lugano.ch/planb-forum/',
        },
    ];
    const arr = [
        {
            date: 'October 4',
            day: 'Wednesday',
            url: program1,
            desc: 'Arrival   |  Meet & Greet   |  VIP Dinner',
            id: 1,
            modal_img: desktop_wednesday_wed_1,
            child: [
                {
                    id: '1-1',
                    start_time: '14:00',
                    end_time: '18:00',
                    img: desktop_wednesday_wed_2,
                    phoneImg: mobile_wednesday_wed_1,
                    title: 'Meet and Greet with Aperitif',
                    desc: null,
                },
                {
                    id: '1-2',
                    start_time: '18:00',
                    end_time: '21:00',
                    img: desktop_wednesday_wed_3,
                    phoneImg: mobile_wednesday_wed_2,
                    title: 'VIP Dinner',
                    desc: null,
                },
            ],
        },
        {
            date: 'October 5',
            day: 'Thursday',
            url: program2,
            desc: '|   VIP Dinner   |   Private Concert',
            id: 2,
            modal_img: desktop_thursday_thur_1,
            child: [
                {
                    id: '2-1',
                    start_time: '09:00',
                    end_time: '10:00',
                    img: desktop_thursday_thur_2,
                    phoneImg: mobile_thursday_thur_1,
                    title: 'Welcome & Breakfast at SBL HQ',
                    desc: null,
                },
                {
                    id: '2-2',
                    start_time: '10:00',
                    end_time: '10:10',
                    img: desktop_thursday_thur_3,
                    phoneImg: mobile_thursday_thur_2,
                    title: 'Introduction to Swiss Blockchain Labs',
                    // desc: "Dr. Stephanie Dreier – Managing Director of Swiss Blockchain Labs",
                    desc: [
                        {
                            title: 'Dr. Stephanie Dreier',
                            linker: ' - ',
                            content: 'Managing Director of Swiss Blockchain Labs',
                        },
                    ],
                    showMore: 'up',
                },
                {
                    id: '2-3',
                    start_time: '10:10',
                    end_time: '10:30',
                    img: desktop_thursday_thur_4,
                    phoneImg: mobile_thursday_thur_3,
                    title: 'Keynote: Macroeconomics and Cryptoeconomics : 7 Forecasts',
                    link: '2 - Yulin - expert.pdf',
                    desc: [
                        {
                            title: 'Dr. Yulin Liu',
                            linker: ' - ',
                            content:
                                'Partner at Bochsler Group | Senior Economic Advisor at Chainlink | Founding President of Swiss Blockchain Labs',
                        },
                    ],
                    // desc: "Dr. Yulin Liu – Partner at Bochsler Group | Senior Economics Advisor at Chainlink | Founding President of Swiss Blockchain Labs",
                    showMore: 'up',
                },
                {
                    id: '2-4',
                    start_time: '10:30',
                    end_time: '10:45',
                    img: desktop_thursday_thur_5,
                    phoneImg: mobile_thursday_thur_4,
                    title: 'Expert Talk: Real World Assets on Blockchain: The Fuel to the Next Bull Run?',
                    link: '3 - Melissa Song - RWA Expert Talk.pdf',
                    desc: [
                        {
                            title: 'Melissa Song',
                            linker: ' - ',
                            content: 'Head of Business at Yumi NFT Marketplace',
                        },
                    ],
                    // desc: "Melissa Song – Head of Business at Yumi NFT Marketplace",
                    showMore: 'up',
                },
                {
                    id: '2-5',
                    start_time: '10:45',
                    end_time: '11:15',
                    img: desktop_thursday_thur_6,
                    phoneImg: mobile_thursday_thur_5,
                    title: "Panel Discussion: Evaluating RWA Readiness: Where's the Opportunities and What Are the Challenges?",
                    desc: [
                        {
                            title: 'Panelists: ',
                            content:
                                'Chichi Hong (Asia Partner, Hack VC), Gavin Wang (Managing Partner, SNZ), Thomas Ao (Partner, Mindfulness Capital), Cyrus Fazel (CEO & Founder, Swissborg)',
                            end: '  |  ',
                        },
                        {
                            title: 'Moderator: ',
                            content: 'Melissa Song',
                        },
                    ],
                    // desc: "Panelists: TBD | Moderator: Melissa Song",
                    showMore: 'up',
                },
                {
                    id: '2-6',
                    start_time: '11:15',
                    end_time: '11:30',
                    img: null,
                    phoneImg: null,
                    title: 'Coffee break',
                    desc: null,
                },
                {
                    id: '2-7',
                    start_time: '11:30',
                    end_time: '11:50',
                    img: desktop_thursday_thur_9,
                    phoneImg: mobile_thursday_thur_8,
                    title: 'Keynote: Cypherspace as Cloud 3.0',
                    link: '5 - Dominic Williams - Build On The Internet V17.5.pdf',
                    desc: [
                        {
                            title: 'Dominic Williams',
                            linker: ' - ',
                            content: 'Founder & Chief Scientist of Dfinity Foundation',
                        },
                    ],
                    // desc: "Mike Schwartz – Co-founder of Origyn Foundation",
                    showMore: 'up',
                },
                {
                    id: '2-8',
                    start_time: '11:50',
                    end_time: '12:20',
                    img: desktop_program_gldt,
                    phoneImg: mobile_program_gldt,
                    title: 'Panel Discussion: Gold by Origyn',
                    desc: [
                        {
                            title: 'Panelists: ',
                            content:
                                'Paul Meeusen (VP of Partnerships, Dfinity Foundation), Victor Gailly (Operations Manager, BAS), Dustin Becker (Scientist Engineer, Bochsler Finance)',
                            end: '  |  ',
                        },
                        {
                            title: 'Moderator: ',
                            content: 'Fernando Soares (Co-Founder, Quantum Agency)',
                        },
                    ],
                    // desc: "Panelists: TBD   |   Moderator: Mike Schwartz",
                    showMore: 'up',
                },
                {
                    id: '2-9',
                    start_time: '12:20',
                    end_time: '13:30',
                    img: null,
                    phoneImg: null,
                    title: 'Lunch break',
                    desc: null,
                },
                {
                    id: '2-10',
                    start_time: '13:30',
                    end_time: '13:50',
                    img: desktop_thursday_thur_7,
                    phoneImg: mobile_thursday_thur_6,
                    title: 'Expert Talk: Migrating Global Commerce to Web3',
                    link: '7 - Mike Schwarz - expert talk.pdf',
                    desc: [
                        {
                            title: 'Mike Schwartz',
                            linker: ' - ',
                            content: 'Co-Founder of Origyn Foundation',
                        },
                    ],
                    // desc: "TBD",
                    showMore: 'up',
                },
                {
                    id: '2-11',
                    start_time: '13:50',
                    end_time: '14:20',
                    img: desktop_thursday_thur_8,
                    phoneImg: mobile_thursday_thur_7,
                    title: 'Panel Discussion: Getting Real Web3 Market Traction - How, Who & When',
                    desc: [
                        {
                            title: 'Panelists: ',
                            content:
                                'Leeor Groen (Managing Director, Spartan Group), Sebastian Blum (Partner, Greenfield Capital), Thomas Yao (Founding Partner, IMO Ventures), Jonas Jünger (Managing Director DACH Region, Binance)',
                            end: '  |  ',
                        },
                        {
                            title: 'Moderator: ',
                            content: 'Mike Schwartz',
                        },
                    ],
                    // desc: "Panelists: TBD | Moderator: SBCD Gold Sponsor",
                    showMore: 'up',
                },
                {
                    id: '2-12',
                    start_time: '14:20',
                    end_time: '14:30',
                    img: null,
                    phoneImg: null,
                    title: 'Coffee break',
                    desc: null,
                },
                {
                    id: '2-13',
                    start_time: '14:30',
                    end_time: '14:45',
                    img: desktop_thursday_thur_11,
                    phoneImg: mobile_thursday_thur_10,
                    title: 'Expert Talk: Decode Creator Economy 3.0',
                    link: '9 - Ching - Decode Creator Economy 3.0.pdf',
                    desc: [
                        {
                            title: 'Ching Nola',
                            linker: ' - ',
                            content:
                                'Head of Product at DSocial & Elías A. Nieto - Partner at Quantum Agency',
                        },
                    ],
                    // desc: "Ching Nola – Head of Product at Dsocial",
                    showMore: 'up',
                },
                {
                    id: '2-14',
                    start_time: '14:45',
                    end_time: '15:15',
                    img: desktop_thursday_thur_12,
                    phoneImg: mobile_thursday_thur_11,
                    title: 'Panel Discussion: Tokens of Creativity: Crafting the Future of Content Ownership and the Creator Economy on Web3',
                    desc: [
                        {
                            title: 'Panelists: ',
                            content:
                                'Armin ZadakBar (President, European Web3 Organization), Julien Aerni (Co-Founder, Quantum Agency), Anna Ladyshenski (Partner Blockchain & Web3, Page Executive), Nicolas Salmon (Founding Partner, Quadrilium Ventures)',
                            end: '  |  ',
                        },
                        {
                            title: 'Moderator: ',
                            content: 'Ching Nola',
                        },
                    ],
                    // desc: "Panelists: TBD | Moderator: Ching Nola",
                    showMore: 'up',
                },
                {
                    id: '2-15',
                    start_time: '15:15',
                    end_time: '15:30',
                    img: null,
                    phoneImg: null,
                    title: 'Coffee break',
                    desc: null,
                },
                {
                    id: '2-16',
                    start_time: '15:30',
                    end_time: '15:45',
                    img: desktop_program_shiku,
                    phoneImg: mobile_program_shiku,
                    title: 'Expert Talk: Embracing Web3 and AI as the Keys to the Open Metaverse',
                    link: '11 - Michael Peterer - Expert Talk Metaverse SBCD.pdf',
                    desc: [
                        {
                            title: 'Dr. Michael Peterer',
                            linker: ' - ',
                            content: 'Head of Business & Growth at Shiku Foundation',
                        },
                    ],
                    // desc: "Dr. Michael Peterer – Head of Growth at Shiku Metaverse",
                    showMore: 'up',
                },
                {
                    id: '2-17',
                    start_time: '15:45',
                    end_time: '16:15',
                    // img: desktop_thursday_thur_14,
                    // phoneImg: mobile_thursday_thur_13,
                    img: desktop_thursday_thur_13,
                    phoneImg: mobile_thursday_thur_12,
                    title: 'Panel Discussion: Web3 and the Metaverse: Where Do We Go from Here?',
                    desc: [
                        {
                            title: 'Panelists: ',
                            content:
                                'Steffen Bassler (CEO, SwissOne Capital), Robin Vallat (Organizer, Metaverse Summit), Rob-Roy Roedel (Partner, SPiCE VC), Brett Krause (General Partner, Transcend Fund)',
                            end: '  |  ',
                        },
                        {
                            title: 'Moderator: ',
                            content: 'Dr. Michael Peterer',
                        },
                    ],
                    // desc: "Panelists: TBD | Moderator: Dr. Michael Peterer",
                    showMore: 'up',
                },
                {
                    id: '2-18',
                    start_time: '16:15',
                    end_time: '16:30',
                    img: null,
                    phoneImg: null,
                    title: 'Coffee break',
                    desc: null,
                },
                {
                    id: '2-19',
                    start_time: '16:30',
                    end_time: '16:45',
                    img: desktop_thursday_thur_15,
                    phoneImg: mobile_thursday_thur_14,
                    title: 'Expert Talk: Breaking Boundaries: Evolution of Brokerage Services in a Decentralized World',
                    desc: [
                        {
                            title: 'Yves Honoré',
                            // linker: " - ",
                            content: ' (CEO & Co-Founder, Bity)',
                        },
                    ],
                    // desc: "Alexis Roussel – Chairman of Bity board",
                    showMore: 'up',
                },
                {
                    id: '2-20',
                    start_time: '16:45',
                    end_time: '17:00',
                    // img: desktop_thursday_thur_16,
                    // phoneImg: mobile_thursday_thur_15,
                    img: desktop_thursday_Julien_Wolff,
                    phoneImg: mobile_thursday_Julien_Wolff,
                    title: 'Expert Talk: Venture Capital in Web3 Ecosystem',
                    link: '14 - Julien Wolff - 6M - Swiss Blockchain Capital Day - 10.2023.pdf',
                    desc: [
                        {
                            title: 'Julien Wolff',
                            linker: ' - ',
                            content:
                                'Board Member of 6 Monks (Registered Alternative Investment Fund Manager)',
                        },
                    ],
                    // desc: "Panelists: TBD | Moderator: Julien Wolff – at 6monks",
                    showMore: 'up',
                },
                {
                    id: '2-21',
                    start_time: '17:00',
                    end_time: '17:30',
                    img: desktop_thursday_Julie_Bourgeois,
                    phoneImg: mobile_thursday_Julie_Bourgeois,
                    title: 'Panel Discussion: Web3 Opportunities for Private Equity',
                    desc: [
                        {
                            title: 'Panelists: ',
                            content:
                                'Asim Ahmad (Partner, Eterna Capital), Michael Tan (Co-Founder, Veris Ventures), Luca Burlando (Head of Ventures & Partnerships, Sygnum), Sébastien Genoud (VC, L1 Digital)',
                            end: '  |  ',
                        },
                        {
                            title: 'Moderator: ',
                            content: 'Julie Bourgeois (Head of Legal and Compliance, 6 Monks)',
                        },
                    ],
                    // desc: "Panelists: TBD | Moderator: Julien Wolff – at 6monks",
                    showMore: 'up',
                },
                {
                    id: '2-22',
                    start_time: '17:30',
                    end_time: '18:00',
                    img: null,
                    phoneImg: null,
                    title: 'Shuttle to the restaurant',
                    desc: null,
                },
                {
                    id: '2-23',
                    start_time: '18:00',
                    end_time: '21:00',
                    img: desktop_thursday_thur_17,
                    phoneImg: mobile_thursday_thur_16,
                    title: 'Dinner and Private Concert',
                    desc: null,
                },
            ],
        },
        {
            date: 'October 6',
            day: 'Friday',
            url: program3,
            desc: 'Hiking | Mountain Top VIP Lunch | Networking Apéro | Boat Cruise',
            id: 3,
            modal_img: desktop_friday_fri_1,
            child: [
                {
                    id: '3-1',
                    start_time: '09:00',
                    end_time: '11:00',
                    img: desktop_friday_fri_2,
                    phoneImg: mobile_friday_fri_1,
                    title: 'Networking Brunch',
                    desc: null,
                },
                {
                    id: '3-2',
                    start_time: '11:00',
                    end_time: '13:00',
                    img: desktop_friday_fri_3,
                    phoneImg: mobile_friday_fri_2,
                    title: 'Hiking Uetliberg',
                    desc: null,
                },
                {
                    id: '3-3',
                    start_time: '13:00',
                    end_time: '15:00',
                    img: desktop_friday_fri_4,
                    phoneImg: mobile_friday_fri_3,
                    title: 'Mountain Top VIP Lunch',
                    desc: null,
                },
                {
                    id: '3-4',
                    start_time: '15:00',
                    end_time: '18:00',
                    img: desktop_friday_fri_5,
                    phoneImg: mobile_friday_fri_4,
                    title: 'Internet Computer Networking Apéro',
                    desc: null,
                },
                {
                    id: '3-5',
                    start_time: '18:00',
                    end_time: '21:00',
                    img: desktop_friday_fri_6,
                    phoneImg: mobile_friday_fri_5,
                    title: 'Sunset cruise + VIP Dinner',
                    desc: null,
                },
            ],
        },
    ];
    const click_show = (item) => {
        setCurrentInfo(item);
        setCurrentId(Number(item.id) - 1);
        setShowModal(true);
    };
    const click_show_partner = (item) => {
        setCurrentPartnerInfo(item);
        setCurrentPartnerId(Number(item.id) - 1);
        setShowPartnerModal(true);
    };
    // 改变方向
    const changeDirection = (item, type) => {
        const copy_obj = Object.assign({}, currentInfo);
        copy_obj.child.forEach((m) => {
            if (m.id === item.id) {
                m.showMore = type;
            }
        });
        setCurrentInfo(copy_obj);
    };

    const JoinSwiper = React.memo(() => {
        let carouselRef = null;
        const [currentSlide, setCurrentSlide] = useState(0);
        const playVedio = () => {
            document.querySelector(`.slick-active video`).play();
            document.querySelector(`.slick-active .play-button`).style.display = 'none';
        };
        const showPlayButton = () => {
            document.querySelector(`.slick-active .play-button`).style.display = 'block';
        };
        const onChange = (currentSlide) => {
            setCurrentSlide(currentSlide);
        };

        const handleGoToSlide = (slideIndex) => {
            document.querySelectorAll(`.slick-slide video`).forEach((item) => {
                item.pause();
            });
            document.querySelectorAll(`.slick-slide .play-button`).forEach((item) => {
                item.style.display = 'block';
            });
            carouselRef && carouselRef.goTo(slideIndex);
        };

        const next = () => {
            document.querySelectorAll(`.slick-slide video`).forEach((item) => {
                item.pause();
            });
            document.querySelectorAll(`.slick-slide .play-button`).forEach((item) => {
                item.style.display = 'block';
            });
            carouselRef && carouselRef.next();
        };

        const prev = () => {
            document.querySelectorAll(`.slick-slide video`).forEach((item) => {
                item.pause();
            });
            document.querySelectorAll(`.slick-slide .play-button`).forEach((item) => {
                item.style.display = 'block';
            });
            carouselRef && carouselRef.prev();
        };

        const videoList = [
            'https://buckets.bochslerfinance.com/shiku-public/SBL/day1swisslabdeft.mp4',
            'https://buckets.bochslerfinance.com/shiku-public/SBL/day2swisslabdeft.mp4',
            'https://buckets.bochslerfinance.com/shiku-public/SBL/day3finalcolologo.mp4',
        ];

        // const videoList = [
        //   require("./video/day1swisslabdeft.mp4"),
        //   require("./video/day2swisslabdeft.mp4"),
        //   require("./video/day3finalcolologo.mp4"),
        // ];

        return (
            <>
                <Carousel afterChange={onChange} ref={(ref) => (carouselRef = ref)} dots={false}>
                    {videoList.map((item, index) => {
                        return (
                            <div className="swiper-slide-join" key={`my-video-${index}`}>
                                <video
                                    className="my-video-join"
                                    id={`my-video-${index}`}
                                    onEnded={() => showPlayButton(`my-video-${index}`)}
                                >
                                    {currentSlide === index && (
                                        <source src={item} type="video/mp4" preload="auto" />
                                    )}
                                </video>

                                <div
                                    className="play-button"
                                    id={`play-button-${index}`}
                                    onClick={playVedio}
                                ></div>
                            </div>
                        );
                    })}
                </Carousel>
                <div className="my-dots">
                    <div
                        className={`cell ${currentSlide === 0 && 'active'}`}
                        onClick={() => handleGoToSlide(0)}
                    >
                        <span>01</span> Wednesday
                    </div>
                    <div
                        className={`cell ${currentSlide === 1 && 'active'}`}
                        onClick={() => handleGoToSlide(1)}
                    >
                        <span>02</span> Thursday
                    </div>
                    <div
                        className={`cell ${currentSlide === 2 && 'active'}`}
                        onClick={() => handleGoToSlide(2)}
                    >
                        <span>03</span> Friday
                    </div>
                </div>
                <div className="prev" onClick={prev}></div>
                <div className="next" onClick={next}></div>
            </>
        );
    });

    const Joining = React.memo(() => {
        const imgList = [
            'https://buckets.bochslerfinance.com/shiku-public/assets/img/join-img-1.jpg',
            'https://buckets.bochslerfinance.com/shiku-public/assets/img/join-img-2.jpg',
            'https://buckets.bochslerfinance.com/shiku-public/assets/img/join-img-3.jpg',
            'https://buckets.bochslerfinance.com/shiku-public/assets/img/join-img-4.jpg',
        ];
        return (
            <div className="join">
                <div className="join-top">
                    <div className="title">Thank you for joining Swiss Blockchain Capital Day</div>
                    <div className="desc">
                        Swiss Blockchain Capital Day surpassed all expectations as the premier
                        gathering for VCs, Family Offices, and other Web3 key players, and it's all
                        thanks to your support. Together, we are building a community focused on
                        long-lasting connections and tangible actions that will drive Web3
                        innovation forward. Please stay tuned for our next big event in 2024.
                    </div>
                    <div
                        onClick={() =>
                            window.open('https://buckets.bochslerfinance.com/sbl-public/sbl.zip')
                        }
                        className="join-btn"
                    >
                        <div className="capital-reg-right-title">Download photos</div>
                        <div className="capital-reg-right-img">
                            <img
                                src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className="join-img-list">
                    {imgList.map((item) => {
                        return (
                            <div key={item}>
                                <img src={item} alt="" />
                            </div>
                        );
                    })}
                </div>
                <div className="join-swiper">
                    <JoinSwiper />
                </div>
            </div>
        );
    });
    const goPdfLink = (link) => {
        window.open(
            `https://buckets.bochslerfinance.com/shiku-public/Presentations/Presentations/${encodeURIComponent(
                link,
            )}`,
        );
    };

    return (
        <div className="capital-wrap">
            <div className="capital-header">
                <div className="capital-header-head">
                    <img className="capital-header-head-logo" src={Logo} alt="" />
                    <Link to="/" className="capital-header-head-link">
                        <img src={ArrowRight} alt="" />
                        Back to Homepage
                    </Link>
                </div>
                <div className="capital-header-middle">Swiss Blockchain Capital Day</div>
                <div className="capital-header-bottom">October 4-6, 2023 | Zürich, Switzerland</div>
            </div>
            <div className="capital-number-info">
                {appType !== 'phone' && <img src={pcImg} alt="" onLoad={() => reset()} />}
                {appType === 'phone' && <img src={phonePng} alt="" onLoad={() => reset()} />}
                <div className="capital-number-info-desc">Exclusive & private event</div>
                <div className="capital-number-info-bottom">
                    <div className="capital-number-info-desc-number">{value}+</div>
                    <div className="capital-number-info-desc-desc">
                        Global VCs meet Swiss Funds of Funds & Family Offices
                    </div>
                </div>
            </div>
            <Joining />
            <div className="capital-program">
                <div className="capital-program-desc">
                    An exclusive gathering of the most influential players in the blockchain
                    industry, Swiss Blockchain Capital Day offers our guests a unique opportunity to
                    come together, share ideas, and anticipate market trends. Our focus is on
                    building deep, long-lasting connections. Join us to access premier industry
                    resources. We look forward to welcoming you in Zürich!
                </div>
                {appType !== 'phone' && (
                    <div>
                        <div className="capital-program-bottom">
                            <div className="capital-program-bottom-left">
                                <div className="infoText">Program</div>
                            </div>
                            <ul className="capital-program-bottom-right">
                                {arr.map((m) => {
                                    return (
                                        <li key={m.id} className="capital-program-bottom-right-li">
                                            <div className="capital-program-bottom-right-li-left">
                                                <div className="capital-date">{m.date}</div>
                                                <div className="capital-day">{m.day}</div>
                                            </div>
                                            <img
                                                className="capital-program-bottom-right-li-middle"
                                                src={m.url}
                                                alt=""
                                            />
                                            <div className="capital-program-bottom-right-li-right">
                                                {m.date === 'October 5' && (
                                                    <span>Full program </span>
                                                )}
                                                {m.date === 'October 6' &&
                                                    appType === 'pad' &&
                                                    'Hiking | VIP Lunch | Networking Apéro | Boat Cruise'}
                                                {m.date === 'October 6' &&
                                                    appType === 'pc' &&
                                                    m.desc}
                                                {m.date !== 'October 6' && m.desc}
                                                {/* {m.desc} */}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="capital-program-button">
                            <div
                                className="capital-program-learn"
                                onClick={() => click_show(arr[1])}
                            >
                                <div className="capital-reg-right-img">
                                    <img
                                        src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                        alt=""
                                    />
                                </div>
                                <div className="capital-reg-right-title">View Full Program</div>
                            </div>
                        </div>
                    </div>
                )}
                {appType === 'phone' && (
                    <div>
                        <div className="capital-program-bottom-phone">
                            <div className="capital-program-bottom-phone-title">Program</div>
                            <ul className="capital-program-bottom-phone-box">
                                {arr.map((item) => {
                                    return (
                                        <li key={item.id} className="capital-phone-box-li">
                                            <div
                                                className="capital-phone-box-li-top"
                                                style={{
                                                    backgroundImage: `url('${item.url}')`,
                                                }}
                                            >
                                                <div className="capital-phone-box-li-top-date">
                                                    {item.date}
                                                </div>
                                                <div className="capital-phone-box-li-top-day">
                                                    {item.day}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    item.id === 3
                                                        ? 'capital-phone-box-li-bottom special-pd'
                                                        : 'capital-phone-box-li-bottom'
                                                }
                                            >
                                                {item.date === 'October 5' && (
                                                    <span className="capital-special-color">
                                                        Full program{' '}
                                                    </span>
                                                )}
                                                {item.desc}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="capital-program-button">
                            <div
                                className="capital-program-learn"
                                onClick={() => click_show(arr[1])}
                            >
                                <div className="capital-reg-right-img">
                                    <img
                                        src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                        alt=""
                                    />
                                </div>
                                <div className="capital-reg-right-title">View Full Program</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {appType !== 'phone' && (
                <div className="capital-partner">
                    <div className="capital-partner-vertical"></div>
                    <div className="capital-partner-title">Partner events</div>
                    {partnetArr.map((m) => {
                        return (
                            <div>
                                <div className="capital-partner-bottom">
                                    <div className="capital-partner-bottom-left"></div>
                                    <div className="capital-partner-bottom-right">
                                        <div key={m.id} className="capital-partner-bottom-right-li">
                                            <div className="capital-partner-bottom-right-li-left">
                                                <div className="capital-date">{m.date}</div>
                                            </div>
                                            <img
                                                className="capital-partner-bottom-right-li-middle"
                                                src={m.url}
                                                alt=""
                                            />
                                            <div className="capital-partner-bottom-right-li-right">
                                                {m.desc}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="capital-partner-button">
                                    <div
                                        className="capital-partner-learn"
                                        onClick={() => click_show_partner(m)}
                                    >
                                        <div className="capital-reg-right-img">
                                            <img
                                                src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                                alt=""
                                            />
                                        </div>
                                        <div className="capital-reg-right-title">Learn More</div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            {appType === 'phone' && (
                <div className="capital-partner">
                    <div className="capital-partner-bottom-phone">
                        <div className="capital-partner-bottom-phone-title">Partner events</div>

                        {partnetArr.map((item) => {
                            return (
                                <ul className="capital-partner-bottom-phone-box">
                                    <li key={item.id} className="capital-phone-box-li">
                                        <div
                                            className="capital-phone-box-li-top"
                                            style={{
                                                backgroundImage: `url('${item.modal_img}')`,
                                            }}
                                        ></div>
                                        <div
                                            className={
                                                item.id === 3
                                                    ? 'capital-phone-box-li-bottom special-pd'
                                                    : 'capital-phone-box-li-bottom'
                                            }
                                        >
                                            {item.desc}
                                        </div>
                                    </li>
                                    <div className="capital-partner-button">
                                        <div
                                            className="capital-partner-learn"
                                            onClick={() => click_show_partner(item)}
                                        >
                                            <div className="capital-reg-right-img">
                                                <img
                                                    src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="capital-reg-right-title">
                                                Learn More
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            );
                        })}
                    </div>
                    {/* <div className="capital-partner-button">
            <div className="capital-partner-learn" onClick={() => click_show_partner(partnetArr[0])}>
              <div className="capital-reg-right-img">
                <img
                  src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                  alt=""
                />
              </div>
              <div className="capital-reg-right-title">Learn More</div>
            </div>
          </div> */}
                </div>
            )}

            <div className="capital-logo-content">
                <h1 className="capital-logo-title">Sponsors</h1>
                <div className="capital-logo-desc">
                    Do you want to sponsor Swiss Blockchain Capital Day?{' '}
                    <Link to="/sponsor">Reach out.</Link>
                </div>
                <div className="capital-logo-platinum">
                    <div className="capital-platinum-top">
                        <div>Platinum</div>
                        <div>sponsors</div>
                    </div>
                    <div className="capital-platinum-bottom">
                        {platinumList.map((item, index) => {
                            return (
                                <div key={index} className="capital-platinum-bottom-box">
                                    {!item.linkTo && <img src={item.img} alt="" />}
                                    {item.linkTo && (
                                        <Link target="_blank" to={item.linkTo}>
                                            <img src={item.img} alt="" />
                                        </Link>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="capital-logo-gold">
                    <div className="capital-gold-top">
                        <div>Gold</div>
                        <div>sponsors</div>
                    </div>
                    <div className="capital-gold-bottom">
                        {goldList.map((item, index) => {
                            return (
                                <div key={index} className="capital-gold-bottom-box">
                                    {!item.linkTo && <img src={item.img} alt="" />}
                                    {item.linkTo && (
                                        <Link target="_blank" to={item.linkTo}>
                                            <img src={item.img} alt="" />
                                        </Link>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="capital-logo-middle">
                    <PartnersWrap list={imgList} title={'Attendees'} />
                </div>
                {appType === 'pc' && (
                    <div className="capital-logo-last">
                        <h1 className="capital-logo-last-title">More to be confirmed</h1>
                        <div className="capital-logo-last-component">
                            <PlatformLoadingDom />
                        </div>
                    </div>
                )}
            </div>
            {appType !== 'phone' && (
                <div className="capital-media-partner">
                    <div className="capital-media-partner-left">
                        <div className="capital-media-partner-left-content">
                            <div>Media Partners</div>
                            <div>
                                <img src={media_partner_cointelegraph} alt="" />
                                <img src={media_partner_coinhustle} alt="" />
                            </div>
                            <div>
                                <img src={media_partner_foresightnew} alt="" />
                                <img src={media_partner_odaily} alt="" />
                            </div>
                            <div>
                                <img src={media_partner_cryptopolitan} alt="" />
                                <img src={media_partner_haitun} alt="" />
                            </div>
                            <div>
                                <img src={media_partner_wjb} alt="" />
                                <img src={media_partner_BeInCrypto} alt="" />
                            </div>
                            {/* <div>
                <img src={media_partner_wjb} alt="" />
                
              </div> */}
                        </div>
                    </div>
                    <div className="capital-media-partner-right">
                        {appType === 'pc' && (
                            <img
                                src="https://buckets.bochslerfinance.com/shiku-public/assets/img/capital/media_partner_pc.png"
                                alt=""
                            />
                        )}
                        {appType === 'pad' && (
                            <img
                                src="https://buckets.bochslerfinance.com/shiku-public/assets/img/capital/media_partner_pad.png"
                                alt=""
                            />
                        )}
                    </div>
                </div>
            )}
            {appType === 'phone' && (
                <div className="capital-media-partner">
                    <div className="capital-media-partner-right">
                        <img
                            src="https://buckets.bochslerfinance.com/shiku-public/assets/img/capital/media_partner_phone.png"
                            alt=""
                        />
                    </div>
                    <div className="capital-media-partner-left">
                        <div className="capital-media-partner-left-content">
                            <div>Media Partners</div>
                            <div>
                                <img src={media_partner_cointelegraph} alt="" />
                                <img src={media_partner_coinhustle} alt="" />
                            </div>
                            <div>
                                <img src={media_partner_foresightnew} alt="" />
                                <img src={media_partner_odaily} alt="" />
                            </div>
                            <div>
                                <img src={media_partner_cryptopolitan} alt="" />
                                <img src={media_partner_haitun} alt="" />
                            </div>
                            <div>
                                <img src={media_partner_wjb} alt="" />
                                <img src={media_partner_BeInCrypto} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div hidden className="capital-registration">
                <h1 className="capital-registration-title">Sponsorship</h1>
                {/* <div className="capital-registration-pass">Special Pass</div>
        <div className="capital-registration-head">
          <ul className="capital-reg-left">
            <li>VIP ticket</li>
            <li>CHF 4999.–</li>
            <li>
              <div className="common-quan"></div>
              VIP lunches and dinners, hiking, boat cruise, private concert all included
            </li>
          </ul>
          <Link to="/sponsor" className="capital-common-right w-230">
            <div className="capital-reg-right-title">GET A TICKET</div>
            <div className="capital-reg-right-img">
              <img
                src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                alt=""
              />
            </div>
          </Link>
        </div>
        <div className="capital-registration-middle">Registration</div> */}
                <div className="capital-registration-bottom">
                    <ul className="capital-registration-bottom-ul">
                        <li className="capital-bottom-liner-left">Silver Sponsorship</li>
                        <li>CHF 10K</li>
                        <li>
                            <div className="common-quan"></div>
                            <div className="ul-wenzi">2 VIP tickets</div>
                        </li>
                        <li>
                            <div className="common-quan"></div>
                            <div className="ul-wenzi">
                                VIP lunches and dinners, hiking, boat cruise, private concert all
                                included
                            </div>
                        </li>
                        <li>
                            <Link to="/sponsor" className="capital-common-right w-220">
                                <div className="capital-reg-right-title">Contact Us</div>
                                <div className="capital-reg-right-img">
                                    <img
                                        src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                        alt=""
                                    />
                                </div>
                            </Link>
                        </li>
                    </ul>
                    <ul className="capital-registration-bottom-ul">
                        <li className="capital-bottom-liner-middle">Gold Sponsorship</li>
                        <li>CHF 25K</li>
                        <li>
                            <div className="common-quan"></div>
                            <div className="ul-wenzi">5 VIP tickets</div>
                        </li>
                        <li>
                            <div className="common-quan"></div>
                            <div className="ul-wenzi">Panel participation</div>
                        </li>
                        <li>
                            <div className="common-quan"></div>
                            <div className="ul-wenzi">
                                Host a side event with additional exposure (optional)
                            </div>
                        </li>
                        <li>
                            <div className="common-quan"></div>
                            <div className="ul-wenzi">
                                VIP lunches and dinners, hiking, boat cruise, private concert all
                                included
                            </div>
                        </li>

                        <li>
                            <Link to="/sponsor" className="capital-common-right w-220">
                                <div className="capital-reg-right-title">Contact us</div>
                                <div className="capital-reg-right-img">
                                    <img
                                        src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                        alt=""
                                    />
                                </div>
                            </Link>
                        </li>
                    </ul>
                    <ul className="capital-registration-bottom-ul">
                        <li className="capital-bottom-liner-right">Platinum Sponsorship</li>
                        <li>CHF 50K</li>
                        <li>
                            <div className="common-quan"></div>
                            <div className="ul-wenzi">10 VIP tickets</div>
                        </li>
                        <li>
                            <div className="common-quan"></div>
                            <div className="ul-wenzi">Panel participation</div>
                        </li>
                        <li>
                            <div className="common-quan"></div>
                            <div className="ul-wenzi">Pick a panel topic</div>
                        </li>
                        <li>
                            <div className="common-quan"></div>
                            <div className="ul-wenzi">
                                Host a side event with additional exposure (optional)
                            </div>
                        </li>
                        <li>
                            <div className="common-quan"></div>
                            <div className="ul-wenzi">
                                VIP lunches and dinners, hiking, boat cruise, private concert all
                                included
                            </div>
                        </li>
                        <li>
                            <Link to="/sponsor" className="capital-common-right w-220">
                                <div className="capital-reg-right-title">Contact us</div>
                                <div className="capital-reg-right-img">
                                    <img
                                        src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                        alt=""
                                    />
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="capital-bottom">
                <div className="capital-bottom-title">Join Swiss Blockchain Capital Day</div>
                <div className="capital-bottom-content">
                    Join us if you are VCs, family offices, fund of funds, crypto exchanges, layer1
                    protocols and media. Contact us if you are interested in sponsorship.
                </div>
                <div className="capital-bottom-box">
                    {/* <div className="capital-bottom-box-left">
            <div className="capital-bottom-box-left-btn">Get a ticket</div>
            <div className="capital-bottom-box-left-svg">
              <img
                src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540805_arrow.svg"
                alt=""
              />
            </div>
          </div> */}
                    <Link
                        target="_blank"
                        to="https://lu.ma/SwissBlockchainCapitalDay"
                        className="capital-bottom-box-right"
                    >
                        <div className="capital-bottom-box-right-btn">SIGN UP</div>
                        <div className="capital-bottom-box-right-svg">
                            <img
                                src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                alt=""
                            />
                        </div>
                    </Link>
                    <Link to="/sponsor" className="capital-bottom-box-right">
                        <div className="capital-bottom-box-right-btn">Contact us</div>
                        <div className="capital-bottom-box-right-svg">
                            <img
                                src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                alt=""
                            />
                        </div>
                    </Link>
                </div>
            </div>
            {/* <div>
        <h1>Registration</h1>
        <div>Special Pass</div>
        <div>
          <ul>
            <li>VIP ticket</li>
            <li>CHF 4999.–</li>
            <li>
              <div></div>
              VIP dinners, hiking, boat cruise, private concert included
            </li>
          </ul>
        </div>
      </div> */}
            <GoToTopWrap custName={{ marginTop: 0 }} />
            {/* modal */}
            {showModal && (
                <div className="capital-modal">
                    <div className="capital-modal-box">
                        {/* 头部 */}
                        <ul className="capital-modal-box-ul">
                            <li className="capital-modal-head">
                                <div className="capital-modal-head-left">
                                    <div className="capital-modal-date">{currentInfo.date}</div>
                                    <div className="capital-modal-day">{currentInfo.day}</div>
                                    {appType === 'phone' && (
                                        <img
                                            className="capital-modal-head-right-close"
                                            src={closeSvg}
                                            alt=""
                                            onClick={() => {
                                                setShowModal(false);
                                            }}
                                        />
                                    )}
                                </div>
                                {appType !== 'phone' && (
                                    <div className="capital-modal-head-right">
                                        <img
                                            className="capital-modal-head-right-img"
                                            src={currentInfo.modal_img}
                                            alt=""
                                        />
                                        <img
                                            className="capital-modal-head-right-close"
                                            src={closeSvg}
                                            alt=""
                                            onClick={() => {
                                                setShowModal(false);
                                            }}
                                        />
                                    </div>
                                )}
                            </li>
                            {appType !== 'phone' &&
                                currentInfo &&
                                currentInfo.child &&
                                currentInfo.child.map((items) => {
                                    return (
                                        <li
                                            key={items.id}
                                            className={`capital-modal-content ${
                                                items.link ? 'point' : ''
                                            } ${items.id === 1 ? 'special-style' : ''}`}
                                            onClick={() => {
                                                items.link && goPdfLink(items.link);
                                            }}
                                        >
                                            <div
                                                className={
                                                    items.img === null && items.end_time !== '17:30'
                                                        ? 'capital-modal-content-left bg-gray'
                                                        : 'capital-modal-content-left'
                                                }
                                            >
                                                <div>{items.start_time}</div>
                                                <div>{items.end_time}</div>
                                            </div>
                                            {items.img && (
                                                <div className="capital-modal-content-middle">
                                                    <img src={items.img} alt="" />
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    items.img === null && items.end_time !== '17:30'
                                                        ? 'capital-modal-content-right bg-gray'
                                                        : 'capital-modal-content-right'
                                                }
                                            >
                                                <div>{items.title}</div>
                                                {items.desc && (
                                                    <div>
                                                        {items.desc.map((item) => {
                                                            return (
                                                                <span className="span-content">
                                                                    <span>{item.title}</span>
                                                                    <span>{item.linker}</span>
                                                                    <span>{item.content}</span>
                                                                    <span>{item.end}</span>
                                                                </span>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                            {items.link && (
                                                <div
                                                    className="capital-modal-content-absolute"
                                                    onClick={() => goPdfLink(items.link)}
                                                >
                                                    <img alt="" src={AllowClickIcon} />
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                            {appType === 'phone' &&
                                currentInfo &&
                                currentInfo.child &&
                                currentInfo.child.map((items) => {
                                    return (
                                        <li key={items.id} className="capital-modal-content">
                                            {items.phoneImg && (
                                                <img
                                                    className="capital-modal-content-middle"
                                                    src={items.phoneImg}
                                                    alt=""
                                                />
                                            )}
                                            {items.img && (
                                                <div className="capital-modal-content-left">
                                                    <div>{items.start_time} - </div>
                                                    <div>{items.end_time}</div>
                                                </div>
                                            )}
                                            {items.img && (
                                                <div className="capital-modal-content-line"></div>
                                            )}
                                            {items.img === null && items.end_time !== '17:30' ? (
                                                <div className="special-show">
                                                    <div>
                                                        {items.start_time} - {items.end_time}:{' '}
                                                        {items.title}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="capital-modal-content-right">
                                                    <div className="capital-modal-content-right-left">
                                                        <div className="capital-modal-content-right-left-title">
                                                            {items.title}
                                                        </div>
                                                        {items.desc &&
                                                            items.showMore === 'down' && (
                                                                <div className="capital-modal-content-right-left-content">
                                                                    {items.desc &&
                                                                        items.desc.map((item) => {
                                                                            return (
                                                                                <span className="span-content">
                                                                                    <span>
                                                                                        {item.title}
                                                                                    </span>
                                                                                    <span>
                                                                                        {
                                                                                            item.linker
                                                                                        }
                                                                                    </span>
                                                                                    <span>
                                                                                        {
                                                                                            item.content
                                                                                        }
                                                                                    </span>
                                                                                    <span>
                                                                                        {item.end}
                                                                                    </span>
                                                                                </span>
                                                                            );
                                                                        })}
                                                                </div>
                                                            )}

                                                        {items.link &&
                                                            items.showMore === 'down' && (
                                                                <div
                                                                    className="capital-modal-content-view"
                                                                    onClick={() =>
                                                                        goPdfLink(items.link)
                                                                    }
                                                                >
                                                                    <div className="capital-modal-content-view-title">
                                                                        View presentation
                                                                    </div>
                                                                    <div className="capital-modal-content-view-img">
                                                                        <img
                                                                            src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                    {items.showMore === 'up' && (
                                                        <img
                                                            className="capital-modal-content-right-left-img"
                                                            src={upSvg}
                                                            alt=""
                                                            onClick={() =>
                                                                changeDirection(items, 'down')
                                                            }
                                                        />
                                                    )}
                                                    {items.showMore === 'down' && (
                                                        <img
                                                            className="capital-modal-content-right-left-img"
                                                            src={downSvg}
                                                            alt=""
                                                            onClick={() =>
                                                                changeDirection(items, 'up')
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                            <li className="capital-modal-bottom">
                                {arr[currentId - 1] && (
                                    <div
                                        className="capital-modal-btn"
                                        onClick={() => {
                                            setCurrentId(currentId - 1);
                                            setCurrentInfo(arr[currentId - 1]);
                                        }}
                                    >
                                        <img src={leftArrowSvg} alt="" />
                                    </div>
                                )}
                                <div className="capital-modal-bottom-middle">
                                    {!arr[currentId - 1] && <div></div>}
                                    {arr[currentId - 1] && (
                                        <div
                                            style={pointer}
                                            onClick={() => {
                                                setCurrentId(currentId - 1);
                                                setCurrentInfo(arr[currentId - 1]);
                                            }}
                                        >
                                            Wednesday
                                        </div>
                                    )}
                                    {arr[currentId + 1] && (
                                        <div
                                            style={pointer}
                                            onClick={() => {
                                                setCurrentId(currentId + 1);
                                                setCurrentInfo(arr[currentId + 1]);
                                            }}
                                        >
                                            Friday
                                        </div>
                                    )}
                                    {!arr[currentId + 1] && <div></div>}
                                </div>
                                {arr[currentId + 1] && (
                                    <div
                                        className="capital-modal-btn"
                                        onClick={() => {
                                            setCurrentId(currentId + 1);
                                            setCurrentInfo(arr[currentId + 1]);
                                        }}
                                    >
                                        <img src={rightArrowSvg} alt="" />
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            {showPartnerModal && (
                <div className="capital-modal-partner">
                    {appType === 'pc' && (
                        <div className="capital-modal-partner-detail">
                            <img
                                className="capital-modal-partner-close"
                                src={partnerCloseSvg}
                                alt=""
                                onClick={() => {
                                    setShowPartnerModal(false);
                                }}
                            />
                            <div className="capital-modal-partner-table">
                                <div className="capital-modal-partner-img">
                                    <img src={currentPartnerInfo.pc_thumb} alt="" />
                                </div>
                                <div className="capital-modal-partner-content">
                                    <div className="capital-modal-partner-title">
                                        {currentPartnerInfo.title}
                                    </div>
                                    <div className="capital-modal-partner-desc">
                                        {currentPartnerInfo.content.map((item) => {
                                            return (
                                                <p dangerouslySetInnerHTML={{ __html: item }}></p>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="capital-modal-partne-button">
                                <Link
                                    target="_blank"
                                    to={currentPartnerInfo.linkTo}
                                    className="capital-modal-partner-learn"
                                >
                                    <div className="capital-reg-right-img">
                                        <img
                                            src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="capital-reg-right-title">Sign up</div>
                                </Link>
                            </div>
                        </div>
                    )}
                    {appType === 'pad' && (
                        <div className="capital-modal-partner-detail">
                            <img
                                className="capital-modal-partner-close"
                                src={partnerCloseSvg}
                                alt=""
                                onClick={() => {
                                    setShowPartnerModal(false);
                                }}
                            />
                            <img
                                className="capital-modal-partner-img"
                                src={currentPartnerInfo.pad_thumb}
                                alt=""
                            />
                            <div className="capital-modal-partner-content">
                                <div className="capital-modal-partner-title">
                                    {currentPartnerInfo.title}
                                </div>
                                <div className="capital-modal-partner-desc">
                                    {currentPartnerInfo.content.map((item) => {
                                        return <p dangerouslySetInnerHTML={{ __html: item }}></p>;
                                    })}
                                </div>
                            </div>
                            <div className="capital-modal-partne-button">
                                <Link
                                    target="_blank"
                                    to={currentPartnerInfo.linkTo}
                                    className="capital-modal-partner-learn"
                                >
                                    <div className="capital-reg-right-img">
                                        <img
                                            src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="capital-reg-right-title">Sign up</div>
                                </Link>
                            </div>
                        </div>
                    )}
                    {appType === 'phone' && (
                        <div className="capital-modal-partner-detail">
                            <img
                                className="capital-modal-partner-close"
                                src={partnerCloseSvg}
                                alt=""
                                onClick={() => {
                                    setShowPartnerModal(false);
                                }}
                            />
                            <img
                                className="capital-modal-partner-img"
                                src={currentPartnerInfo.phone_thumb}
                                alt=""
                            />
                            <div className="capital-modal-partner-content">
                                <div className="capital-modal-partner-title">
                                    {currentPartnerInfo.title}
                                </div>
                                <div className="capital-modal-partner-subtitle">
                                    {currentPartnerInfo.subtitle}
                                </div>
                                <div className="capital-modal-partner-desc">
                                    {currentPartnerInfo.content.map((item) => {
                                        return <p dangerouslySetInnerHTML={{ __html: item }}></p>;
                                    })}
                                </div>
                            </div>
                            <div className="capital-modal-partne-button">
                                <Link
                                    target="_blank"
                                    to={currentPartnerInfo.linkTo}
                                    className="capital-modal-partner-learn"
                                >
                                    <div className="capital-reg-right-img">
                                        <img
                                            src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="capital-reg-right-title">Sign up</div>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Capital;
