// import { s3address } from "@/utils/s3.js";

import yumiLogo from '@/assets/HomePage/MeetsCapitalWrap/yumiLogo.svg';
import bityLogo from '@/assets/HomePage/MeetsCapitalWrap/bityLogo.svg';
import origynLogo from '@/assets/HomePage/MeetsCapitalWrap/origynLogo.svg';
import gldtLogo from '@/assets/HomePage/MeetsCapitalWrap/gldtLogo.svg';
import shikuLogo from '@/assets/HomePage/MeetsCapitalWrap/shikuLogo.svg';
import helixLogo from '@/assets/HomePage/MeetsCapitalWrap/helixLogo.svg';
import metaversityLogo from '@/assets/HomePage/MeetsCapitalWrap/metaversityLogo.svg';
import dsocialLogo from '@/assets/HomePage/MeetsCapitalWrap/dsocialLogo.svg';

export const meetsCapitalWrapPlatformList = [
    {
        logoImg: yumiLogo,
        titletext: 'Yumi NFT marketplace',
        btnList: [
            {
                value: 'yumi.io',
                url: 'https://tppkg-ziaaa-aaaal-qatrq-cai.raw.ic0.app/',
            },
            {
                value: 'linktree',
                url: 'https://linktr.ee/yuminft',
            },
            {
                value: 'deck',
                url: 'https://swissblockchainlabs.org/yumideck.pdf',
            },
        ],
        bindPlatformList: [
            {
                url: 'https://discord.com/invite/x239m2dx6j',
            },
            {
                url: 'https://twitter.com/YumiMarketplace',
            },
        ],
        descText:
            'Yumi is an innovative, next-generation NFT platform and exchange that combines full decentralization with regulatory compliance. It offers NFTs for real-world assets, serves as an NFTs-as-a-service provider for enterprises, and provides an immersive metaverse experience.',
        hintTextList: [
            {
                value: 'Fractionalized fine art',
                url: 'https://tppkg-ziaaa-aaaal-qatrq-cai.raw.ic0.app/origyn/art/2oqzn-paaaa-aaaaj-azrla-cai',
            },
            {
                value: 'Gold backed NFT',
                url: 'https://tppkg-ziaaa-aaaal-qatrq-cai.raw.ic0.app/gold/nfts',
            },
            {
                value: 'NFT as a service for top companies',
                url: 'https://nft.sodexobenefits.sodexonet.com/home',
            },
            {
                value: 'Fiat integration',
                url: 'https://tppkg-ziaaa-aaaal-qatrq-cai.raw.ic0.app/',
            },
        ],
    },
    {
        logoImg: bityLogo,
        titletext: 'Bity',
        btnList: [
            {
                value: 'bity.com',
                url: 'https://bity.com/',
            },
        ],
        bindPlatformList: [],
        descText:
            'Bity is a Swiss brokerage for buying, selling, trading, and using cryptocurrency for online purchases.Buty is building a universal cryptocurrency gateway that provides secure and reliable products and services.',
        hintTextList: [],
    },
    {
        logoImg: origynLogo,
        titletext: 'ORIGYN',
        btnList: [
            {
                value: 'origyn.com',
                url: 'https://www.origyn.com/',
            },
            {
                value: 'OGY dashboard',
                url: 'https://vekja-naaaa-aaaao-aaacq-cai.ic0.app/home',
            },
        ],
        bindPlatformList: [],
        descText:
            'ORIGYN creates powerful biometric digital certificates that prove authenticity, identity and ownership of valuable objects and secure their data fully on the blockchain, forever. ORIGYN’s digital certification technology establishes an irrefutable connection between a physical asset and a forgery-proof, customizable digital certificate.',
        hintTextList: [],
    },
    {
        logoImg: gldtLogo,
        titletext: 'GLDT',
        btnList: [],
        bindPlatformList: [],
        descText:
            'GLDT is a token that is 100% backed by physical gold, making it a secure and reliable investment option in the world of cryptocurrency. GLDT is only minted when a GLD NFT is swapped, ensuring that it is fully backed by physical gold held in secure vaults in Switzerland.',
        hintTextList: [],
    },
    {
        logoImg: shikuLogo,
        titletext: 'shiku.com',
        btnList: [
            {
                value: 'shiku.com',
                url: 'https://shiku.com/',
            },
            {
                value: 'demo video',
                url: 'https://www.youtube.com/watch?v=rZZ5bQ1EFXc',
            },
            // {
            //   value: "deck",
            //   url: "https://buckets.bochslerfinance.com/yumi-public/ShikuDeck.pdf", //"https://swissblockchainlabs.org/ShikuDeck.pdf",
            // },
        ],
        bindPlatformList: [
            {
                url: 'https://discord.com/invite/PU5jSkbzSG',
            },
            {
                url: 'https://twitter.com/ShikuMetaverse?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
            },
        ],
        descText:
            "Shiku Foundation is the first and only fully regulated Swiss-based metaverse non-profit foundation. Shiku is the world's first open and decentralized full-feature metaverse platform that provides high-resolution immersive experiences of collaboration, communication and connection.",
        hintTextList: [
            {
                value: 'Leverages the unique technology of the IC',
                url: '',
            },
            {
                value: 'Decentralized, open, scalable, secure',
                url: '',
            },
            {
                value: 'Realistic graphics, low storage costs, no gas-fees',
                url: '',
            },
            {
                value: 'GameFi, DeFi, MetaFi, SocialFi, LearnFi',
                url: '',
            },
        ],
    },
    {
        logoImg: helixLogo,
        titletext: 'Helix',
        btnList: [
            {
                value: 'helixapp.com',
                url: 'https://helixapp.com/',
            },
        ],
        bindPlatformList: [],
        descText:
            'A decentralized crypto exchange that allows for trading unlimited cross-chain crypto assets and perpetual markets, while offering market-leading rebates.',
        hintTextList: [],
    },
    // {
    //     logoImg: metaversityLogo,
    //     titletext: 'Metaversity',
    //     btnList: [
    //         {
    //             value: 'blog post',
    //             url: 'https://blog.shiku.com/metaversity-an-open-innovative-and-sustainable-academy-in-shiku-metaverse/',
    //         },
    //     ],
    //     bindPlatformList: [],
    //     descText:
    //         'Metaversity is an open, innovative, and sustainable academy on Shiku Metaverse that provides scholars with easy access to blockchain data. It is a platform for generating meaningful behavioural data, and a sustainable ecosystem for the future of academia.',
    //     hintTextList: [],
    // },
    {
        logoImg: dsocialLogo,
        titletext: 'DSocial',
        btnList: [
            {
                value: 'website',
                url: 'https://dwqte-viaaa-aaaai-qaufq-cai.ic0.app/',
            },
        ],
        bindPlatformList: [],
        descText: 'Decentralized video sharing platform.',
        hintTextList: [
            {
                value: 'Decentralized video sharing platform built on blockchain',
                url: '',
            },
            {
                value: 'Support long & short video',
                url: '',
            },
            {
                value: 'Own 100% of your data & community',
                url: '',
            },
        ],
    },
];
