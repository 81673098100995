import React from 'react';
import './index.scss';
import { cn } from '@/common/cn';
const PlatformLoadingDom = ({ className }) => {
    return (
        <div className={cn('platformLoadingDomWrapper', className)}>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
        </div>
    );
};

export default PlatformLoadingDom;
