import { useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatchStore } from './store';

function App() {
    const dispatch = useDispatchStore();

    // app width
    const onAppResize = useCallback(() => {
        let _w = document.getElementsByTagName('body')[0].offsetWidth;
        let _type = '';
        if (_w >= 1440 && _w <= 1640) {
            _type = 'pc';
        } else if (_w >= 1024 && _w <= 1439) {
            _type = 'pad';
        } else if (_w <= 1023) {
            _type = 'phone';
        } else {
            _type = 'pc';
        }

        let u = navigator.userAgent;
        let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
        if (isiOS) {
            // setUserAgent("IOS");
            dispatch({
                type: 'setAppWidth',
                appWidth: _w,
                appType: _type,
                userAgent: 'IOS',
                appSubType: _w >= 1640 ? true : false,
            });
            if (/Version/.test(u) && !/EdgiOS/.test(u)) {
                // setUserAgent("IOS Safari");
                dispatch({
                    type: 'setAppWidth',
                    appWidth: _w,
                    appType: _type,
                    userAgent: 'IOS Safari',
                    appSubType: _w >= 1640 ? true : false,
                });
            }
        }
        if (isAndroid) {
            // setUserAgent("Android");
            dispatch({
                type: 'setAppWidth',
                appWidth: _w,
                appType: _type,
                userAgent: 'Android',
                appSubType: _w >= 1640 ? true : false,
            });
        }

        dispatch({
            type: 'setAppWidth',
            appWidth: _w,
            appType: _type,
            appSubType: _w >= 1640 ? true : false,
        });

        window.addEventListener(
            'resize',
            () => {
                _w = document.getElementsByTagName('body')[0].offsetWidth;
                if (_w >= 1440 && _w <= 1640) {
                    _type = 'pc';
                } else if (_w >= 1024 && _w <= 1439) {
                    _type = 'pad';
                } else if (_w <= 1023) {
                    _type = 'phone';
                } else {
                    _type = 'pc';
                }

                dispatch({
                    type: 'setAppWidth',
                    appWidth: _w,
                    appType: _type,
                    appSubType: _w >= 1640 ? true : false,
                });
            },
            false,
        );
    }, [dispatch]);

    useEffect(() => {
        onAppResize();
    }, [onAppResize]);

    return (
        <div id="App" className="App">
            <section className="body">
                <Outlet />
            </section>
        </div>
    );
}

export default App;
