import { useState } from 'react';
import { useStateStore } from '@/store';
import { s3address } from '@/utils/s3.js';
import PlatformLoadingDom from './components/PlatformLoadingDom';
import PlatformInfoModal from './components/PlatformInfoModal';
import './index.scss';
import { cn } from '@/common/cn.js';
import yumiLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/yumiLogoIcon.svg';
import bityLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/bityLogoIcon.svg';
import origynLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/origynLogoIcon.svg';
import shikuLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/shikuLogoIcon.svg';
import helixLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/helixLogoIcon.svg';
import archeryLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/archeryLogoIcon.svg';
import icpGlobalLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/icpGlobalLogoIcon.svg';
import venturesLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/venturesLogoIcon.svg';
import bochslerLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/bochslerLogoIcon.svg';
import eterbaLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/eterbaLogoIcon.svg';
import edgeLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/edge.svg';
import dracoonLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/dracoon.svg';
import kebnelIcon from '@/assets/HomePage/MeetsCapitalWrap/kebnel.svg';
import oneYumiIcon from '@/assets/HomePage/MeetsCapitalWrap/oneYumiIcon.svg';
import arrow from '@/assets/HomePage/MeetsCapitalWrap/arrow.svg';
import gldtLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/gldtLogoIcon.png';
import metaversityLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/metaversityLogoIcon.png';
import dsocialLogoIcon from '@/assets/HomePage/MeetsCapitalWrap/dsocialLogoIcon.png';
import logo_EternaCapital from '@/assets/HomePage/MeetsCapitalWrap/logo_EternaCapital.png';
import { isMobile } from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';
const MeetsCapitalWrap = () => {
    const state = useStateStore();
    const { appType } = state.default;

    const [detailPageFlagPlatformInfoModal, setDetailPageFlagPlatformInfoModal] = useState(false);
    const [platformInfoModalStyle, setPlatformInfoModalStyle] = useState({});
    const [disabledLoadingFlagPlatformInfoModal, setDisabledLoadingFlagPlatformInfoModal] =
        useState(false);
    const [platformRecordIndex, setPlatformRecordIndex] = useState(0);
    const [showMoreCapital, setShowMoreCapital] = useState(false);
    // mobile capital slider
    const [swiper, setSwiper] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const onSlideChange = (e) => setCurrentPage(e.realIndex);

    const handlePlatformInfoModalStyleToLeft = () => {
        if (!disabledLoadingFlagPlatformInfoModal) {
            setDisabledLoadingFlagPlatformInfoModal(true);
            setDetailPageFlagPlatformInfoModal(true);
            setPlatformInfoModalStyle({
                animation: 'platformInfoModalWrapRightToLeft 0.3s ease-in-out forwards',
            });
            setTimeout(() => {
                setDisabledLoadingFlagPlatformInfoModal(false);
            }, 1000);
        }
    };

    const handlePlatformInfoModalStyleToRight = () => {
        if (!disabledLoadingFlagPlatformInfoModal) {
            setDisabledLoadingFlagPlatformInfoModal(true);
            setPlatformInfoModalStyle({
                animation: 'platformInfoModalWrapLeftToRight 0.3s ease-in-out forwards',
            });
            setTimeout(() => {
                setDetailPageFlagPlatformInfoModal(false);
                setDisabledLoadingFlagPlatformInfoModal(false);
            }, 1000);
        }
    };

    const handleWindowScroll = () => {
        const blockchainWrapOffsetHeight = document.querySelector('.blockchainWrap').offsetHeight;
        // const webSpaceWrapOffsetHeight = document.querySelector(".webSpaceWrap").offsetHeight;
        const meetsCapitalWrapOffsetHeight =
            document.querySelector('.meetsCapitalWrap').offsetHeight;
        // const partnersWrapOffsetHeight = document.querySelector(".partnersWrap").offsetHeight;
        const subscribeWrapOffsetHeight = document.querySelector('.subscribeWrap').offsetHeight;
        const webEventsWrapOffsetHeight = document.querySelector('.webEventsWrap').offsetHeight;
        window.scrollTo(
            0,
            blockchainWrapOffsetHeight +
                // webSpaceWrapOffsetHeight +
                meetsCapitalWrapOffsetHeight +
                // partnersWrapOffsetHeight +
                subscribeWrapOffsetHeight +
                webEventsWrapOffsetHeight,
        );
    };

    return (
        <>
            <div className="meetsCapitalWrap">
                <div className="comTitle">
                    <span className="whiteText">Innovation</span>
                    <span className="themeColorText">meets Capital</span>
                </div>
                {isMobile ? (
                    <div className="infoContent">
                        <div className="platformOneTable !grid !grid-cols-2">
                            <div
                                className="tableItem border-b-0 border-l-0 border-r-0"
                                onClick={() => {
                                    setPlatformRecordIndex(0);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <div className="firstIcon">
                                    <img src={oneYumiIcon} alt="" />
                                </div>
                                <img src={yumiLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem border-b-0 border-r-0"
                                onClick={() => {
                                    setPlatformRecordIndex(1);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <img src={bityLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem border-b-0"
                                onClick={() => {
                                    setPlatformRecordIndex(2);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <div className="firstIcon">
                                    <img src={oneYumiIcon} alt="" />
                                </div>
                                <img src={origynLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem border-l-0 border-r-0"
                                onClick={() => {
                                    setPlatformRecordIndex(3);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <img style={{ scale: '50%' }} src={gldtLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem border-r-0"
                                onClick={() => {
                                    setPlatformRecordIndex(4);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <img src={shikuLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem"
                                onClick={() => {
                                    setPlatformRecordIndex(5);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <img src={helixLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem border-l-0 border-r-0 border-t-0"
                                onClick={() => {
                                    setPlatformRecordIndex(6);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <img style={{ scale: '50%' }} src={metaversityLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem border-r-0 border-t-0"
                                onClick={() => {
                                    setPlatformRecordIndex(7);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <img style={{ scale: '50%' }} src={dsocialLogoIcon} alt="" />
                            </div>
                        </div>
                        <div className="centerCom">
                            <div className="infoText">
                                Swiss
                                {appType === 'phone' ? <br /> : <>&nbsp;</>}
                                Blockchain Labs
                            </div>
                        </div>
                        <div className="platformTwoTable !grid !grid-cols-1 border border-[#808080]">
                            <Swiper
                                className="!w-full cursor-pointer"
                                direction="horizontal"
                                loop={false}
                                onSwiper={(swiper) => setSwiper(swiper)}
                                onSlideChange={onSlideChange}
                            >
                                <SwiperSlide className="flex !w-full flex-col">
                                    <div className="tableItem flex w-full  border-none">
                                        <img
                                            src={icpGlobalLogoIcon}
                                            className="mx-auto h-24"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="tableItem flex w-full  border-none"
                                        onClick={() => {
                                            window.open('https://www.bochslerfinance.com/');
                                        }}
                                    >
                                        <img src={bochslerLogoIcon} className="h-14" alt="" />
                                    </div>
                                    <div
                                        className="tableItem flex w-full  border-none"
                                        onClick={() => {
                                            window.open('https://anmicapital.com/');
                                        }}
                                    >
                                        <img className="h-10" src={logo_EternaCapital} alt="" />
                                    </div>
                                    <div className="tableItem " onClick={() => {}}>
                                        <img src={'/img/home/a100x.png'} className="h-12" alt="" />
                                    </div>
                                    <div
                                        className="tableItem border-t-0"
                                        onClick={() => {
                                            window.open('https://www.eternacapital.com/');
                                        }}
                                    >
                                        <img src={eterbaLogoIcon} className="h-8" alt="" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="flex !w-full flex-col">
                                    {' '}
                                    <div
                                        className="tableItem border-r-0 border-t-0"
                                        onClick={() => {
                                            window.open('http://www.kernel-ventures.com/');
                                        }}
                                    >
                                        <img src={kebnelIcon} className="h-8" alt="" />
                                    </div>
                                    <div
                                        className="tableItem border-r-0 border-t-0"
                                        onClick={() => {}}
                                    >
                                        <img
                                            src={'/img/home/op-crypto.png'}
                                            className="h-15"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="tableItem border-t-0"
                                        onClick={() => {
                                            window.open('https://edge-ventures.io/');
                                        }}
                                    >
                                        <img src={edgeLogoIcon} className="h-14" alt="" />
                                    </div>
                                    <div
                                        className="tableItem w-full"
                                        onClick={() => {
                                            window.open('https://www.dracoon.ventures/');
                                        }}
                                    >
                                        <img
                                            src={'/img/home/dracoon.svg'}
                                            className="h-13"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="tableItem w-full !border-none"
                                        onClick={() => {
                                            window.open('https://www.yapcapital.ventures/');
                                        }}
                                    >
                                        <img
                                            src={'/img/home/yap-capital.svg'}
                                            className="h-[100px]"
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="flex !w-full flex-col">
                                    {' '}
                                    <div
                                        className="tableItem w-full"
                                        onClick={() => {
                                            window.open('https://helveteq.com/');
                                        }}
                                    >
                                        <img src={'/img/home/helv.svg'} className="h-7" alt="" />
                                    </div>
                                    <div
                                        className="tableItem w-full"
                                        onClick={() => {
                                            window.open('https://helveteq.com/');
                                        }}
                                    >
                                        <img src={'/img/home/chorus.svg'} className="h-10" alt="" />
                                    </div>
                                    <div
                                        className="tableItem w-full"
                                        onClick={() => {
                                            window.open('https://ambergroup.io/');
                                        }}
                                    >
                                        <img src={'/img/home/amber.svg'} className="h-6" alt="" />
                                    </div>
                                    <div className="tableItem w-full !border-none"></div>
                                    <div className="tableItem w-full !border-none"></div>
                                </SwiperSlide>
                            </Swiper>
                            <div className="flex h-12 justify-between bg-[#bfff00] px-4">
                                <div
                                    className="flex w-1/2"
                                    onClick={() => {
                                        swiper?.slideTo((currentPage - 1) % 3);
                                    }}
                                >
                                    <img
                                        src="/img/home/arrow.svg"
                                        className=" my-auto w-6  rotate-180"
                                        alt=""
                                    ></img>
                                </div>
                                <div className="h-full w-px bg-[#808080]"></div>
                                <div
                                    className="flex w-1/2"
                                    onClick={() => {
                                        swiper?.slideTo(currentPage + 1);
                                    }}
                                >
                                    {' '}
                                    <img
                                        src="/img/home/arrow.svg"
                                        className="my-auto ml-auto w-6"
                                        alt=""
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="infoContent">
                        <div className="platformOneTable">
                            <div
                                className="tableItem border-b-0 border-l-0 border-r-0"
                                onClick={() => {
                                    setPlatformRecordIndex(0);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <div className="firstIcon">
                                    <img src={oneYumiIcon} alt="" />
                                </div>
                                <img src={yumiLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem border-b-0 border-r-0"
                                onClick={() => {
                                    setPlatformRecordIndex(1);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <img src={bityLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem border-b-0"
                                onClick={() => {
                                    setPlatformRecordIndex(2);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <div className="firstIcon">
                                    <img src={oneYumiIcon} alt="" />
                                </div>
                                <img src={origynLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem border-l-0 border-r-0"
                                onClick={() => {
                                    setPlatformRecordIndex(3);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <img style={{ scale: '50%' }} src={gldtLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem border-r-0"
                                onClick={() => {
                                    setPlatformRecordIndex(4);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <img src={shikuLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem"
                                onClick={() => {
                                    setPlatformRecordIndex(5);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <img src={helixLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem border-l-0 border-r-0 border-t-0"
                                onClick={() => {
                                    setPlatformRecordIndex(6);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <img style={{ scale: '50%' }} src={metaversityLogoIcon} alt="" />
                            </div>
                            <div
                                className="tableItem border-r-0 border-t-0"
                                onClick={() => {
                                    setPlatformRecordIndex(7);
                                    handlePlatformInfoModalStyleToLeft();
                                }}
                            >
                                <img style={{ scale: '50%' }} src={dsocialLogoIcon} alt="" />
                            </div>
                            {
                                <div className="tableItemNone border-t-0">
                                    <PlatformLoadingDom />
                                </div>
                            }
                        </div>
                        <div className="centerCom">
                            <div className="infoText">
                                Swiss
                                {appType === 'phone' ? <br /> : <>&nbsp;</>}
                                Blockchain Labs
                            </div>
                        </div>
                        <div className="platformTwoTable lg:grid-row-3 grid !grid-rows-3 gap-px border border-[#808080] lg:grid-cols-3">
                            {!showMoreCapital ? (
                                <>
                                    <div
                                        className="tableItem w-full border-none"
                                        // onClick={() => {
                                        //   window.open("https://icp.global/home/");
                                        // }}
                                    >
                                        <img src={icpGlobalLogoIcon} className="h-24" alt="" />
                                    </div>
                                    <div
                                        className="tableItem w-full border-none"
                                        onClick={() => {
                                            window.open('https://www.bochslerfinance.com/');
                                        }}
                                    >
                                        <img src={bochslerLogoIcon} className="h-14" alt="" />
                                    </div>
                                    <div
                                        className="tableItem border-t-0 border-none"
                                        onClick={() => {
                                            window.open('https://anmicapital.com/');
                                        }}
                                    >
                                        <img className="h-10" src={logo_EternaCapital} alt="" />
                                    </div>
                                    <div
                                        className="tableItem border-t-0 border-none"
                                        onClick={() => {}}
                                    >
                                        <img src={'/img/home/a100x.png'} className="h-12" alt="" />
                                    </div>
                                    <div
                                        className="tableItem border-t-0"
                                        onClick={() => {
                                            window.open('https://www.eternacapital.com/');
                                        }}
                                    >
                                        <img src={eterbaLogoIcon} className="h-8" alt="" />
                                    </div>
                                    <div
                                        className="tableItem border-t-0 border-none"
                                        onClick={() => {
                                            window.open('http://www.kernel-ventures.com/');
                                        }}
                                    >
                                        <img src={kebnelIcon} className="h-8" alt="" />
                                    </div>
                                    <div
                                        className="tableItem border-t-0 border-none"
                                        onClick={() => {}}
                                    >
                                        <img
                                            src={'/img/home/op-crypto.png'}
                                            className="h-15"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="tableItem border-t-0"
                                        onClick={() => {
                                            window.open('https://edge-ventures.io/');
                                        }}
                                    >
                                        <img src={edgeLogoIcon} className="h-14" alt="" />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        className="tableItem w-full !border-none"
                                        onClick={() => {
                                            window.open('https://www.dracoon.ventures/');
                                        }}
                                    >
                                        <img
                                            src={'/img/home/dracoon.svg'}
                                            className="h-13"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="tableItem w-full !border-none"
                                        onClick={() => {
                                            window.open('https://www.yapcapital.ventures/');
                                        }}
                                    >
                                        <img
                                            src={'/img/home/yap-capital.svg'}
                                            className="h-[100px]"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="tableItem w-full !border-none"
                                        onClick={() => {
                                            window.open('https://helveteq.com/');
                                        }}
                                    >
                                        <img src={'/img/home/helv.svg'} className="h-7" alt="" />
                                    </div>
                                    <div
                                        className="tableItem w-full !border-none"
                                        onClick={() => {
                                            window.open('https://helveteq.com/');
                                        }}
                                    >
                                        <img src={'/img/home/chorus.svg'} className="h-10" alt="" />
                                    </div>
                                    <div
                                        className="tableItem w-full !border-none"
                                        onClick={() => {
                                            window.open('https://ambergroup.io/');
                                        }}
                                    >
                                        <img src={'/img/home/amber.svg'} className="h-6" alt="" />
                                    </div>
                                    <div className="tableItem w-full !border-none"></div>
                                    <div className="tableItem w-full !border-none"></div>
                                    <div className="tableItem w-full !border-none"></div>
                                </>
                            )}
                            <div
                                className="tableItem group relative border-r-0 border-t-0 text-center font-['Inter'] text-lg font-normal uppercase leading-[35px] tracking-[5.40px] text-black opacity-95"
                                onClick={() => setShowMoreCapital((p) => !p)}
                            >
                                {showMoreCapital ? 'Back' : 'More'}
                                <div
                                    className={cn(
                                        'absolute bottom-0 right-0 flex h-11 w-[46px]  bg-[#BFFF00]',
                                        showMoreCapital && 'right-full translate-x-full',
                                    )}
                                >
                                    <img
                                        src="/img/home/arrow.svg"
                                        className={cn(
                                            'z-10 m-auto',
                                            showMoreCapital && 'rotate-180',
                                        )}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="w-full max-w-[1312px] px-4 lg:px-[34px] xl:px-0">
                    <div
                        className={cn(
                            'grid w-full max-w-[1312px] gap-px bg-[#808080]   lg:grid-cols-2',
                            isMobile && 'grid-rows-2 border-0',
                        )}
                    >
                        <div className="flex flex-col">
                            <div className="h-[68px] bg-[#C9C7C7] text-center font-['Inter'] text-[34px] font-bold leading-[68px] text-neutral-900">
                                Exchanges
                            </div>
                            <div
                                className={cn(
                                    'grid h-52 w-full grid-cols-2 gap-px border border-[#808080] bg-[#808080] lg:grid-cols-3 lg:border-[#808080] lg:bg-[#808080]',
                                    'lg:border-r-0',
                                )}
                            >
                                <div className="flex bg-[#131313]">
                                    <img src="/img/home/bitget.svg" className="m-auto" alt="" />
                                </div>
                                <div className="flex bg-[#131313]">
                                    <img
                                        src="/img/home/ic-light-house.svg"
                                        className="m-auto"
                                        alt=""
                                    />
                                </div>
                                <div className="col-span-2 flex bg-[#131313] lg:col-span-1">
                                    <PlatformLoadingDom className="m-auto" />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="h-[68px] bg-[#C9C7C7] text-center font-['Inter'] text-[34px] font-bold leading-[68px] text-neutral-900">
                                Media
                            </div>
                            <div
                                className={cn(
                                    'grid h-52 w-full grid-cols-2 gap-px border border-[#808080] bg-[#808080] lg:grid-cols-3 lg:border-[#808080] lg:bg-[#808080]',
                                    'lg:border-l-0',
                                )}
                            >
                                <div className="flex bg-[#131313]">
                                    <img
                                        src="/img/home/coin-telegraph.svg"
                                        className="m-auto"
                                        alt=""
                                    />
                                </div>
                                <div className="flex bg-[#131313]">
                                    <img src="/img/home/coinhustle.png" className="m-auto" alt="" />
                                </div>
                                <div className="col-span-2 flex bg-[#131313] lg:col-span-1">
                                    <PlatformLoadingDom className="m-auto " />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {detailPageFlagPlatformInfoModal ? (
                <PlatformInfoModal
                    platformRecordIndex={platformRecordIndex}
                    platformInfoModalStyle={platformInfoModalStyle}
                    handlePlatformInfoModalStyle={handlePlatformInfoModalStyleToRight}
                />
            ) : null}
        </>
    );
};

export default MeetsCapitalWrap;
