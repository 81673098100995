import { useEffect, useState } from 'react';
import { useStateStore } from '@/store';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination } from "swiper";
import 'swiper/swiper-bundle.css';
import './index.scss';
import leftArrowSvg from '@/svg/left-arrow.svg';
import rightArrowSvg from '@/svg/right-arrow.svg';
import { Link } from 'react-router-dom';

const PartnersWrap = (props) => {
    // 在组件中使用
    SwiperCore.use([Pagination, Navigation]);
    const state = useStateStore();
    const [swiperList, setSwiperList] = useState([]);
    const { appType } = state.default;
    const arr = props.list;

    const table_logo_computed = (start, end) => {
        let newLogo = [];
        if (end > 0) {
            newLogo = arr.slice(start, end);
        } else {
            newLogo = arr.slice(start);
        }
        return newLogo;
    };

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appType]);

    const init = () => {
        let number = 0;
        // let startIndex = 0;
        let endIndex = 0;
        let newArr = [];
        if (appType === 'pad') {
            number = Math.ceil(arr.length / 9);
            endIndex = 9;
        } else if (appType === 'phone') {
            number = Math.ceil(arr.length / 8);
            endIndex = 8;
        }
        for (let i = 1; i <= number; i++) {
            newArr.push({
                id: i,
                list: table_logo_computed(endIndex * (i - 1), endIndex * i),
            });
        }
        setSwiperList(newArr);
    };
    return (
        <div className="partners-wrap">
            <div className="partners-vertical"></div>
            <h1 className="partners-wrap-title">{props.title}</h1>
            {/* pc */}
            {appType === 'pc' && (
                <div className="partners-pc-wrap">
                    {arr.map((item, index) => {
                        return (
                            <div className="partners-pc-box">
                                {item.linkTo && (
                                    <Link target="_blank" to={item.linkTo} key={index}>
                                        <img src={item.img} alt="" />
                                    </Link>
                                )}
                                {!item.linkTo && (
                                    <div key={index}>
                                        <img src={item.img} alt="" />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
            {/* table */}
            {appType === 'pad' && (
                <>
                    <div className="partners-table-wrap">
                        <div className="arrow-common swiper-button-next">
                            <img
                                // src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/shiku/1692326848259_arrow (1).svg"
                                src={rightArrowSvg}
                                alt=""
                            />
                        </div>
                        <Swiper
                            className="par-swiper"
                            pagination={{ clickable: true }}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                                disabledClass: 'disable', // 当导航按钮变为不可用时添加的class,也就是当swiper索引为0时上一张没有prevEl的class类名就会添加一个disable，也就是.swiper-button-prev .disable
                            }}
                        >
                            {swiperList.map((m) => {
                                return (
                                    <SwiperSlide key={m.id} className="partners-pc-wrap">
                                        {m.list.map((item, index) => {
                                            return (
                                                <div className="partners-pc-box">
                                                    {item.linkTo && (
                                                        <Link
                                                            target="_blank"
                                                            to={item.linkTo}
                                                            key={index}
                                                        >
                                                            <img src={item.img} alt="" />
                                                        </Link>
                                                    )}
                                                    {!item.linkTo && (
                                                        <div key={index}>
                                                            <img src={item.img} alt="" />
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        <div className="arrow-common swiper-button-prev">
                            <img
                                //src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/shiku/1692326848264_arrow.svg"
                                src={leftArrowSvg}
                                alt=""
                            />
                        </div>
                    </div>
                </>
            )}
            {/* phone */}
            {appType === 'phone' && (
                <div className="partners-table-wrap">
                    <Swiper className="par-swiper" pagination={{ clickable: true }}>
                        {swiperList.map((m) => {
                            return (
                                <SwiperSlide key={m.id} className="partners-pc-wrap">
                                    {m.list.map((item, index) => {
                                        return (
                                            <div className="partners-pc-box">
                                                {item.linkTo && (
                                                    <Link
                                                        target="_blank"
                                                        to={item.linkTo}
                                                        key={index}
                                                    >
                                                        <img src={item.img} alt="" />
                                                    </Link>
                                                )}
                                                {!item.linkTo && (
                                                    <div key={index}>
                                                        <img src={item.img} alt="" />
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            )}
        </div>
    );
};

export default PartnersWrap;
