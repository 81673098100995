import { useState } from 'react';
import { message } from 'antd';
import MailerLite from '@mailerlite/mailerlite-nodejs';
import { token } from './apiToken';
import arrowBtnIcon from './imgs/arrow.svg';
import './index.scss';
import { cn } from '@/common/cn.js';
const SubscribeWrap = ({ hasLabel = true, wrapperClassName }) => {
    const [emailFlagPlaceholder, setEmailFlagPlaceholder] = useState(true);
    const [email, setEmail] = useState('');

    // const fetch_groups = () => {
    //   const mailerlite = new MailerLite({
    //     api_key: token,
    //   });
    //   const params = {
    //     limit: 25,
    //     page: 1,
    //     sort: "-name",
    //   };

    //   mailerlite.groups
    //     .get(params)
    //     .then((response) => {
    //       console.log(response.data);
    //     })
    //     .catch((error) => {
    //       if (error.response) console.log(error.response.data);
    //     });
    // };

    const sendEmail = () => {
        const mailerlite = new MailerLite({
            api_key: token,
        });
        mailerlite.subscribers
            .createOrUpdate({
                email: email,
                groups: ['92317295796290723'],
            })
            .then((res) => {
                message.success('Successful!');
            })
            .catch((error) => {
                message.error('Please try again.');
            });
    };

    return (
        <div className={cn('subscribeWrap', wrapperClassName)}>
            {hasLabel && <div className="labelText">Subscribe to our Newsletter:</div>}
            <div className="inputWrap">
                <div className="input-container">
                    <input
                        placeholder={emailFlagPlaceholder ? 'Email address' : ''}
                        className="input-field"
                        type="text"
                        onInput={(e) => {
                            setEmail(e.target.value);
                        }}
                        onFocus={() => {
                            setEmailFlagPlaceholder(false);
                        }}
                        onBlur={() => {
                            setEmailFlagPlaceholder(true);
                        }}
                    />
                    <label className="input-label">Email address</label>
                    <span className="input-highlight"></span>
                    <span className="input-highNone"></span>
                </div>
                <div
                    className="arrowBtn"
                    onClick={() => {
                        sendEmail();
                    }}
                >
                    <img src={arrowBtnIcon} alt="" />
                </div>
            </div>
        </div>
    );
};

export default SubscribeWrap;
