import React, { useReducer, useContext } from 'react';
import { createContext } from 'react-activation';

import { combineReducers, state, reducer } from './default';

const initialState = {
    // 默认主state
    default: state,
};

// 全部reducer
const rootReducer = combineReducers({
    default: reducer,
});

const StateContext = createContext();
const DispatchContent = createContext();

// state hook
function useStateStore() {
    return useContext(StateContext);
}

// dispatch hook
function useDispatchStore() {
    return useContext(DispatchContent);
}

function StoreProvider({ children }) {
    const [state, dispatch] = useReducer(rootReducer, initialState);

    return (
        <StateContext.Provider value={state}>
            <DispatchContent.Provider value={dispatch}>{children}</DispatchContent.Provider>
        </StateContext.Provider>
    );
}

export { useStateStore, useDispatchStore, StoreProvider };
