// import { s3address } from "@/utils/s3.js";
import FlickerAnimationCom from '../FlickerAnimationCom/index';
import { useStateStore } from '@/store';
import { Link } from 'react-router-dom';
import './index.scss';
import logo from '@/assets/HomePage/BlockchainWrap/logo.svg';
import bgImg from '@/assets/HomePage/BlockchainWrap/headerBg.png';
import twitter from '@/svg/twitter.svg';
// const arrowBottomIcon = `${s3address}/HomePage/BlockchainWrap/arrowBottom.svg`;
const BlockchainWrap = () => {
    const state = useStateStore();
    const { appType } = state.default;

    // const logo = `${s3address}/HomePage/BlockchainWrap/logo.svg`;
    // const bgImg = `${s3address}/HomePage/BlockchainWrap/headerBg.png`;
    // const handleWindowScroll = () => {
    //   const blockchainWrapOffsetHeight = document.querySelector(".blockchainWrap").offsetHeight;
    //   window.scrollTo(0, blockchainWrapOffsetHeight);
    // };

    const getFlickerAnimationComStyle = () => {
        if (appType === 'pc' || appType === 'pad') {
            return [
                {
                    position: 'absolute',
                    top: '20px',
                    left: '20px',
                },
                {
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                },
            ];
        } else if (appType === 'phone') {
            return [
                {
                    position: 'absolute',
                    top: '0',
                    left: '-158px',
                },
                {
                    position: 'absolute',
                    bottom: '0',
                    right: '-158px',
                },
            ];
        } else {
            return [];
        }
    };

    return (
        <div className="blockchainWrap" style={{ backgroundImage: `url('${bgImg}')` }}>
            <FlickerAnimationCom flickerAnimationComStyle={getFlickerAnimationComStyle()[0]} />
            <FlickerAnimationCom flickerAnimationComStyle={getFlickerAnimationComStyle()[1]} />
            <div className="headerLogoContent">
                <div className="logoContent">
                    <img src={logo} alt="" />
                </div>
            </div>
            <div className="textContentInfo">
                <div className="whiteText">Swiss</div>
                <div className="themeColorText">Blockchain</div>
                <div className="whiteText">Labs</div>
                <p>Where Blockchain Innovation Meets Capital with a Web3 Vision</p>
                <div className="lineContent"></div>
                <Link to="/event" className="scrollText top" onClick={() => window.scrollTo(0, 0)}>
                    <div className="scrollText-left">Exclusive event</div>
                    <div className="scrollText-middle">Swiss Blockchain Capital Day</div>
                    <div className="scrollText-right">
                        <img
                            className="scrollText-right-pc"
                            src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1692006032932_arrow.svg"
                            alt=""
                        />
                        <img
                            className="scrollText-right-phone"
                            src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691999745118_arrow_alone.svg"
                            alt=""
                        />
                    </div>
                </Link>
                <div
                    className="scrollText"
                    onClick={() => window.open('https://www.sblclubdeal.org/')}
                >
                    <div className="scrollText-left">Investment Opportunity</div>
                    <div className="scrollText-middle">Club Deal</div>
                    <div className="scrollText-right">
                        <img
                            className="scrollText-right-pc"
                            src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1692006032932_arrow.svg"
                            alt=""
                        />
                        <img
                            className="scrollText-right-phone"
                            src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691999745118_arrow_alone.svg"
                            alt=""
                        />
                    </div>
                </div>

                {/* <div
          className="scrollText"
          onClick={() => {
            handleWindowScroll();
          }}
        >
          <div className="arrowBottomContent">
            <img src={arrowBottomIcon} alt="" />
          </div>
          <span>Scroll to discover more</span>
        </div> */}
            </div>
            <a
                className="blockchainWrap-twitter"
                href="https://twitter.com/SBL_Zurich"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={twitter} alt="" />
            </a>
            <div className="videoBg">
                <video
                    src="https://buckets.bochslerfinance.com/sbl-public/sbl_hero.mp4"
                    autoPlay
                    muted
                    loop
                    id="bg"
                    playsInline={true}
                ></video>
                <div className="homeMask"></div>
            </div>
        </div>
    );
};

export default BlockchainWrap;
