import { useState } from 'react';
import { useForm } from '@formspree/react';
import { message } from 'antd';
import './index.scss';
const Login = (props) => {
    const { showMessage, title } = props;
    const [nameFlagPlaceholder, setNameFlagPlaceholder] = useState(true);
    const [companyFlagPlaceholder, setCompanyFlagPlaceholder] = useState(true);
    const [emailFlagPlaceholder, setEmailFlagPlaceholder] = useState(true);
    const [nameValue, setNameValue] = useState('');
    const [companyValue, setCompanyValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [messageValue, setMessageValue] = useState('');
    const [messageFlagPlaceholder, setMessageFlagPlaceholder] = useState(true);
    const [errorTips, setErrorTips] = useState(false);
    const [, handleSubmit] = useForm('xknlnwky');
    //  onSubmit={handleSubmit}

    return (
        <form onSubmit={handleSubmit} className="login-from">
            <div className="input-container">
                <input
                    placeholder={nameFlagPlaceholder ? 'Name' : ''}
                    className="input-field"
                    type="text"
                    name="Name"
                    onFocus={() => {
                        setNameFlagPlaceholder(false);
                    }}
                    onBlur={() => {
                        setNameFlagPlaceholder(true);
                    }}
                    onInput={(e) => {
                        setNameValue(e.target.value);
                    }}
                />
                <label className="input-label">Name</label>
                <span className="input-highlight"></span>
                <span className="input-highNone"></span>
            </div>
            <div className="input-container">
                <input
                    placeholder={companyFlagPlaceholder ? 'Company' : ''}
                    className="input-field"
                    type="text"
                    name="Company"
                    onFocus={() => {
                        setCompanyFlagPlaceholder(false);
                    }}
                    onBlur={() => {
                        setCompanyFlagPlaceholder(true);
                    }}
                    onInput={(e) => {
                        setCompanyValue(e.target.value);
                    }}
                />
                <label className="input-label">Company</label>
                <span className="input-highlight"></span>
                <span className="input-highNone"></span>
            </div>
            <div className="input-container">
                <input
                    placeholder={emailFlagPlaceholder ? 'Email' : ''}
                    className="input-field"
                    type="text"
                    name="Email"
                    onFocus={() => {
                        setEmailFlagPlaceholder(false);
                    }}
                    onBlur={() => {
                        setEmailFlagPlaceholder(true);
                    }}
                    onInput={(e) => {
                        const value = e.target.value.trim();
                        if (!value) {
                            setErrorTips(false);
                            return false;
                        }
                        var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                        if (!emailRegex.test(value)) {
                            setEmailValue('');
                            setErrorTips(true);
                            return false;
                        } else {
                            setErrorTips(false);
                        }
                        setEmailValue(value);
                    }}
                />
                <label className="input-label">Email</label>
                <span className={errorTips ? 'input-bade' : 'input-highlight'}></span>
                <span className="input-highNone"></span>
                {errorTips && (
                    <label className="empty-tips">*Please enter a valid email address</label>
                )}
            </div>
            {showMessage && (
                <div className="input-container">
                    <input
                        placeholder={messageFlagPlaceholder ? 'Message' : ''}
                        className="input-field"
                        type="text"
                        name="Message"
                        onFocus={() => {
                            setMessageFlagPlaceholder(false);
                        }}
                        onBlur={() => {
                            setMessageFlagPlaceholder(true);
                        }}
                        onInput={(e) => {
                            setMessageValue(e.target.value);
                        }}
                    />
                    <label className="input-label">Message</label>
                    <span className="input-highlight"></span>
                    <span className="input-highNone"></span>
                </div>
            )}
            <div className="joinContent">
                <button
                    className="joinBtn"
                    type="submit"
                    onClick={() => {
                        if (showMessage && (!emailValue || emailValue === '' || errorTips)) {
                            message.error('Please fill in the correct email address.');
                            return false;
                        }
                        let flag = false;
                        if (
                            nameValue !== '' &&
                            companyValue !== '' &&
                            emailValue !== '' &&
                            errorTips
                        ) {
                            flag = true;
                        } else {
                            flag = false;
                        }
                        if (showMessage) {
                            if (messageValue !== '') {
                                flag = true;
                            }
                        }
                        if (flag) {
                            message.success(
                                'Thank you for your message! We will contact you shortly.',
                            );
                        } else {
                            message.error('Please enter the correct content.');
                        }
                    }}
                >
                    <div className="btnText">
                        <span>{title}</span>
                    </div>
                    <div className="arrowImg">
                        <img
                            src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691652540810_arrow (1).svg"
                            alt=""
                        />
                    </div>
                </button>
            </div>
        </form>
    );
};
export default Login;
