import { useStateStore } from '@/store';
// import { s3address } from "@/utils/s3.js";
import './index.scss';
import arrowIcon from '@/assets/HomePage/OurMembersWrap/arrowIcon.svg';
import newscover_1 from '@/assets/HomePage/OurMembersWrap/newscover-1.jpg';
import newscover_2 from '@/assets/HomePage/OurMembersWrap/newscover-2.jpg';
import newscover_3 from '@/assets/HomePage/OurMembersWrap/newscover-3.jpg';
import newscover_4 from '@/assets/HomePage/OurMembersWrap/newscover-4.jpg';
import newscover_5 from '@/assets/HomePage/OurMembersWrap/newscover-5.jpg';
import newscover_6 from '@/assets/HomePage/OurMembersWrap/newscover-6.jpg';
import { Link } from 'react-router-dom';
const OurMembersWrap = () => {
    const state = useStateStore();
    const { appType } = state.default;

    const getInfoTableContent = () => {
        if (appType === 'pc') {
            return (
                <div className="infoTableContent">
                    <div className="leftCopntent">
                        <div className="itemTableContent">
                            <div className="coverImg">
                                <img src={newscover_1} alt="" />
                            </div>
                            <div className="descContent">
                                <div className="title">ORIGYN X Forbes</div>
                                <div className="desc">
                                    <span
                                        onClick={() => {
                                            window.open(
                                                'https://www.forbes.com/sites/stephaniehirschmiller/2022/05/01/origyn-partners-with-watchbox-sets-standard-in-authentication-of-luxury-timepieces/?sh=50a8488c1e51',
                                            );
                                        }}
                                    >
                                        How Origyn’s Sets Diamond Standard In Authentication For
                                        Luxury Timepieces
                                    </span>
                                    <div className="arrowIcon">
                                        <img src={arrowIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="itemTableContent">
                            <div className="coverImg">
                                <img src={newscover_2} alt="" />
                            </div>
                            <div className="descContent">
                                <div className="title">ORIGYN X Bitcoin Insider</div>
                                <div className="desc">
                                    <span
                                        onClick={() => {
                                            window.open(
                                                'https://www.bitcoininsider.org/article/210343/icp-blockchain-technology-revolutionizes-authentication-and-digital-certification',
                                            );
                                        }}
                                    >
                                        ICP Blockchain revolutionizes authentication & digital
                                        certification of real products
                                    </span>
                                    <div className="arrowIcon">
                                        <img src={arrowIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="itemTableContent">
                            <div className="coverImg">
                                <img src={newscover_3} alt="" />
                            </div>
                            <div className="descContent">
                                <div className="title">ORIGYN X Crypto Potato</div>
                                <div className="desc">
                                    <span
                                        onClick={() => {
                                            window.open(
                                                'https://www.coindesk.com/business/2021/11/23/nft-authenticator-origyn-raises-20m-at-a-300m-valuation/',
                                            );
                                        }}
                                    >
                                        Billionaire Bill Ackman Says Crypto is Here to Stay After
                                        FTX Implosion
                                    </span>
                                    <div className="arrowIcon">
                                        <img src={arrowIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="itemTableContent">
                            <div className="coverImg">
                                <img src={newscover_4} alt="" />
                            </div>
                            <div className="descContent">
                                <div className="title">Shiku x Art Basel</div>
                                <div className="desc">
                                    <span
                                        onClick={() => {
                                            window.open(
                                                'https://blog.shiku.com/unveiling-mcc-world-where-art-and-the-metaverse-converge/',
                                            );
                                        }}
                                    >
                                        Unveiling Conor Mccreedy's World: Shiku at Art Basel
                                    </span>
                                    <div className="arrowIcon">
                                        <img src={arrowIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="rightCopntent">
                        {/* <div className="itemTableContent">
                            <div className="coverImg">
                                <img src={newscover_5} alt="" />
                            </div>
                            <div className="descContent">
                                <div className="title">Shiku X NFT Talinn</div>
                                <div className="desc">
                                    <span
                                        onClick={() => {
                                            window.open(
                                                'https://blog.shiku.com/shiku-metaverse-unveils-the-true-potential-of-the-metaverse-at-nft-tallinn/',
                                            );
                                        }}
                                    >
                                        Shiku Metaverse at NFT Tallinn
                                    </span>
                                    <div className="arrowIcon">
                                        <img src={arrowIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="itemTableContent">
                            <div className="coverImg">
                                <img src={'/img/news/Openchat-x-Modclub.png'} alt="" />
                            </div>
                            <div className="descContent">
                                <div className="title">Openchat x Modclub</div>
                                <div className="desc">
                                    <span
                                        onClick={() => {
                                            window.open(
                                                'https://medium.com/@modclub/openchat-partners-with-modclub-for-decentralized-content-moderation-856548fd6f92',
                                            );
                                        }}
                                    >
                                        OpenChat Partners with Modclub for Decentralized Content
                                        Moderation
                                    </span>
                                    <div className="arrowIcon">
                                        <img src={arrowIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="infoContent">
                            <div className="titleText">Other news</div>
                            <div className="descText">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://decrypt.co/146830/sodexo-drops-nfts-twist-employees-not-public',
                                        );
                                    }}
                                >
                                    Sodexo Drops NFTs With a Twist: They’re for Employees, Not the
                                    Public
                                </span>
                                <img src={arrowIcon} alt="" />
                            </div>
                            <div className="descText">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://decrypt.co/86677/paris-hilton-joins-20m-investment-in-nft-platform-origyn',
                                        );
                                    }}
                                >
                                    A Digital Certificate for Authentic Italian Products
                                </span>
                                <img src={arrowIcon} alt="" />
                            </div>
                            {/* <div className="descText">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://blog.shiku.com/metaversity-an-open-innovative-and-sustainable-academy-in-shiku-metaverse/',
                                        );
                                    }}
                                >
                                    Metaversity: Revolutionazing Academy in Shiku Metaverse
                                </span>
                                <img src={arrowIcon} alt="" />
                            </div> */}
                            <div className="descText">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://yumimarketplace.medium.com/a-julian-opie-will-be-the-first-fractionalized-artwork-on-yumi-nft-marketplace-e09a733f3a45',
                                        );
                                    }}
                                >
                                    Fractionalizing Fine Art: Julian Opie on Yumi
                                </span>
                                <img src={arrowIcon} alt="" />
                            </div>

                            <Link
                                to="/news"
                                onClick={() => window.scrollTo(0, 0)}
                                className="group mt-2 inline-flex h-[54px]  cursor-pointer items-start justify-start"
                            >
                                <div className="flex h-full items-start justify-start gap-5 border border-[#BFFF00] bg-black px-6 font-['Inter-Bold'] text-[13px] font-bold uppercase leading-[54px] tracking-[3.25px] text-[#BFFF00] group-hover:bg-[#373737]">
                                    View more
                                </div>
                                <div className="relative flex h-[54px] w-14  border border-[#BFFF00] bg-[#BFFF00]">
                                    <img src="/img/home/arrow.svg" className="m-auto" alt="" />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            );
        } else if (appType === 'pad') {
            return (
                <div className="infoTableContent">
                    <div className="leftCopntent">
                        <div className="itemTableContent">
                            <div className="coverImg">
                                <img src={newscover_1} alt="" />
                            </div>
                            <div className="descContent">
                                <div className="title">ORIGYN X Forbes</div>
                                <div className="desc">
                                    <span
                                        onClick={() => {
                                            window.open(
                                                'https://www.forbes.com/sites/stephaniehirschmiller/2022/05/01/origyn-partners-with-watchbox-sets-standard-in-authentication-of-luxury-timepieces/?sh=50a8488c1e51',
                                            );
                                        }}
                                    >
                                        How Origyn’s Sets Diamond Standard In Authentication For
                                        Luxury Timepieces
                                    </span>
                                    <div className="arrowIcon">
                                        <img src={arrowIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="itemTableContent">
                            <div className="coverImg">
                                <img src={newscover_2} alt="" />
                            </div>
                            <div className="descContent">
                                <div className="title">ORIGYN X Bitcoin Insider</div>
                                <div className="desc">
                                    <span
                                        onClick={() => {
                                            window.open(
                                                'https://www.bitcoininsider.org/article/210343/icp-blockchain-technology-revolutionizes-authentication-and-digital-certification',
                                            );
                                        }}
                                    >
                                        ICP Blockchain revolutionizes authentication & digital
                                        certification of real products
                                    </span>
                                    <div className="arrowIcon">
                                        <img src={arrowIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="itemTableContent">
                            <div className="coverImg">
                                <img src={newscover_3} alt="" />
                            </div>
                            <div className="descContent">
                                <div className="title">ORIGYN X Crypto Potato</div>
                                <div className="desc">
                                    <span
                                        onClick={() => {
                                            window.open(
                                                'https://www.coindesk.com/business/2021/11/23/nft-authenticator-origyn-raises-20m-at-a-300m-valuation/',
                                            );
                                        }}
                                    >
                                        Billionaire Bill Ackman Says Crypto is Here to Stay After
                                        FTX Implosion
                                    </span>
                                    <div className="arrowIcon">
                                        <img src={arrowIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="itemTableContent">
                            <div className="coverImg">
                                <img src={newscover_4} alt="" />
                            </div>
                            <div className="descContent">
                                <div className="title">Shiku x Art Basel</div>
                                <div className="desc">
                                    <span
                                        onClick={() => {
                                            window.open(
                                                'https://blog.shiku.com/unveiling-mcc-world-where-art-and-the-metaverse-converge/',
                                            );
                                        }}
                                    >
                                        Unveiling Conor Mccreedy's World: Shiku at Art Basel
                                    </span>
                                    <div className="arrowIcon">
                                        <img src={arrowIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="itemTableContent">
                            <div className="coverImg">
                                <img src={newscover_5} alt="" />
                            </div>
                            <div className="descContent">
                                <div className="title">Shiku X NFT Talinn</div>
                                <div className="desc">
                                    <span
                                        onClick={() => {
                                            window.open(
                                                'https://blog.shiku.com/shiku-metaverse-unveils-the-true-potential-of-the-metaverse-at-nft-tallinn/',
                                            );
                                        }}
                                    >
                                        Shiku Metaverse at NFT Tallinn
                                    </span>
                                    <div className="arrowIcon">
                                        <img src={arrowIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="itemTableContent">
                            <div className="coverImg">
                                <img src={newscover_6} alt="" />
                            </div>
                            <div className="descContent">
                                <div className="title">Yumi x Sodexo</div>
                                <div className="desc">
                                    <span
                                        onClick={() => {
                                            window.open(
                                                'https://decrypt.co/146830/sodexo-drops-nfts-twist-employees-not-public',
                                            );
                                        }}
                                    >
                                        Sodexo Drops NFTs With a Twist: They’re for Employees, Not
                                        the Public
                                    </span>
                                    <div className="arrowIcon">
                                        <img src={arrowIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rightCopntent">
                        <div className="infoContent">
                            <div className="titleText">Other news from our members</div>
                            <div className="descText">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://decrypt.co/86677/paris-hilton-joins-20m-investment-in-nft-platform-origyn',
                                        );
                                    }}
                                >
                                    A Digital Certificate for Authentic Italian Products
                                </span>
                                <div className="arrowIcon">
                                    <img src={arrowIcon} alt="" />
                                </div>
                            </div>
                            {/* <div className="descText">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://blog.shiku.com/metaversity-an-open-innovative-and-sustainable-academy-in-shiku-metaverse/',
                                        );
                                    }}
                                >
                                    Metaversity: Revolutionazing Academy in Shiku Metaverse
                                </span>
                                <div className="arrowIcon">
                                    <img src={arrowIcon} alt="" />
                                </div>
                            </div> */}
                            <div className="descText">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://yumimarketplace.medium.com/a-julian-opie-will-be-the-first-fractionalized-artwork-on-yumi-nft-marketplace-e09a733f3a45',
                                        );
                                    }}
                                >
                                    Fractionalizing Fine Art: Julian Opie on Yumi
                                </span>
                                <div className="arrowIcon">
                                    <img src={arrowIcon} alt="" />
                                </div>
                            </div>
                            <div className="descText">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://yumimarketplace.medium.com/yumi-adds-alchemy-pays-fiat-on-ramp-solution-to-our-platform-abb4e38e7ae9',
                                        );
                                    }}
                                >
                                    Yumi integrates the first ICP on-ramp with Alchemy Pay
                                </span>
                                <div className="arrowIcon">
                                    <img src={arrowIcon} alt="" />
                                </div>
                            </div>
                            <Link
                                to="/news"
                                onClick={() => window.scrollTo(0, 0)}
                                className="group mt-2 inline-flex h-[54px]  cursor-pointer items-start justify-start"
                            >
                                <div className="flex h-full items-start justify-start gap-5 border border-[#BFFF00] bg-black px-6 font-['Inter-Bold'] text-[13px] font-bold uppercase leading-[54px] tracking-[3.25px] text-[#BFFF00] group-hover:bg-[#373737]">
                                    View more
                                </div>
                                <div className="relative flex h-[54px] w-14  border border-[#BFFF00] bg-[#BFFF00]">
                                    <img src="/img/home/arrow.svg" className="m-auto" alt="" />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="infoTableContent">
                    <div className="itemTableContent">
                        <div className="coverImg">
                            <img src={newscover_1} alt="" />
                        </div>
                        <div className="descContent">
                            <div className="title">ORIGYN X Forbes</div>
                            <div className="desc">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://www.forbes.com/sites/stephaniehirschmiller/2022/05/01/origyn-partners-with-watchbox-sets-standard-in-authentication-of-luxury-timepieces/?sh=50a8488c1e51',
                                        );
                                    }}
                                >
                                    How Origyn’s Sets Diamond Standard In Authentication For Luxury
                                    Timepieces
                                </span>
                                <div className="arrowIcon">
                                    <img src={arrowIcon} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="itemTableContent">
                        <div className="coverImg">
                            <img src={newscover_2} alt="" />
                        </div>
                        <div className="descContent">
                            <div className="title">ORIGYN X Bitcoin Insider</div>
                            <div className="desc">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://www.bitcoininsider.org/article/210343/icp-blockchain-technology-revolutionizes-authentication-and-digital-certification',
                                        );
                                    }}
                                >
                                    ICP Blockchain revolutionizes authentication & digital
                                    certification of real products
                                </span>
                                <div className="arrowIcon">
                                    <img src={arrowIcon} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="itemTableContent">
                        <div className="coverImg">
                            <img src={newscover_3} alt="" />
                        </div>
                        <div className="descContent">
                            <div className="title">ORIGYN X Crypto Potato</div>
                            <div className="desc">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://www.coindesk.com/business/2021/11/23/nft-authenticator-origyn-raises-20m-at-a-300m-valuation/',
                                        );
                                    }}
                                >
                                    Billionaire Bill Ackman Says Crypto is Here to Stay After FTX
                                    Implosion
                                </span>
                                <div className="arrowIcon">
                                    <img src={arrowIcon} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="itemTableContent">
                        <div className="coverImg">
                            <img src={newscover_4} alt="" />
                        </div>
                        <div className="descContent">
                            <div className="title">Shiku x Art Basel</div>
                            <div className="desc">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://blog.shiku.com/unveiling-mcc-world-where-art-and-the-metaverse-converge/',
                                        );
                                    }}
                                >
                                    Unveiling Conor Mccreedy's World: Shiku at Art Basel
                                </span>
                                <div className="arrowIcon">
                                    <img src={arrowIcon} alt="" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="itemTableContent">
                        <div className="coverImg">
                            <img src={newscover_5} alt="" />
                        </div>
                        <div className="descContent">
                            <div className="title">Shiku X NFT Talinn</div>
                            <div className="desc">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://blog.shiku.com/shiku-metaverse-unveils-the-true-potential-of-the-metaverse-at-nft-tallinn/',
                                        );
                                    }}
                                >
                                    Shiku Metaverse at NFT Tallinn
                                </span>
                                <div className="arrowIcon">
                                    <img src={arrowIcon} alt="" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="itemTableContent">
                        <div className="coverImg">
                            <img src={newscover_6} alt="" />
                        </div>
                        <div className="descContent">
                            <div className="title">Yumi x Sodexo</div>
                            <div className="desc">
                                <span
                                    onClick={() => {
                                        window.open(
                                            'https://finance.yahoo.com/news/sodexo-drops-nfts-twist-employees-110102327.html',
                                        );
                                    }}
                                >
                                    Sodexo Drops NFTs With a Twist: They’re for Employees, Not the
                                    Public
                                </span>
                                <div className="arrowIcon">
                                    <img src={arrowIcon} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="infoContent">
                        <div className="titleText">Other news</div>
                        <div className="descText">
                            <span
                                onClick={() => {
                                    window.open(
                                        'https://decrypt.co/86677/paris-hilton-joins-20m-investment-in-nft-platform-origyn',
                                    );
                                }}
                            >
                                A Digital Certificate for Authentic Italian Products
                            </span>
                            <img src={arrowIcon} alt="" />
                        </div>
                        {/* <div className="descText">
                            <span
                                style={{ width: appType === 'phone' ? '296px' : null }}
                                onClick={() => {
                                    window.open(
                                        'https://blog.shiku.com/metaversity-an-open-innovative-and-sustainable-academy-in-shiku-metaverse/',
                                    );
                                }}
                            >
                                Metaversity: Revolutionazing Academy in Shiku Metaverse
                            </span>
                            <img src={arrowIcon} alt="" />
                        </div> */}
                        <div className="descText">
                            <span
                                onClick={() => {
                                    window.open(
                                        'https://yumimarketplace.medium.com/a-julian-opie-will-be-the-first-fractionalized-artwork-on-yumi-nft-marketplace-e09a733f3a45',
                                    );
                                }}
                            >
                                Fractionalizing Fine Art: Julian Opie on Yumi
                            </span>
                            <img src={arrowIcon} alt="" />
                        </div>
                        <div className="descText">
                            <span
                                onClick={() => {
                                    window.open(
                                        'https://yumimarketplace.medium.com/yumi-adds-alchemy-pays-fiat-on-ramp-solution-to-our-platform-abb4e38e7ae9',
                                    );
                                }}
                            >
                                Yumi integrates the first ICP on-ramp with Alchemy Pay
                            </span>
                            <img src={arrowIcon} alt="" />
                        </div>
                        <Link
                            to="/news"
                            onClick={() => window.scrollTo(0, 0)}
                            className="group mt-2 inline-flex h-[54px]  cursor-pointer items-start justify-start"
                        >
                            <div className="flex h-full items-start justify-start gap-5 border border-[#BFFF00] bg-black px-6 font-['Inter-Bold'] text-[13px] font-bold uppercase leading-[54px] tracking-[3.25px] text-[#BFFF00] group-hover:bg-[#373737]">
                                View more
                            </div>
                            <div className="relative flex h-[54px] w-14  border border-[#BFFF00] bg-[#BFFF00]">
                                <img src="/img/home/arrow.svg" className="m-auto" alt="" />
                            </div>
                        </Link>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className="ourMembersWrap">
            <div className="comTitle">
                <span className="whiteText">Latest news</span>
                <span className="themeColorText">from our members</span>
            </div>
            {getInfoTableContent()}
        </div>
    );
};

export default OurMembersWrap;
