import { useStateStore } from '@/store';
// import { s3address } from "@/utils/s3.js";
import './index.scss';
import Login from '@/views/login/index.jsx';
import check from '@/assets/HomePage/ExploreOurOfferWrap/check.svg';

const ExploreOurOfferWrap = () => {
    const state = useStateStore();
    const { appType } = state.default;

    const getDescItemSpanStyle = () => {
        if (appType === 'pc') {
            return {
                lineHeight: '40px',
            };
        } else if (appType === 'phone') {
            return {
                lineHeight: '30px',
            };
        } else {
            return {};
        }
    };

    return (
        <div className="exploreOurOfferWrap">
            <div className="comTitle">
                <span className="whiteText">Contact us to</span>
                <span className="themeColorText">
                    explore our offers
                    {/* {appType === "pad" ? <>&nbsp;</> : <br />} */}
                </span>
            </div>
            <div className="infoContent">
                <div className="hintContent">
                    {/* <div className="titleText">Check out some of our benefits for members</div> */}
                    <div className="descItem">
                        <img src={check} alt="" />
                        <span>Access to all events</span>
                    </div>
                    <div className="descItem">
                        <img src={check} alt="" />
                        <span style={getDescItemSpanStyle()}>
                            Access to meeting rooms and VR room
                        </span>
                    </div>
                    <div className="descItem">
                        <img src={check} alt="" />
                        <span>Option to coorganize events</span>
                    </div>
                    <div className="descItem">
                        <img src={check} alt="" />
                        <span>Option to speak at our events </span>
                    </div>
                    <div className="descItem">
                        <img src={check} alt="" />
                        <span>Coffee and refreshments</span>
                    </div>
                    <div className="descItem">
                        <img src={check} alt="" />
                        <span>Private desk with storage space</span>
                    </div>
                    <div className="descItem">
                        <img src={check} alt="" />
                        <span style={getDescItemSpanStyle()}>
                            Billiard table, table tennis & foosball table
                        </span>
                    </div>
                    <div className="descItem">
                        <img src={check} alt="" />
                        <span
                            style={{
                                lineHeight: '30px',
                            }}
                        >
                            Location at the heart of Zurich with waterfront and mountain view
                        </span>
                    </div>
                </div>
                <div className="formContent">
                    <div className="titleText">Let’s get in touch.</div>
                    <div className="exploreOurOfferWrap-login">
                        <Login showMessage={true} title={'SEND US A MESSAGE'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExploreOurOfferWrap;
