// import { s3address } from "@/utils/s3.js";

import one1 from '@/assets/HomePage/WebEventsWrap/one1.svg';
import one2 from '@/assets/HomePage/WebEventsWrap/one2.svg';
import one3 from '@/assets/HomePage/WebEventsWrap/one3.svg';
import two2 from '@/assets/HomePage/WebEventsWrap/two2.svg';
import two3 from '@/assets/HomePage/WebEventsWrap/two3.svg';
import three1 from '@/assets/HomePage/WebEventsWrap/three1.svg';
import three2 from '@/assets/HomePage/WebEventsWrap/three2.svg';
import three3 from '@/assets/HomePage/WebEventsWrap/three3.svg';
import four1 from '@/assets/HomePage/WebEventsWrap/four1.svg';
import four2 from '@/assets/HomePage/WebEventsWrap/four2.svg';
import five1 from '@/assets/HomePage/WebEventsWrap/five1.svg';
import five2 from '@/assets/HomePage/WebEventsWrap/five2.svg';
import five3 from '@/assets/HomePage/WebEventsWrap/five3.svg';
import six1 from '@/assets/HomePage/WebEventsWrap/six1.svg';
import six2 from '@/assets/HomePage/WebEventsWrap/six2.svg';
import six3 from '@/assets/HomePage/WebEventsWrap/six3.svg';
import origynLogoIcon from '@/assets/HomePage/WebEventsWrap/origynLogoIcon.svg';

import baner_1 from '@/assets/HomePage/WebEventsWrap/one.png';
import baner_2 from '@/assets/HomePage/WebEventsWrap/two.png';
import baner_4 from '@/assets/HomePage/WebEventsWrap/four.png';
import baner_5 from '@/assets/HomePage/WebEventsWrap/five.png';
import baner_6 from '@/assets/HomePage/WebEventsWrap/six.png';

export const webEventsWrapCardListInfoPc = [
    {
        bannerId: 'oneBanner',
        bannerList: [
            {
                bannerImg: baner_1,
                date: 'June 6, 2023',
                titleText: 'Swiss Blockchain Finance Day',
                descText: [
                    {
                        icon: one1,
                        text: 'Bitcoin Suisse, Sygnum Bank, Seba Bank, UBS, AWS, Web3 Foundation, Fireblocks and many more',
                    },
                    {
                        icon: one2,
                        text: 'Panels on investment, tokenisation, DeFi, regulation',
                    },
                    {
                        icon: one3,
                        text: '20+ one on one sessions with VCs',
                    },
                ],
            },
            {
                bannerImg: baner_2,
                date: 'April 27, 2023',
                titleText: 'Yumi Gold Launch',
                descText: [
                    {
                        icon: one1,
                        text: 'Dfinity, 	Taurus, CV Labs, Binance, VCs & MFOs',
                    },
                    {
                        icon: two2,
                        text: 'The most expensive NFT on ICP sold at the event',
                    },
                    {
                        icon: two3,
                        text: 'Two world-famous DJs',
                    },
                ],
            },
            {
                bannerImg: origynLogoIcon,
                bannerImgStyle: {
                    width: 'unset',
                    height: 'unset',
                },
                date: 'March 27, 2023',
                titleText: 'FEDERITALY and ORIGYN Reception',
                descText: [
                    {
                        icon: three1,
                        text: 'ORIGYN Foundation and FEDERITALY partnership',
                    },
                    {
                        icon: three2,
                        text: 'Unique collaboration with the Italian government',
                    },
                    {
                        icon: three3,
                        text: 'Blockchain technology used to protect Italian made goods ',
                    },
                ],
            },
        ],
    },
    {
        bannerId: 'twoBanner',
        bannerList: [
            {
                bannerImg: baner_6,
                date: 'March 23, 2023',
                titleText: 'Yumi One Year Anniversary',
                descText: [
                    {
                        icon: six1,
                        text: '100 unique collections on Yumi',
                    },
                    {
                        icon: six2,
                        text: '3000 community members joined celebration in Shiku Metaverse',
                    },
                    {
                        icon: six3,
                        text: 'Panels on future of NFTs, AI in crypto, cross-chain integration',
                    },
                ],
            },
            {
                bannerImg: baner_4,
                date: 'February 8, 2023',
                titleText: 'ICP Labs DeFi Workshop',
                descText: [
                    {
                        icon: four1,
                        text: 'Top ICP projects, e.g. Decentralized Exchanges, Crypto Wallets',
                    },
                    {
                        icon: four2,
                        text: 'Training sessions by Dfinity Foundation as part of ICP Labs program',
                    },
                ],
            },
            {
                bannerImg: baner_5,
                date: 'December 8, 2022',
                titleText: 'Bochsler Group Blockchain Event',
                descText: [
                    {
                        icon: five1,
                        text: 'First ever publicly co-owned fractionalized fine art painting',
                    },
                    {
                        icon: five2,
                        text: 'Founding of the first Swiss metaverse foundation ',
                    },
                    {
                        icon: five3,
                        text: 'Launch of Swiss Blockchain Labs',
                    },
                ],
            },
        ],
    },
];

export const webEventsWrapCardListInfoPad = [
    {
        bannerId: 'oneBanner',
        bannerList: [
            {
                bannerImg: baner_1,
                date: 'June 6, 2023',
                titleText: 'Swiss Blockchain Finance Day',
                descText: [
                    {
                        icon: one1,
                        text: 'Bitcoin Suisse, Sygnum Bank, Seba Bank, UBS, AWS, Web3 Foundation, Fireblocks and many more',
                    },
                    {
                        icon: one2,
                        text: 'Panels on investment, tokenisation, DeFi, regulation',
                    },
                    {
                        icon: one3,
                        text: '20+ one on one sessions with VCs',
                    },
                ],
            },
            {
                bannerImg: baner_2,
                date: 'April 27, 2023',
                titleText: 'Yumi Gold Launch',
                descText: [
                    {
                        icon: one1,
                        text: 'Dfinity, 	Taurus, CV Labs, Binance, VCs & MFOs',
                    },
                    {
                        icon: two2,
                        text: 'The most expensive NFT on ICP sold at the event',
                    },
                    {
                        icon: two3,
                        text: 'Two world-famous DJs',
                    },
                ],
            },
        ],
    },
    {
        bannerId: 'twoBanner',
        bannerList: [
            {
                bannerImg: origynLogoIcon,
                bannerImgStyle: {
                    width: 'unset',
                    height: 'unset',
                },
                date: 'March 27, 2023',
                titleText: 'FEDERITALY and ORIGYN Reception',
                descText: [
                    {
                        icon: three1,
                        text: 'ORIGYN Foundation and FEDERITALY partnership',
                    },
                    {
                        icon: three2,
                        text: 'Unique collaboration with the Italian government',
                    },
                    {
                        icon: three3,
                        text: 'Blockchain technology used to protect Italian made goods ',
                    },
                ],
            },
            {
                bannerImg: baner_4,
                date: 'February 8, 2023',
                titleText: 'ICP Labs DeFi Workshop',
                descText: [
                    {
                        icon: four1,
                        text: 'Top ICP projects, e.g. Decentralized Exchanges, Crypto Wallets',
                    },
                    {
                        icon: four2,
                        text: 'Training sessions by Dfinity Foundation as part of ICP Labs program',
                    },
                ],
            },
        ],
    },
    {
        bannerId: 'threeBanner',
        bannerList: [
            {
                bannerImg: baner_6,
                date: 'March 23, 2023',
                titleText: 'Yumi One Year Anniversary',
                descText: [
                    {
                        icon: six1,
                        text: '100 unique collections on Yumi',
                    },
                    {
                        icon: six2,
                        text: '3000 community members joined celebration in Shiku Metaverse',
                    },
                    {
                        icon: six3,
                        text: 'Panels on future of NFTs, AI in crypto, cross-chain integration',
                    },
                ],
            },
            {
                bannerImg: baner_5,
                date: 'December 8, 2022',
                titleText: 'Bochsler Group Blockchain Event',
                descText: [
                    {
                        icon: five1,
                        text: 'First ever publicly co-owned fractionalized fine art painting',
                    },
                    {
                        icon: five2,
                        text: 'Founding of the first Swiss metaverse foundation ',
                    },
                    {
                        icon: five3,
                        text: 'Launch of Swiss Blockchain Labs',
                    },
                ],
            },
        ],
    },
];

export const webEventsWrapCardListInfoPhone = [
    {
        bannerId: 'oneBanner',
        bannerList: [
            {
                bannerImg: baner_1,
                date: 'June 6, 2023',
                titleText: 'Swiss Blockchain Finance Day',
                descText: [
                    {
                        icon: one1,
                        text: 'Bitcoin Suisse, Sygnum Bank, Seba Bank, UBS, AWS, Web3 Foundation, Fireblocks and many more',
                    },
                    {
                        icon: one2,
                        text: 'Panels on investment, tokenisation, DeFi, regulation',
                    },
                    {
                        icon: one3,
                        text: '20+ one on one sessions with VCs',
                    },
                ],
            },
        ],
    },
    {
        bannerId: 'twoBanner',
        bannerList: [
            {
                bannerImg: baner_2,
                date: 'April 27, 2023',
                titleText: 'Yumi Gold Launch',
                descText: [
                    {
                        icon: one1,
                        text: 'Dfinity, 	Taurus, CV Labs, Binance, VCs & MFOs',
                    },
                    {
                        icon: two2,
                        text: 'The most expensive NFT on ICP sold at the event',
                    },
                    {
                        icon: two3,
                        text: 'Two world-famous DJs',
                    },
                ],
            },
        ],
    },
    {
        bannerId: 'threeBanner',
        bannerList: [
            {
                bannerImg: origynLogoIcon,
                bannerImgStyle: {
                    width: 'unset',
                    height: 'unset',
                },
                date: 'March 27, 2023',
                titleText: 'FEDERITALY and ORIGYN Reception',
                descText: [
                    {
                        icon: three1,
                        text: 'ORIGYN Foundation and FEDERITALY partnership',
                    },
                    {
                        icon: three2,
                        text: 'Unique collaboration with the Italian government',
                    },
                    {
                        icon: three3,
                        text: 'Blockchain technology used to protect Italian made goods ',
                    },
                ],
            },
        ],
    },
    {
        bannerId: 'sixBanner',
        bannerList: [
            {
                bannerImg: baner_6,
                date: 'March 23, 2023',
                titleText: 'Yumi One Year Anniversary',
                descText: [
                    {
                        icon: six1,
                        text: '100 unique collections on Yumi',
                    },
                    {
                        icon: six2,
                        text: '3000 community members joined celebration in Shiku Metaverse',
                    },
                    {
                        icon: six3,
                        text: 'Panels on future of NFTs, AI in crypto, cross-chain integration',
                    },
                ],
            },
        ],
    },
    {
        bannerId: 'fourBanner',
        bannerList: [
            {
                bannerImg: baner_4,
                date: 'February 8, 2023',
                titleText: 'ICP Labs DeFi Workshop',
                descText: [
                    {
                        icon: four1,
                        text: 'Top ICP projects, e.g. Decentralized Exchanges, Crypto Wallets',
                    },
                    {
                        icon: four2,
                        text: 'Training sessions by Dfinity Foundation as part of ICP Labs program',
                    },
                ],
            },
        ],
    },
    {
        bannerId: 'fiveBanner',
        bannerList: [
            {
                bannerImg: baner_5,
                date: 'December 8, 2022',
                titleText: 'Bochsler Group Blockchain Event',
                descText: [
                    {
                        icon: five1,
                        text: 'First ever publicly co-owned fractionalized fine art painting',
                    },
                    {
                        icon: five2,
                        text: 'Founding of the first Swiss metaverse foundation ',
                    },
                    {
                        icon: five3,
                        text: 'Launch of Swiss Blockchain Labs',
                    },
                ],
            },
        ],
    },
];
