// reducer降级
export const combineReducers = (reducerObj) => {
    return (state, action) => {
        return Object.keys(reducerObj).reduce(
            (acc, prop) => ({
                ...acc,
                [prop]: reducerObj[prop](acc[prop], action),
            }),
            state,
        );
    };
};

// 默认主模块reducer
export const reducer = (state, action) => {
    switch (action.type) {
        case 'setAppWidth':
            window.appType = action.appType;
            window.appWidth = action.appWidth;
            return {
                ...state,
                appWidth: action.appWidth,
                appType: action.appType,
                appSubType: action.appSubType,
            };
        default:
            return state;
    }
};

// 默认主state
export const state = {
    token: localStorage.getItem('token') || '',
    ICP2USDRate: 0,
    OGY2USDRate: 0,
    whitelist: [],
    isWhitelistAgent: false,
    shikuActor: null,
    ledgerActor: null,
    agent: null,
    agentType: null,
    principal: null,
    profile: null,
    anonymousAgent: null,
    anonymousShikuActor: null,
    anonymousArtistActor: null,
    logining: false,
    creatorWhiteList: null, // create NFT/Collection 白名单
    myCollectionList: null, // my collection list
    icpBalance: 0, // user icp balance
    appWidth: null, // document app width
    appType: '', // app type, eg: pc | pad | mobile
    artistWhiteList: [], // artist white list
    updataProfileFlag: false,
    userAgent: 'Other',
    appSubType: false,
    shopCart: null,
    reClaimVisible: false,
    goldCart: localStorage.getItem('goldCart') ? JSON.parse(localStorage.getItem('goldCart')) : [],
    kycLevel: 'NA',
    kycPending: false,
    kycForm: null,
    makeOffer: null,
};
