import twitter from '@/svg/twitter.svg';
import Btn from '@/components/btn.jsx';
import SubscribeWrap from '@/views/HomePage/components/SubscribeWrap';
import GoToTopWrap from '@/views/HomePage/components/GoToTopWrap';
import { cn } from '@/common/cn';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateStore } from '@/store';
import { isMobile } from 'react-device-detect';
const news = [
    {
        title: 'ORIGYN X Bitcoin Insider',
        desc: 'ICP Blockchain  revolutionizes authentication & digital certification of real products',
        link: 'https://www.bitcoininsider.org/article/210343/icp-blockchain-technology-revolutionizes-authentication-and-digital-certification',
    },
    {
        title: 'Yumi x Sodexo',
        desc: 'Sodexo Drops NFTs With a Twist: They’re for Employees, Not the Public',
        link: 'https://decrypt.co/146830/sodexo-drops-nfts-twist-employees-not-public',
    },
    {
        title: 'Openchat x Modclub',
        desc: 'Sodexo Drops NFTs With a Twist: They’re for Employees, Not the Public',
        link: 'https://medium.com/@modclub/openchat-partners-with-modclub-for-decentralized-content-moderation-856548fd6f92',
    },
    {
        title: 'ORIGYN X Crypto Potato',
        desc: 'Billionaire Bill Ackman Says Crypto is Here to Stay After FTX Implosion',
        link: 'https://www.coindesk.com/business/2021/11/23/nft-authenticator-origyn-raises-20m-at-a-300m-valuation/',
    },
    // {
    //     title: 'Shiku x Art Basel',
    //     desc: "Unveiling Conor Mccreedy's World: Shiku at Art Basel",
    //     link: 'https://blog.shiku.com/unveiling-mcc-world-where-art-and-the-metaverse-converge/',
    // },
    {
        title: 'ORIGYN X Decrypt',
        desc: 'Paris Hilton Joins $20M Investment in NFT Platform Origyn',
        link: 'https://decrypt.co/86677/paris-hilton-joins-20m-investment-in-nft-platform-origyn',
    },
    // {
    //     title: 'Shiku',
    //     desc: 'Metaversity: Revolutionazing Academy in Shiku Metaverse',
    //     link: 'https://blog.shiku.com/metaversity-an-open-innovative-and-sustainable-academy-in-shiku-metaverse/',
    // },
    {
        title: 'Yumi x ORIGYN',
        desc: 'Fractionalizing Fine Art: Julian Opie on Yumi',
        link: 'https://yumimarketplace.medium.com/a-julian-opie-will-be-the-first-fractionalized-artwork-on-yumi-nft-marketplace-e09a733f3a45',
    },
    {
        title: 'Yumi X Alchemy Pay',
        desc: 'Yumi integrates the first ICP on-ramp with Alchemy Pay',
        link: 'https://yumimarketplace.medium.com/yumi-adds-alchemy-pays-fiat-on-ramp-solution-to-our-platform-abb4e38e7ae9',
    },
    // {
    //     title: 'Shiku X Lugano Fest',
    //     desc: 'Shiku Shines at Lugano NFT Fest: Bridging the Gap Between NFTs, Metaverse, and the Real World',
    //     link: 'https://blog.shiku.com/shiku-shines-at-lugano-nft-fest-bridging-the-gap-between-nfts-metaverse-and-the-real-world/?ref=shiku-metaverse-newsletter',
    // },
    // {
    //     title: 'Yumi X Sodexo',
    //     desc: 'Sodexo Drops NFTs for employees, not the public',
    //     link: 'https://finance.yahoo.com/news/sodexo-drops-nfts-twist-employees-110102327.html?guccounter=1&guce_referrer=aHR0cDovL2xvY2FsaG9zdDo0MzAwLw&guce_referrer_sig=AQAAANzSKFmPQFwaL7HKqyezDtI9qB_09xKFlRr_joyrL5y3J3XsRGAS9-nwYSdwIbJKcM7RAE0uJFROG4RncPOZs-6sv2yg24B8ANGkG80egdx7pMzKf84jNrKmWcMFEMMkQ_cR9Q_19rZ_lgFLRtWjEVTM6cvnzL0oemKPahozJX94',
    // },
    {
        title: 'Yumi x Pluxee',
        desc: 'Yumi & Pluxee redefines employee engagement with NFTs',
        link: 'https://yumimarketplace.medium.com/pluxee-redefining-employee-engagement-through-nfts-and-blockchain-ceb009be230e',
    },
    // {
    //     title: 'Shiku X Swiss Web3 Fest',
    //     desc: 'Shiku Unveils Its Vision at Swiss Web3 Fest',
    //     link: 'https://blog.shiku.com/shiku-unveils-its-vision-at-swiss-web3-fest-the-future-of-the-metaverse/',
    // },
];
const filterOptions = ['All', 'Shiku', 'Yumi', 'ORIGYN'];
export default function News() {
    const [curFilter, setCurFilter] = useState('All');

    const filteredNews = news.filter((n) => {
        if (curFilter === 'All') {
            return true;
        }
        return n.title.indexOf(curFilter) !== -1 || n.desc.indexOf(curFilter) !== -1;
    });
    const [mobileShow, setMobileShow] = useState(4);

    const [mobileOptionShowMore, setMobileOptionShowMore] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return isMobile ? (
        <div className="flex flex-col">
            <div
                className="relative flex h-[725px] w-full flex-col justify-center gap-y-[14px] bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url('/img/news/ogy-lv.png')` }}
            >
                <img
                    src="/img/news/logo.svg"
                    className="absolute left-0 top-0 z-50 w-[148px]"
                    onClick={() => {
                        navigate('/');
                    }}
                    alt=""
                ></img>
                <img
                    src={twitter}
                    className="absolute right-0 top-0 cursor-pointer"
                    alt=""
                    onClick={() => window.open('https://twitter.com/SBL_Zurich')}
                ></img>
                <div className="absolute left-0 top-[1px] h-[437px] bg-gradient-to-b from-black to-black" />
                <div className="absolute left-0 top-0 h-[68px] w-[195px]">
                    <div className="absolute left-[1px] top-[1px] h-[66.45px] w-[173.80px]">
                        <div className="absolute left-[86px] top-[10.11px] h-[44.42px] w-[87.80px]"></div>
                        <div className="absolute left-0 top-0 h-[66.45px] w-[66.45px]"></div>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="left-0 top-0 flex flex-col text-center">
                        <span className="font-['Inter-Bold'] text-[34px] font-bold leading-[50px] text-white">
                            How Origyn’s Sets Diamond Standard In
                        </span>

                        <span className="font-['Inter-Bold'] text-[26px] font-bold leading-[50px] text-[#BFFF00]">
                            Authentication For Luxury Timepieces
                            <br />
                        </span>
                    </div>
                    <div className="mx-auto h-[38px]  text-center font-['Inter-Bold'] text-[20px] font-bold leading-[35px] text-white">
                        ORIGYN X FORBES
                    </div>
                </div>

                <Btn
                    text={'Read more'}
                    onClick={() => {
                        window.open(
                            'https://www.forbes.com/sites/stephaniehirschmiller/2022/05/01/origyn-partners-with-watchbox-sets-standard-in-authentication-of-luxury-timepieces/?sh=38ec8e7e1e51',
                        );
                    }}
                ></Btn>
            </div>
            <div className="flex w-screen flex-col  bg-[#131313] p-5 lg:p-16">
                <div className="m-auto flex w-full max-w-[1321px] flex-col justify-between">
                    <div className="flex w-full flex-col justify-start gap-y-8">
                        <div className="font-['Inter-Bold']">
                            <span className=" text-[36px] font-bold leading-[50px] text-white">
                                Latest news
                                <br />
                            </span>
                            <span className="w-[100px] whitespace-pre-wrap  text-[36px] font-bold leading-[50px] text-[#BFFF00] lg:w-auto">
                                from our members
                            </span>
                        </div>
                        <div className="flex w-full flex-shrink-0 flex-col  flex-wrap  transition-all duration-300">
                            {(mobileOptionShowMore ? filterOptions : [curFilter]).map(
                                (f, index) => (
                                    <div
                                        onClick={() => {
                                            setCurFilter(f);
                                            setMobileOptionShowMore((s) => !s);
                                        }}
                                        className={cn(
                                            " flex h-10 w-full cursor-pointer items-center justify-center   font-['Inter-SemiBold'] text-[13px] font-bold leading-[30px] text-white hover:border-[#BFFF00] hover:text-[#BFFF00]",
                                            index !== 0 && '-mt-px',
                                        )}
                                        key={f}
                                    >
                                        <div
                                            className={cn(
                                                'h-full flex-1 border  border-stone-300 text-center leading-10',
                                                curFilter === f &&
                                                    'z-10 border-[#BFFF00] text-[#BFFF00]',
                                            )}
                                        >
                                            {f}
                                        </div>
                                        {
                                            <div
                                                className={cn(
                                                    'ml-auto flex  h-full w-11 bg-[#BFFF00]',
                                                )}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setMobileOptionShowMore((s) => !s);
                                                }}
                                            >
                                                {index === 0 && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="25"
                                                        className={cn(
                                                            'm-auto transition-all duration-300',
                                                            mobileOptionShowMore && 'rotate-180 ',
                                                        )}
                                                        viewBox="0 0 24 25"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M11.0215 1.96087L22.1942 12.6719L23.2801 11.5392L12.1074 0.828168L11.0215 1.96087Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            d="M11.3297 2.7058L11.1388 23.9996L12.6605 23.9996L12.8514 2.62752L11.3297 2.7058Z"
                                                            fill="black"
                                                        />
                                                        <path
                                                            d="M12.1073 0.828068L0.726715 12.6992L1.79278 13.7212L13.1734 1.85008L12.1073 0.828068Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                )}
                                            </div>
                                        }
                                    </div>
                                ),
                            )}
                        </div>
                    </div>
                    <div className="grid flex-shrink-0 grid-cols-1 gap-5 text-white lg:w-auto">
                        {filteredNews.slice(0, mobileShow).map((n) => (
                            <div className="flex w-full cursor-pointer flex-col gap-x-7 border-b border-[#8A8A8A33]/20 py-5 hover:bg-[#BFFF00] hover:bg-opacity-[0.03] ">
                                <img src={`/img/news/${n.title.replaceAll(' ', '-')}.png`}></img>
                                <div className="mt-2">
                                    <div className="font-['Inter-Bold'] text-[13px] font-bold leading-[30px] text-white">
                                        {n.title}
                                    </div>
                                    <div
                                        className="group flex cursor-pointer justify-start gap-x-5"
                                        onClick={() => {
                                            window.open(n.link);
                                        }}
                                    >
                                        <div className="w-[80%] font-['Inter'] text-lg font-normal leading-[35px] text-stone-300 decoration-[#BFFF00] group-hover:underline">
                                            {n.desc}
                                        </div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="14"
                                            viewBox="0 0 15 14"
                                            fill="none"
                                            className="mt-3 flex-shrink-0"
                                        >
                                            <path
                                                d="M12.8154 0.818256L12.8154 12.7782H14.0434L14.0434 0.818256H12.8154Z"
                                                fill="#BFFF00"
                                            />
                                            <path
                                                d="M12.5611 1.38887L0.739014 12.3749L1.60735 13.1818L13.4294 2.19579L12.5611 1.38887Z"
                                                fill="#BFFF00"
                                            />
                                            <path
                                                d="M14.0432 0.818176L1.1731 0.818176L1.1731 1.95935L14.0432 1.95935V0.818176Z"
                                                fill="#BFFF00"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {mobileShow !== filteredNews.length && (
                            <Btn
                                text={'View more'}
                                onClick={() => {
                                    console.debug(
                                        '🚀 ~ file: index.js:210 ~ News ~ filteredNews:',
                                        filteredNews,
                                    );
                                    setMobileShow(filteredNews.length);
                                }}
                            ></Btn>
                        )}
                    </div>
                </div>
            </div>
            <div className="bg-[#BFFF00] py-[120px]">
                <div className="h-[74px] text-center font-['Inter-SemiBold'] text-[42px] font-bold leading-[50px] text-neutral-900">
                    Sign up to our Newsletter
                </div>
                <SubscribeWrap hasLabel={false} wrapperClassName="border-none"></SubscribeWrap>
            </div>
            <GoToTopWrap wrapperClassName="!mt-0"></GoToTopWrap>
        </div>
    ) : (
        <div className="flex flex-col">
            <div
                className="relative flex h-[725px] w-full flex-col justify-center gap-y-[14px] bg-cover bg-center bg-no-repeat lg:h-[438px]"
                style={{ backgroundImage: `url('/img/news/ogy-lv.png')` }}
            >
                <img
                    src="/img/news/logo.svg"
                    className="absolute left-0 top-0 z-50"
                    onClick={() => {
                        navigate('/');
                    }}
                    alt=""
                ></img>
                <img
                    src={twitter}
                    className="absolute right-0 top-0 cursor-pointer"
                    alt=""
                    onClick={() => window.open('https://twitter.com/SBL_Zurich')}
                ></img>
                <div className="absolute left-0 top-[1px] h-[437px] bg-gradient-to-b from-black to-black" />
                <div className="absolute left-0 top-0 h-[68px] w-[195px]">
                    <div className="absolute left-[1px] top-[1px] h-[66.45px] w-[173.80px]">
                        <div className="absolute left-[86px] top-[10.11px] h-[44.42px] w-[87.80px]"></div>
                        <div className="absolute left-0 top-0 h-[66.45px] w-[66.45px]"></div>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="left-0 top-0 flex flex-col text-center">
                        <span className="font-['Inter-Bold'] text-[42px] font-bold leading-[50px] text-white">
                            How Origyn’s Sets Diamond Standard In
                        </span>

                        <span className="font-['Inter-Bold'] text-[42px] font-bold leading-[50px] text-[#BFFF00]">
                            Authentication For Luxury Timepieces
                            <br />
                        </span>
                    </div>
                    <div className="mx-auto h-[38px]  text-center font-['Inter-Bold'] text-xl font-bold leading-[35px] text-white">
                        ORIGYN X FORBES
                    </div>
                </div>
                <div className="absolute left-[227px] top-[22px] h-[35px] cursor-pointer">
                    <div className="absolute left-[16px] top-[26px] h-4 w-4 origin-top-left -rotate-180"></div>
                    <div
                        className="left-[23px] top-0 flex items-center gap-x-1 font-['Inter'] text-lg font-normal leading-[35px] text-stone-300"
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                d="M10.061 2.71997L5.71437 7.06664C5.20104 7.57997 5.20104 8.41997 5.71437 8.9333L10.061 13.28"
                                stroke="#C9C7C7"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>{' '}
                        <div>Back to Home</div>
                    </div>
                </div>
                <Btn
                    text={'Read more'}
                    onClick={() => {
                        window.open(
                            'https://www.forbes.com/sites/stephaniehirschmiller/2022/05/01/origyn-partners-with-watchbox-sets-standard-in-authentication-of-luxury-timepieces/?sh=38ec8e7e1e51',
                        );
                    }}
                ></Btn>
            </div>
            <div className="flex w-screen flex-col  bg-[#131313] p-5 lg:p-16">
                <div className="m-auto flex w-full max-w-[1321px] justify-between lg:flex-col">
                    <div className="flex w-full flex-col justify-start gap-y-8 lg:flex-row  lg:justify-between lg:gap-y-0">
                        <div className="font-['Inter-Bold']">
                            <span className=" text-[42px] font-bold leading-[50px] text-white">
                                Latest news
                                <br />
                            </span>
                            <span className="w-[100px] whitespace-pre-wrap  text-[42px] font-bold leading-[50px] text-[#BFFF00] lg:w-auto">
                                from our members
                            </span>
                        </div>
                        <div className="flex  flex-shrink-0 flex-col gap-x-4 gap-y-4 lg:flex-row">
                            {filterOptions.map((f) => (
                                <div
                                    onClick={() => {
                                        setCurFilter(f);
                                    }}
                                    className={cn(
                                        "inline-flex h-10 w-24 cursor-pointer items-center justify-center gap-2.5 border border-stone-300 px-[30px] py-[5px] font-['Inter-SemiBold'] text-[13px] font-bold leading-[30px] text-white hover:border-[#BFFF00] hover:text-[#BFFF00]",
                                        curFilter === f && 'border-[#BFFF00] text-[#BFFF00] ',
                                    )}
                                >
                                    {f}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="grid w-3/4 flex-shrink-0 grid-cols-1 gap-5 text-white lg:w-auto lg:grid-cols-2">
                        {filteredNews.map((n) => (
                            <div className="flex cursor-pointer gap-x-7 border-b border-[#8A8A8A33]/20 py-5 hover:bg-[#BFFF00] hover:bg-opacity-[0.03]">
                                <img src={`/img/news/${n.title.replaceAll(' ', '-')}.png`}></img>
                                <div className="self-end">
                                    <div className="font-['Inter-SemiBold'] text-[13px] font-bold leading-[30px] text-white">
                                        {n.title}
                                    </div>
                                    <div
                                        className="group flex cursor-pointer justify-start gap-x-5"
                                        onClick={() => {
                                            window.open(n.link);
                                        }}
                                    >
                                        <div className="w-[80%] font-['Inter'] text-lg font-normal leading-[35px] text-stone-300 decoration-[#BFFF00] group-hover:underline">
                                            {n.desc}
                                        </div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="14"
                                            viewBox="0 0 15 14"
                                            fill="none"
                                            className="mt-3 flex-shrink-0"
                                        >
                                            <path
                                                d="M12.8154 0.818256L12.8154 12.7782H14.0434L14.0434 0.818256H12.8154Z"
                                                fill="#BFFF00"
                                            />
                                            <path
                                                d="M12.5611 1.38887L0.739014 12.3749L1.60735 13.1818L13.4294 2.19579L12.5611 1.38887Z"
                                                fill="#BFFF00"
                                            />
                                            <path
                                                d="M14.0432 0.818176L1.1731 0.818176L1.1731 1.95935L14.0432 1.95935V0.818176Z"
                                                fill="#BFFF00"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="bg-[#BFFF00] py-[120px]">
                <div className="h-[74px] text-center font-['Inter-SemiBold'] text-[42px] font-bold leading-[50px] text-neutral-900">
                    Sign up to our Newsletter
                </div>
                <SubscribeWrap hasLabel={false} wrapperClassName="border-none"></SubscribeWrap>
            </div>
            <GoToTopWrap wrapperClassName="!mt-0"></GoToTopWrap>
        </div>
    );
}
