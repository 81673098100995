import { useStateStore } from '@/store';
// import { s3address } from "@/utils/s3.js";
import { meetsCapitalWrapPlatformList } from '../../data';
import './index.scss';

import closeIcon from '@/assets/HomePage/PlatformInfoModal/close.svg';
import arrow from '@/assets/HomePage/PlatformInfoModal/arrow.svg';
import check from '@/assets/HomePage/PlatformInfoModal/check.svg';

const PlatformInfoModal = ({
    platformInfoModalStyle,
    handlePlatformInfoModalStyle,
    platformRecordIndex,
}) => {
    const state = useStateStore();
    const { appType } = state.default;

    const getContentCom = () => {
        if (appType === 'pc') {
            return (
                <div
                    className="platformInfoModalWrapPc"
                    style={platformInfoModalStyle}
                    onClick={() => handlePlatformInfoModalStyle()}
                >
                    <div className="infoContent" onClick={(e) => e.stopPropagation()}>
                        <div
                            className="closeBtn"
                            onClick={() => {
                                handlePlatformInfoModalStyle();
                            }}
                        >
                            <img src={closeIcon} alt="" />
                        </div>
                        <div className="coverContent">
                            <div className="logoImg">
                                <img
                                    src={meetsCapitalWrapPlatformList[platformRecordIndex]?.logoImg}
                                    alt=""
                                />
                            </div>
                            <div className="titletext">
                                {meetsCapitalWrapPlatformList[platformRecordIndex]?.titletext}
                            </div>
                            <div className="btnContent">
                                {meetsCapitalWrapPlatformList[platformRecordIndex]?.btnList.map(
                                    (btnItem, btnIndex) => {
                                        return (
                                            <div
                                                key={btnIndex + 'key'}
                                                className="btnItem"
                                                onClick={() => {
                                                    if (btnItem.value === 'desk') {
                                                        const a = document.createElement('a');
                                                        a.href = btnItem.url;
                                                        a.download = 'yumi_fundraising_deck';
                                                        a.target = '_blank';
                                                        a.click();
                                                    } else {
                                                        window.open(btnItem.url);
                                                    }
                                                }}
                                            >
                                                <span>{btnItem.value}</span>
                                                <div className="img"></div>
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                            <div className="platformContent">
                                {meetsCapitalWrapPlatformList[platformRecordIndex]
                                    ?.bindPlatformList[0]?.url === undefined ? null : (
                                    <div
                                        className="btnPlatformItem discordLogo"
                                        onClick={() => {
                                            window.open(
                                                meetsCapitalWrapPlatformList[platformRecordIndex]
                                                    ?.bindPlatformList[0]?.url,
                                            );
                                        }}
                                    ></div>
                                )}
                                {meetsCapitalWrapPlatformList[platformRecordIndex]
                                    ?.bindPlatformList[1]?.url === undefined ? null : (
                                    <div
                                        className="btnPlatformItem twitterLogo"
                                        onClick={() => {
                                            window.open(
                                                meetsCapitalWrapPlatformList[platformRecordIndex]
                                                    ?.bindPlatformList[1]?.url,
                                            );
                                        }}
                                    ></div>
                                )}
                            </div>
                        </div>
                        <div className="descContent">
                            <div className="descText">
                                {meetsCapitalWrapPlatformList[platformRecordIndex]?.descText}
                            </div>
                            <div className="hintContent">
                                {meetsCapitalWrapPlatformList[
                                    platformRecordIndex
                                ]?.hintTextList.map((hintItem, hintIndex) => {
                                    return (
                                        <div
                                            key={hintIndex + 'key'}
                                            className="hintItem"
                                            onClick={() => {
                                                if (hintItem.url !== '') {
                                                    window.open(hintItem.url);
                                                }
                                            }}
                                        >
                                            <div style={{ marginRight: '20px' }}>
                                                <img src={check} alt="" />
                                            </div>
                                            <div className="lineValue">
                                                {hintItem.url === '' ? null : (
                                                    <div className="line"></div>
                                                )}
                                                <span>{hintItem.value}</span>
                                            </div>
                                            {hintItem.url === '' ? null : (
                                                <div style={{ marginLeft: '20px' }}>
                                                    <img src={arrow} alt="" />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (appType === 'phone') {
            return (
                <div
                    className="platformInfoModalWrapPhone"
                    style={platformInfoModalStyle}
                    onClick={() => handlePlatformInfoModalStyle()}
                >
                    <div className="infoContent" onClick={(e) => e.stopPropagation()}>
                        <div
                            className="closeBtn"
                            onClick={() => {
                                handlePlatformInfoModalStyle();
                            }}
                        >
                            <img src={closeIcon} alt="" />
                        </div>
                        <div className="coverContent">
                            <div className="logoImg">
                                <img
                                    src={meetsCapitalWrapPlatformList[platformRecordIndex]?.logoImg}
                                    alt=""
                                />
                            </div>
                            <div className="titletext">
                                {meetsCapitalWrapPlatformList[platformRecordIndex]?.titletext}
                            </div>
                            <div className="descText">
                                {meetsCapitalWrapPlatformList[platformRecordIndex]?.descText}
                            </div>
                            <div className="hintContent">
                                {meetsCapitalWrapPlatformList[
                                    platformRecordIndex
                                ]?.hintTextList.map((hintItem, hintIndex) => {
                                    return (
                                        <div
                                            key={hintIndex + 'key'}
                                            className="hintItem"
                                            onClick={() => {
                                                if (hintItem.url !== '') {
                                                    window.open(hintItem.url);
                                                }
                                            }}
                                        >
                                            <div className="checkIcon">
                                                <img src={check} alt="" />
                                            </div>
                                            <div className="lineValue">
                                                <span>{hintItem.value}</span>
                                            </div>
                                            {hintItem.url === '' ? null : (
                                                <div className="arrowIcon">
                                                    <img src={arrow} alt="" />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="btnContent">
                                {meetsCapitalWrapPlatformList[platformRecordIndex]?.btnList.map(
                                    (btnItem, btnIndex) => {
                                        return (
                                            <div
                                                key={btnIndex + 'key'}
                                                className="btnItem"
                                                onClick={() => {
                                                    if (btnItem.value === 'desk') {
                                                        const a = document.createElement('a');
                                                        a.href = btnItem.url;
                                                        a.download = 'yumi_fundraising_deck';
                                                        a.target = '_blank';
                                                        a.click();
                                                    } else {
                                                        window.open(btnItem.url);
                                                    }
                                                }}
                                            >
                                                <span>{btnItem.value}</span>
                                                <div className="img"></div>
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                            <div className="platformContent">
                                {meetsCapitalWrapPlatformList[platformRecordIndex]
                                    ?.bindPlatformList[0]?.url === undefined ? null : (
                                    <div
                                        className="btnPlatformItem discordLogo"
                                        onClick={() => {
                                            window.open(
                                                meetsCapitalWrapPlatformList[platformRecordIndex]
                                                    ?.bindPlatformList[0]?.url,
                                            );
                                        }}
                                    ></div>
                                )}
                                {meetsCapitalWrapPlatformList[platformRecordIndex]
                                    ?.bindPlatformList[1]?.url === undefined ? null : (
                                    <div
                                        className="btnPlatformItem twitterLogo"
                                        onClick={() => {
                                            window.open(
                                                meetsCapitalWrapPlatformList[platformRecordIndex]
                                                    ?.bindPlatformList[1]?.url,
                                            );
                                        }}
                                    ></div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className="platformInfoModalWrapPc"
                    style={platformInfoModalStyle}
                    onClick={() => handlePlatformInfoModalStyle()}
                >
                    <div className="infoContent" onClick={(e) => e.stopPropagation()}>
                        <div
                            className="closeBtn"
                            onClick={() => {
                                handlePlatformInfoModalStyle();
                            }}
                        >
                            <img src={closeIcon} alt="" />
                        </div>
                        <div className="coverContent">
                            <div className="logoImg">
                                <img
                                    src={meetsCapitalWrapPlatformList[platformRecordIndex]?.logoImg}
                                    alt=""
                                />
                            </div>
                            <div className="titletext">
                                {meetsCapitalWrapPlatformList[platformRecordIndex]?.titletext}
                            </div>
                            <div className="btnContent">
                                {meetsCapitalWrapPlatformList[platformRecordIndex]?.btnList.map(
                                    (btnItem, btnIndex) => {
                                        return (
                                            <div
                                                key={btnIndex + 'key'}
                                                className="btnItem"
                                                onClick={() => {
                                                    window.open(btnItem.url);
                                                }}
                                            >
                                                <span>{btnItem.value}</span>
                                                <div className="img"></div>
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                            <div className="platformContent">
                                {meetsCapitalWrapPlatformList[platformRecordIndex]
                                    ?.bindPlatformList[0]?.url === undefined ? null : (
                                    <div
                                        className="btnPlatformItem discordLogo"
                                        onClick={() => {
                                            window.open(
                                                meetsCapitalWrapPlatformList[platformRecordIndex]
                                                    ?.bindPlatformList[0]?.url,
                                            );
                                        }}
                                    ></div>
                                )}
                                {meetsCapitalWrapPlatformList[platformRecordIndex]
                                    ?.bindPlatformList[1]?.url === undefined ? null : (
                                    <div
                                        className="btnPlatformItem twitterLogo"
                                        onClick={() => {
                                            window.open(
                                                meetsCapitalWrapPlatformList[platformRecordIndex]
                                                    ?.bindPlatformList[1]?.url,
                                            );
                                        }}
                                    ></div>
                                )}
                            </div>
                        </div>
                        <div className="descContent">
                            <div className="descText">
                                {meetsCapitalWrapPlatformList[platformRecordIndex]?.descText}
                            </div>
                            <div className="hintContent">
                                {meetsCapitalWrapPlatformList[
                                    platformRecordIndex
                                ]?.hintTextList.map((hintItem, hintIndex) => {
                                    return (
                                        <div
                                            key={hintIndex + 'key'}
                                            className="hintItem"
                                            onClick={() => {
                                                if (hintItem.url !== '') {
                                                    window.open(hintItem.url);
                                                }
                                            }}
                                        >
                                            <div style={{ marginRight: '20px' }}>
                                                <img src={check} alt="" />
                                            </div>
                                            <div className="lineValue">
                                                {hintItem.url === '' ? null : (
                                                    <div className="line"></div>
                                                )}
                                                <span>{hintItem.value}</span>
                                            </div>
                                            {hintItem.url === '' ? null : (
                                                <div style={{ marginLeft: '20px' }}>
                                                    <img src={arrow} alt="" />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return <>{getContentCom()}</>;
};

export default PlatformInfoModal;
