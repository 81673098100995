import './index.scss';
const TextContent = () => {
    return (
        <div className="text-content-wrap">
            <div className="text-content-wrap-desc">
                Welcome to Switzerland's premier Web3 community space in the heart of Zurich with a
                breathtaking waterfront and mountain view! Switzerland boasts a booming crypto scene
                with over 1000 blockchain startups, and our vibrant hub stands as the epicenter for
                some of the finest ventures, positioned to revolutionize the Web3 landscape.{' '}
            </div>
            <div className="text-content-wrap-animations">
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                <div className="text-content-wrap-animations-box">
                    <span>SWISS BLOCKCHAIN LABS</span>
                    <span className="text-content-point">•</span>
                    <span>Switzerland's finest Web3 space</span>
                    <span className="text-content-point">•</span>
                </div>
                {/* <div className="text-content-wrap-animations-box">
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span> Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span> Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span> Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span> Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
        </div>
        <div className="text-content-wrap-animations-box">
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span> Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span> Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span> Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
          <span>SWISS BLOCKCHAIN LABS</span>
          <span className="text-content-point">•</span>
          <span>Switzerland's finest Web3 space</span>
          <span className="text-content-point">•</span>
        </div> */}
            </div>
        </div>
    );
};
export default TextContent;
