import React from 'react';
import ReactDOM from 'react-dom';
import Router from './routes';
import { StoreProvider } from './store';

import { ConfigProvider, Spin } from 'antd';

import 'swiper/swiper-bundle.css';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
import 'antd/dist/antd.variable.min.css';
import './scss/index.scss';

Spin.setDefaultIndicator(
    <img
        style={{ width: '100%', height: '100%' }}
        src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/loading-gif.gif"
        alt=""
    />,
);

ConfigProvider.config({
    theme: {
        primaryColor: '#000000',
    },
});

// navigator.serviceWorker.getRegistrations().then(function(registrations) {
//   for (let registration of registrations) {
//     registration.unregister();
//   }
// });

// BigInt.prototype.toJSON = function () {
//   return this.toString();
// };

ReactDOM.render(
    <React.Fragment>
        <StoreProvider>
            <Router />
        </StoreProvider>
    </React.Fragment>,
    document.getElementById('root'),
);
