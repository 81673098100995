export default function Btn({ text, onClick }) {
    return (
        <div className="group mx-auto flex cursor-pointer" onClick={onClick}>
            <div className="flex h-full items-start justify-start gap-5 border border-[#BFFF00] bg-black px-6 font-['Inter-Bold'] text-[13px] font-bold uppercase leading-[54px] tracking-[3.25px] text-[#BFFF00] group-hover:bg-[#373737]">
                {text}
            </div>
            <div className="relative flex h-full w-14  border border-[#BFFF00] bg-[#BFFF00]">
                <img src="/img/home/arrow.svg" className="m-auto" alt="" />
            </div>
        </div>
    );
}
