import { useState, useEffect } from 'react';
import './index.scss';

const generateArray = () => {
    let arr = [];
    let num = 0;
    while (arr.length < 54) {
        let randomNum = Math.floor(Math.random() * 54);
        if (arr.indexOf(randomNum) === -1) {
            arr.push(randomNum);
            num += 1;
        }
    }
    return { num, arr };
};

const FlickerAnimationCom = ({ flickerAnimationComStyle }) => {
    const [itemArr, setItemArr] = useState([]);

    useEffect(() => {
        setItemArr(generateArray().arr);
        setInterval(() => {
            setItemArr(generateArray().arr);
        }, 1000);
    }, []);

    return (
        <div className="flickerAnimationCom" style={flickerAnimationComStyle}>
            <div className="rowItem">
                {itemArr.slice(0, 9).map((item, index) => {
                    return (
                        <div
                            className="item"
                            key={item + 'key'}
                            style={
                                item >= index + 21
                                    ? {
                                          animation: 'noneOrDisplay 1s ease-in-out forwards',
                                      }
                                    : {}
                            }
                        ></div>
                    );
                })}
            </div>
            <div className="rowItem">
                {itemArr.slice(9, 18).map((item, index) => {
                    return (
                        <div
                            className="item"
                            key={item + 'key'}
                            style={
                                item >= index + 21
                                    ? {
                                          animation: 'noneOrDisplay 1s ease-in-out forwards',
                                      }
                                    : {}
                            }
                        ></div>
                    );
                })}
            </div>
            <div className="rowItem">
                {itemArr.slice(18, 27).map((item, index) => {
                    return (
                        <div
                            className="item"
                            key={item + 'key'}
                            style={
                                item >= index + 21
                                    ? {
                                          animation: 'noneOrDisplay 1s ease-in-out forwards',
                                      }
                                    : {}
                            }
                        ></div>
                    );
                })}
            </div>
            <div className="rowItem">
                {itemArr.slice(27, 36).map((item, index) => {
                    return (
                        <div
                            className="item"
                            key={item + 'key'}
                            style={
                                item >= index + 21
                                    ? {
                                          animation: 'noneOrDisplay 1s ease-in-out forwards',
                                      }
                                    : {}
                            }
                        ></div>
                    );
                })}
            </div>
            <div className="rowItem">
                {itemArr.slice(36, 45).map((item, index) => {
                    return (
                        <div
                            className="item"
                            key={item + 'key'}
                            style={
                                item >= index + 21
                                    ? {
                                          animation: 'noneOrDisplay 1s ease-in-out forwards',
                                      }
                                    : {}
                            }
                        ></div>
                    );
                })}
            </div>
            <div className="rowItem" style={{ margin: 0 }}>
                {itemArr.slice(45, 54).map((item, index) => {
                    return (
                        <div
                            className="item"
                            key={item + 'key'}
                            style={
                                item >= index + 21
                                    ? {
                                          animation: 'noneOrDisplay 1s ease-in-out forwards',
                                      }
                                    : {}
                            }
                        ></div>
                    );
                })}
            </div>
        </div>
    );
};

export default FlickerAnimationCom;
