import { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import { useStateStore } from '@/store';
// import { s3address } from "@/utils/s3.js";
import {
    webEventsWrapCardListInfoPc,
    webEventsWrapCardListInfoPad,
    webEventsWrapCardListInfoPhone,
} from './data';
import './index.scss';
import arrowRightIcon from '@/assets/HomePage/WebEventsWrap/arrow-right.svg';
import arrowLeftIcon from '@/assets/HomePage/WebEventsWrap/arrow-left.svg';
// import arrowRightIconNone from "@/assets/HomePage/WebEventsWrap/arrow-rightNone.svg";
// import arrowLeftIconNone from "@/assets/HomePage/WebEventsWrap/arrow-leftNone.svg`;

const WebEventsWrap = () => {
    const state = useStateStore();
    const { appType } = state.default;
    const [bannerIndex, setBannerIndex] = useState(0);

    useEffect(() => {
        setBannerIndex(0);
    }, [appType]);

    const handleBannerIndex = (newBannerIndex) => {
        setBannerIndex(newBannerIndex);
    };

    const getBannerArr = () => {
        if (appType === 'pc') {
            return webEventsWrapCardListInfoPc;
        } else if (appType === 'pad') {
            return webEventsWrapCardListInfoPad;
        } else if (appType === 'phone') {
            return webEventsWrapCardListInfoPhone;
        } else {
            return [];
        }
    };

    return (
        <div className="webEventsWrap">
            <div className="comTitle">
                <span className="whiteText">Our Web3</span>
                <span className="themeColorText">events</span>
            </div>
            <div className="infoContent">
                {appType === 'pc' ? (
                    <Carousel
                        dots={false}
                        arrows={true}
                        prevArrow={
                            <div
                                className="leftIcon"
                                style={{ marginRight: '40px' }}
                                onClick={() => {
                                    handleBannerIndex(bannerIndex - 1);
                                }}
                            >
                                <img src={arrowLeftIcon} alt="" />
                            </div>
                        }
                        nextArrow={
                            <div
                                className="rightIcon"
                                onClick={() => {
                                    handleBannerIndex(bannerIndex + 1);
                                }}
                            >
                                <img src={arrowRightIcon} alt="" />
                            </div>
                        }
                    >
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', height: 741 }}>
                                {getBannerArr()[0]?.bannerList.map((cardItem, cardIndex) => {
                                    return (
                                        <div key={cardIndex + 'key'} className="cardItemContent">
                                            <div className="cardCover">
                                                <img
                                                    style={cardItem?.bannerImgStyle}
                                                    src={cardItem?.bannerImg}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="descInfoContent">
                                                <div className="dateText">{cardItem?.date}</div>
                                                <div className="titleText">
                                                    <div>{cardItem?.titleText}</div>
                                                    {cardItem?.titleText ===
                                                        'Swiss Blockchain Finance Day' && (
                                                        <div className="special-desc">
                                                            Swiss Venture Growth, MAMA and EEA
                                                        </div>
                                                    )}
                                                </div>
                                                {cardItem?.descText.map((descItem, descIndex) => {
                                                    return (
                                                        <div
                                                            key={descIndex + 'key'}
                                                            className="descTextItem"
                                                        >
                                                            <img src={descItem?.icon} alt="" />
                                                            <span>{descItem?.text}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', height: 741 }}>
                                {getBannerArr()[1]?.bannerList.map((cardItem, cardIndex) => {
                                    return (
                                        <div key={cardIndex + 'key'} className="cardItemContent">
                                            <div className="cardCover">
                                                <img
                                                    style={cardItem?.bannerImgStyle}
                                                    src={cardItem?.bannerImg}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="descInfoContent">
                                                <div className="dateText">{cardItem?.date}</div>
                                                <div className="titleText">
                                                    {cardItem?.titleText}
                                                </div>
                                                {cardItem?.descText.map((descItem, descIndex) => {
                                                    return (
                                                        <div
                                                            key={descIndex + 'key'}
                                                            className="descTextItem"
                                                        >
                                                            <img src={descItem?.icon} alt="" />
                                                            <span>{descItem?.text}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Carousel>
                ) : null}
                {appType === 'pad' ? (
                    <Carousel
                        dots={false}
                        arrows={true}
                        prevArrow={
                            <div
                                className="leftIcon"
                                style={{ marginRight: '40px' }}
                                onClick={() => {
                                    handleBannerIndex(bannerIndex - 1);
                                }}
                            >
                                <img src={arrowLeftIcon} alt="" />
                            </div>
                        }
                        nextArrow={
                            <div
                                className="rightIcon"
                                onClick={() => {
                                    handleBannerIndex(bannerIndex + 1);
                                }}
                            >
                                <img src={arrowRightIcon} alt="" />
                            </div>
                        }
                    >
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', height: 741 }}>
                                {getBannerArr()[0]?.bannerList.map((cardItem, cardIndex) => {
                                    return (
                                        <div key={cardIndex + 'key'} className="cardItemContent">
                                            <div className="cardCover">
                                                <img
                                                    style={cardItem?.bannerImgStyle}
                                                    src={cardItem?.bannerImg}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="descInfoContent">
                                                <div className="dateText">{cardItem?.date}</div>
                                                {/* <div className="titleText">{cardItem?.titleText}</div> */}
                                                <div className="titleText">
                                                    <div>{cardItem?.titleText}</div>
                                                    {cardItem?.titleText ===
                                                        'Swiss Blockchain Finance Day' && (
                                                        <div className="special-desc">
                                                            Swiss Venture Growth, MAMA and EEA
                                                        </div>
                                                    )}
                                                </div>
                                                {cardItem?.descText.map((descItem, descIndex) => {
                                                    return (
                                                        <div
                                                            key={descIndex + 'key'}
                                                            className="descTextItem"
                                                        >
                                                            <img src={descItem?.icon} alt="" />
                                                            <span>{descItem?.text}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', height: 741 }}>
                                {getBannerArr()[1]?.bannerList.map((cardItem, cardIndex) => {
                                    return (
                                        <div key={cardIndex + 'key'} className="cardItemContent">
                                            <div className="cardCover">
                                                <img
                                                    style={cardItem?.bannerImgStyle}
                                                    src={cardItem?.bannerImg}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="descInfoContent">
                                                <div className="dateText">{cardItem?.date}</div>
                                                <div className="titleText">
                                                    {cardItem?.titleText}
                                                </div>
                                                {cardItem?.descText.map((descItem, descIndex) => {
                                                    return (
                                                        <div
                                                            key={descIndex + 'key'}
                                                            className="descTextItem"
                                                        >
                                                            <img src={descItem?.icon} alt="" />
                                                            <span>{descItem?.text}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', height: 741 }}>
                                {getBannerArr()[2]?.bannerList.map((cardItem, cardIndex) => {
                                    return (
                                        <div key={cardIndex + 'key'} className="cardItemContent">
                                            <div className="cardCover">
                                                <img
                                                    style={cardItem?.bannerImgStyle}
                                                    src={cardItem?.bannerImg}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="descInfoContent">
                                                <div className="dateText">{cardItem?.date}</div>
                                                <div className="titleText">
                                                    {cardItem?.titleText}
                                                </div>
                                                {cardItem?.descText.map((descItem, descIndex) => {
                                                    return (
                                                        <div
                                                            key={descIndex + 'key'}
                                                            className="descTextItem"
                                                        >
                                                            <img src={descItem?.icon} alt="" />
                                                            <span>{descItem?.text}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Carousel>
                ) : null}
                {appType === 'phone' ? (
                    <Carousel
                        dots={false}
                        arrows={true}
                        prevArrow={
                            <div
                                className="leftIcon"
                                style={{ marginRight: '40px' }}
                                onClick={() => {
                                    handleBannerIndex(bannerIndex - 1);
                                }}
                            >
                                <img src={arrowLeftIcon} alt="" />
                            </div>
                        }
                        nextArrow={
                            <div
                                className="rightIcon"
                                onClick={() => {
                                    handleBannerIndex(bannerIndex + 1);
                                }}
                            >
                                <img src={arrowRightIcon} alt="" />
                            </div>
                        }
                    >
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {getBannerArr()[0]?.bannerList.map((cardItem, cardIndex) => {
                                    return (
                                        <div key={cardIndex + 'key'} className="cardItemContent">
                                            <div className="cardCover">
                                                <img
                                                    style={cardItem?.bannerImgStyle}
                                                    src={cardItem?.bannerImg}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="descInfoContent">
                                                <div className="dateText">{cardItem?.date}</div>
                                                <div className="titleText">
                                                    <div>{cardItem?.titleText}</div>
                                                    {cardItem?.titleText ===
                                                        'Swiss Blockchain Finance Day' && (
                                                        <div className="special-desc">
                                                            Swiss Venture Growth, MAMA and EEA
                                                        </div>
                                                    )}
                                                </div>
                                                {cardItem?.descText.map((descItem, descIndex) => {
                                                    return (
                                                        <div
                                                            key={descIndex + 'key'}
                                                            className="descTextItem"
                                                        >
                                                            <img src={descItem?.icon} alt="" />
                                                            <span>{descItem?.text}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {getBannerArr()[1]?.bannerList.map((cardItem, cardIndex) => {
                                    return (
                                        <div key={cardIndex + 'key'} className="cardItemContent">
                                            <div className="cardCover">
                                                <img
                                                    style={cardItem?.bannerImgStyle}
                                                    src={cardItem?.bannerImg}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="descInfoContent">
                                                <div className="dateText">{cardItem?.date}</div>
                                                <div className="titleText">
                                                    {cardItem?.titleText}
                                                </div>
                                                {cardItem?.descText.map((descItem, descIndex) => {
                                                    return (
                                                        <div
                                                            key={descIndex + 'key'}
                                                            className="descTextItem"
                                                        >
                                                            <img src={descItem?.icon} alt="" />
                                                            <span>{descItem?.text}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {getBannerArr()[2]?.bannerList.map((cardItem, cardIndex) => {
                                    return (
                                        <div key={cardIndex + 'key'} className="cardItemContent">
                                            <div className="cardCover">
                                                <img
                                                    style={cardItem?.bannerImgStyle}
                                                    src={cardItem?.bannerImg}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="descInfoContent">
                                                <div className="dateText">{cardItem?.date}</div>
                                                <div className="titleText">
                                                    {cardItem?.titleText}
                                                </div>
                                                {cardItem?.descText.map((descItem, descIndex) => {
                                                    return (
                                                        <div
                                                            key={descIndex + 'key'}
                                                            className="descTextItem"
                                                        >
                                                            <img src={descItem?.icon} alt="" />
                                                            <span>{descItem?.text}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {getBannerArr()[3]?.bannerList.map((cardItem, cardIndex) => {
                                    return (
                                        <div key={cardIndex + 'key'} className="cardItemContent">
                                            <div className="cardCover">
                                                <img
                                                    style={cardItem?.bannerImgStyle}
                                                    src={cardItem?.bannerImg}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="descInfoContent">
                                                <div className="dateText">{cardItem?.date}</div>
                                                <div className="titleText">
                                                    {cardItem?.titleText}
                                                </div>
                                                {cardItem?.descText.map((descItem, descIndex) => {
                                                    return (
                                                        <div
                                                            key={descIndex + 'key'}
                                                            className="descTextItem"
                                                        >
                                                            <img src={descItem?.icon} alt="" />
                                                            <span>{descItem?.text}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {getBannerArr()[4]?.bannerList.map((cardItem, cardIndex) => {
                                    return (
                                        <div key={cardIndex + 'key'} className="cardItemContent">
                                            <div className="cardCover">
                                                <img
                                                    style={cardItem?.bannerImgStyle}
                                                    src={cardItem?.bannerImg}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="descInfoContent">
                                                <div className="dateText">{cardItem?.date}</div>
                                                <div className="titleText">
                                                    {cardItem?.titleText}
                                                </div>
                                                {cardItem?.descText.map((descItem, descIndex) => {
                                                    return (
                                                        <div
                                                            key={descIndex + 'key'}
                                                            className="descTextItem"
                                                        >
                                                            <img src={descItem?.icon} alt="" />
                                                            <span>{descItem?.text}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Carousel>
                ) : null}
            </div>
        </div>
    );
};

export default WebEventsWrap;
