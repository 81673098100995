import { s3address } from '@/utils/s3.js';
import './index.scss';
import arrow from '@/assets/HomePage/GoToTopWrap/arrow.svg';
import { cn } from '@/common/cn.js';
const GoToTopWrap = ({ custName, wrapperClassName }) => {
    return (
        <div className={cn('goToTopWrap', wrapperClassName)} style={custName}>
            {/* <div className="infoContent"> */}
            <div
                className="gotoBtn flex w-auto cursor-pointer"
                onClick={() => {
                    window.scrollTo(0, 0);
                }}
            >
                <span>Go to top</span>
                <img src={arrow} alt="" />
            </div>
            <div className="hintText">©2023 Swiss Blockchain Labs</div>
            {/* </div> */}
        </div>
    );
};

export default GoToTopWrap;
