import { Link } from 'react-router-dom';
import GoToTopWrap from '@/views/HomePage/components/GoToTopWrap/index.jsx';
import Login from '@/views/login/index.jsx';
import './index.scss';

const Sponsor = () => {
    return (
        <div className="sponsor-wrap">
            <div className="sponsor-header">
                <img
                    className="sponsor-header-logo"
                    src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691657606811_logo.svg"
                    alt=""
                />
                <Link to="/event" className="sponsor-header-link">
                    <img
                        src="https://yumi-frontend-assets.s3.ap-east-1.amazonaws.com/yumi/unity/1691657730310_arrow-right.svg"
                        alt=""
                    />
                    Back to Event
                </Link>
            </div>
            <div className="sponsor-content">
                <div className="sponsor-content-left">
                    <div className="sponsor-content-left-top">
                        {/* Become a <span>sponsor</span> */}
                        Contact us
                    </div>
                    <div className="sponsor-content-left-bottom">
                        Thank you for your interest in Swiss Blockchain Capital Day! Please, fill in
                        the form, and our team will contact you shortly.
                    </div>
                </div>
                <div className="sponsor-login">
                    <Login showMessage={true} title={'SEND REQUEST'} />
                </div>
            </div>
            <GoToTopWrap />
        </div>
    );
};

export default Sponsor;
